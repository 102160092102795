import cn from 'classnames';
import { useRef, useEffect, useState } from 'react';
import useWindowSize from 'hooks/common/useWindowSize';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import PageContainerActionButtonContainer from 'components/common/PageContainerActionButtonContainer';
import PageContainerActionButton from 'components/common/PageContainerActionButton';
import PageContainerUtils from './PageContainerUtils';
import classes from './PageContainer.module.less';

/**
 * @typedef {Object} MenuItem
 * @property {string} label - Label for the menu item.
 * @property {Function} onClick - Function to call when the menu item is clicked.
 * @property {JSX.Element} icon - Icon for the menu item.
 */

/**
 * @typedef {Object} ActionButton
 * @property {string} label - Label for the action button.
 * @property {string} type - Type of the action button.
 * @property {Function} onClick - Function to call when the action button is clicked.
 * @property {JSX.Element} icon - Icon for the action button.
 * @property {boolean} hide - Flag to hide the action button.
 * @property {JSX.Element} [component] - Custom action button component.
 */

/**
 * PageContainer component for displaying a page with a header, action buttons, menu, and page content.
 * @param {Object} props - Props for the PageContainer component.
 * @param {string} props.className - Additional class names for the container.
 * @param {string} props.title - Title of the page.
 * @param {JSX.Element} [props.left] - Left side component, usually a helper widget below the title.
 * @param {Object} [props.leftProps] - Props for the left component.
 * @param {JSX.Element} [props.topComponent] - Component to display at the top of the container, usually a back button.
 * @param {Object} [props.style] - Inline styles for the container content.
 * @param {JSX.Element} [props.menu] - Custom menu component.
 * @param {JSX.Element} [props.addionalIconButtons] - Additional icon buttons to display.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @param {Array<MenuItem>} props.menuItems - Array of menu items.
 * @param {JSX.Element} props.children - Children components.
 * @returns {JSX.Element} Rendered PageContainer component.
 * @component
 */
export default function PageContainer({
  children,
  className,
  title,
  left,
  leftProps,
  topComponent,
  style,
  menuItems,
  menu,
  addionalIconButtons,
  actionButtons,
  ...rest
}) {
  const titleContainerRef = useRef(null);
  const responsiveActionButtonsContainerRef = useRef(null);
  const defaultActionButtonsContainerRef = useRef(null);
  const pageContainerUtilsRef = useRef(null);

  const [responsiveMode, setResponsiveMode] = useState(false);

  const { width } = useWindowSize();

  const isResponsiveModeEnabled = actionButtons !== undefined;

  useEffect(() => {
    if (!isResponsiveModeEnabled) return;

    if (!pageContainerUtilsRef.current) {
      pageContainerUtilsRef.current = new PageContainerUtils({
        titleContainerRef,
        defaultActionButtonsContainerRef,
        responsiveActionButtonsContainerRef,
        width,
        setResponsiveMode,
      });
    }

    if (isResponsiveModeEnabled) {
      pageContainerUtilsRef.current.handleCurrentLayout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isResponsiveModeEnabled]);

  const [visible, setVisible] = useState(false);

  return (
    <div className={cn('container', 'xs-pt-20', className)} {...rest}>
      {topComponent}
      <div className={cn(classes.header, 'container-header')}>
        <div
          ref={titleContainerRef}
          id="titleContainer"
          className={cn(classes.leftAndTitleContainer, 'xs-m-0', 'max-width-100')}
        >
          <h1 className={cn(classes.title)}>{title}</h1>
          {left ? (
            <div className={cn(classes.left)} {...leftProps}>
              {left}
            </div>
          ) : null}
        </div>
        <RightLayout
          responsiveMode={responsiveMode}
          menuItems={menuItems}
          menu={menu}
          addionalIconButtons={addionalIconButtons}
          visible={visible}
          setVisible={setVisible}
          actionButtons={actionButtons}
          defaultActionButtonsContainerRef={defaultActionButtonsContainerRef}
          responsiveActionButtonsContainerRef={responsiveActionButtonsContainerRef}
        />
      </div>
      <BottomResponsiveLayout
        responsiveMode={responsiveMode}
        responsiveActionButtonsContainerRef={responsiveActionButtonsContainerRef}
        actionButtons={actionButtons}
      />
      <div className={classes.content} style={style}>
        {children}
      </div>
    </div>
  );
}

/**
 * GenerateMenu component for creating a dropdown menu.
 * @param {Object} props - Props for the GenerateMenu component.
 * @param {Array<MenuItem>} props.menuItems - Array of menu items.
 * @param {JSX.Element} [props.menu] - Custom menu component.
 * @param {Function} props.setVisible - Function to set the visibility of the menu.
 * @param {boolean} props.visible - Visibility state of the menu.
 * @returns {JSX.Element|null} Rendered GenerateMenu component or null if no menu items.
 * @component
 */
const PageMenu = ({ menuItems, menu, setVisible, visible }) => {
  if (menu) return <div className={cn(classes.dropDownContainer)}>{menu}</div>;
  if (!menuItems) return null;

  return (
    <div className={cn(classes.dropDownContainer)}>
      <Dropdown
        placement="bottomRight"
        visible={visible}
        onVisibleChange={(e) => {
          setVisible(true);
          console.log(e);
        }}
        overlay={
          <div
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              setVisible(false);
            }}
            className="border-gray"
          >
            <Menu openKeys={null} selectedKeys={null}>
              {menuItems.map((item) => {
                if (item.hide) return null;
                if (item.component) return item.component;
                return (
                  <Menu.Item
                    key={item.label}
                    active={false}
                    onClick={item.onClick}
                    className="hover-background-light-primary"
                    icon={item.icon}
                  >
                    {item.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          </div>
        }
      >
        <MoreOutlined
          onClick={(e) => e.stopPropagation()}
          className={`${classes.categoryOptions} hover-background-light-primary`}
        />
      </Dropdown>
    </div>
  );
};

/**
 * GenerateActionButtons component for creating action buttons.
 * @param {Object} props - Props for the GenerateActionButtons component.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @param {Object} props.actionButtonsContainerRef - Reference to the action buttons container.
 * @param {boolean} [props.responsiveModeEnabled] - Flag to indicate if responsive mode is enabled.
 * @returns {JSX.Element|null} Rendered GenerateActionButtons component or null if no action buttons.
 * @component
 */
const ActionButtonsBar = ({ actionButtons, actionButtonsContainerRef, responsiveModeEnabled, ...props }) => {
  if (!actionButtons) return null;

  const reverseButtonOrderIfResponsive = () => {
    if (responsiveModeEnabled) return actionButtons;
    return actionButtons.slice().reverse();
  };

  return (
    <PageContainerActionButtonContainer
      responsiveModeEnabled={responsiveModeEnabled}
      actionButtonsContainerRef={actionButtonsContainerRef}
      {...props}
    >
      {reverseButtonOrderIfResponsive().map((item) => {
        if (item.hide) return null;
        if (item.component) return item.component;
        return (
          <PageContainerActionButton
            key={item.label}
            icon={item.icon}
            onClick={item.onClick}
            type={item.type}
            hide={item.hide}
          >
            {item.label}
          </PageContainerActionButton>
        );
      })}
    </PageContainerActionButtonContainer>
  );
};

/**
 * ResponsiveMenuLayout component for creating a responsive layout for the menu displaying if buttons are displayed below the title in responsive mode.
 * @param {Object} props - Props for the ResponsiveMenuLayout component.
 * @param {Array<MenuItem>} props.menuItems - Array of menu items.
 * @param {JSX.Element} [props.menu] - Custom menu component.
 * @param {JSX.Element} [props.addionalIconButtons] - Additional icon buttons to display.
 * @param {boolean} props.visible - Visibility state of the menu.
 * @param {Function} props.setVisible - Function to set the visibility of the menu.
 * @param {Object} props.actionButtonsContainerRef - Reference to the action buttons container.
 * @returns {JSX.Element} Rendered ResponsiveMenuLayout component.
 * @component
 */
const ResponsiveMenuLayout = ({
  menuItems,
  menu,
  addionalIconButtons,
  visible,
  setVisible,
  actionButtonsContainerRef,
}) => {
  return (
    <div className={cn(classes.right)}>
      <PageContainerActionButtonContainer actionButtonsContainerRef={actionButtonsContainerRef} />
      <div style={{ paddingTop: '7px' }}>{addionalIconButtons ?? null}</div>
      <div style={{ paddingTop: '7px' }}>
        <PageMenu menuItems={menuItems} menu={menu} setVisible={setVisible} visible={visible} />
      </div>
    </div>
  );
};

/**
 * ResponsiveActionButtonsLayout component for displaying buttons below the title in responsive mode.
 * @param {Object} props - Props for the ResponsiveActionButtonsLayout component.
 * @param {Object} props.responsiveActionButtonsContainerRef - Reference to the responsive action buttons container.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @returns {JSX.Element} Rendered ResponsiveActionButtonsLayout component.
 * @component
 */
const ResponsiveActionButtonsLayout = ({ responsiveActionButtonsContainerRef, actionButtons, ...props }) => {
  return (
    <ActionButtonsBar
      responsiveModeEnabled
      actionButtons={actionButtons}
      actionButtonsContainerRef={responsiveActionButtonsContainerRef}
      {...props}
    />
  );
};

/**
 * DefaultLayout component for creating the default layout for the right side of the page container.
 * @param {Object} props - Props for the DefaultLayout component.
 * @param {Array<MenuItem>} props.menuItems - Array of menu items.
 * @param {boolean} props.visible - Visibility state of the menu.
 * @param {Function} props.setVisible - Function to set the visibility of the menu.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @param {Object} props.actionButtonsContainerRef - Reference to the action buttons container.
 * @param {JSX.Element} [props.menu] - Custom menu component.
 * @param {JSX.Element} [props.addionalIconButtons] - Additional icon buttons to display.
 * @returns {JSX.Element} Rendered DefaultLayout component.
 * @component
 */
const DefaultLayout = ({
  menuItems,
  visible,
  setVisible,
  actionButtons,
  actionButtonsContainerRef,
  menu,
  addionalIconButtons,
}) => {
  return (
    <div className={cn(classes.right)}>
      <ActionButtonsBar actionButtons={actionButtons} actionButtonsContainerRef={actionButtonsContainerRef} />
      <div style={{ paddingTop: '7px' }}>{addionalIconButtons ?? null}</div>
      <div style={{ paddingTop: '7px' }}>
        <PageMenu menuItems={menuItems} menu={menu} setVisible={setVisible} visible={visible} />
      </div>
    </div>
  );
};

/**
 * RightLayout component for managing the layout of the right side of the page container based on responsive mode.
 * @param {Object} props - Props for the RightLayout component.
 * @param {boolean} props.responsiveMode - Flag to indicate if responsive mode is enabled.
 * @param {Array<MenuItem>} props.menuItems - Array of menu items.
 * @param {JSX.Element} [props.menu] - Custom menu component.
 * @param {JSX.Element} [props.addionalIconButtons] - Additional icon buttons to display.
 * @param {boolean} props.visible - Visibility state of the menu.
 * @param {Function} props.setVisible - Function to set the visibility of the menu.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @param {Object} props.defaultActionButtonsContainerRef - Reference to the default action buttons container.
 * @returns {JSX.Element} Rendered RightLayout component.
 * @component
 */
const RightLayout = ({
  responsiveMode,
  menuItems,
  menu,
  addionalIconButtons,
  visible,
  setVisible,
  actionButtons,
  defaultActionButtonsContainerRef,
}) => {
  if (responsiveMode) {
    return (
      <ResponsiveMenuLayout
        menuItems={menuItems}
        menu={menu}
        addionalIconButtons={addionalIconButtons}
        visible={visible}
        setVisible={setVisible}
        actionButtonsContainerRef={defaultActionButtonsContainerRef}
      />
    );
  }
  return (
    <DefaultLayout
      menuItems={menuItems}
      menu={menu}
      addionalIconButtons={addionalIconButtons}
      visible={visible}
      setVisible={setVisible}
      actionButtons={actionButtons}
      actionButtonsContainerRef={defaultActionButtonsContainerRef}
    />
  );
};

/**
 * BottomResponsiveLayout component for managing the layout of responsive action buttons below the title.
 * @param {Object} props - Props for the BottomResponsiveLayout component.
 * @param {boolean} props.responsiveMode - Flag to indicate if responsive mode is enabled.
 * @param {Object} props.responsiveActionButtonsContainerRef - Reference to the responsive action buttons container.
 * @param {Array<ActionButton>} props.actionButtons - Array of action buttons.
 * @returns {JSX.Element|null} Rendered BottomResponsiveLayout component or null if not in responsive mode.
 * @component
 */
const BottomResponsiveLayout = ({ responsiveMode, responsiveActionButtonsContainerRef, actionButtons }) => {
  if (!responsiveMode) return null;
  return (
    <ResponsiveActionButtonsLayout
      responsiveActionButtonsContainerRef={responsiveActionButtonsContainerRef}
      actionButtons={actionButtons}
    />
  );
};
