import i18n from 'i18n';
import inputConfirmModal from 'utils/inputConfirmModal';
import { createEmailTemplate } from 'graphql/methods';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import EmailTemplateForm from './EmailTemplateForm';
import { EmailTemplateValidationSchema } from './schema';

/**
 * Opens the add email template modal to create a new email template
 * @param {object} inputProperties - The input properties for the modal
 * @param {boolean} inputProperties.isLibrary - A boolean value indicating whether the email template is a common library email template
 * @returns {void}
 */
const openAddEmailTemplate = ({ isLibrary }) =>
  inputConfirmModal({
    formContent: () => <EmailTemplateForm />,
    fields: [],
    onSubmit: ({ name, subject, body, isDefault, shouldSendAttachments, description }) =>
      createEmailTemplate({ name, subject, body, isLibrary, isDefault, shouldSendAttachments, description }),
    value: {
      name: '',
      subject: RichText.getDefaultRichEditorValue(),
      body: RichText.getDefaultRichEditorValue({ rootElement: 'paragraph' }),
      description: '',
      isDefault: false,
      shouldSendAttachments: true,
    },
    headerText: i18n.t('admin.EmailTemplates.modal.addTitle'),
    errorResolver: { Duplicated: ['name', i18n.t('admin.EmailTemplates.modal.error.duplicatedErrorMessage')] },
    okText: i18n.t('common.ok'),
    validationSchema: EmailTemplateValidationSchema,
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
  });

export default openAddEmailTemplate;
