import { useEffect } from 'react';
import moment from 'moment';
import { setLocale } from 'yup';
import { de as deLocale, en as enLocale } from 'utils/yupLocale';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_GB';

/**
 * Set language for utilities like moment and yup
 * @param {string} language - Language string ('de'|'en')
 */
export const useUtilityLanguage = (language) => {
  useEffect(() => {
    moment.locale(language);
    switch (language) {
      case 'en':
        setLocale(enLocale);
        break;
      case 'de':
        setLocale(deLocale);
        break;
      default:
    }
  }, [language]);
};

/**
 * Ant Design translations
 */
export const antdTranslations = {
  de,
  en,
};
