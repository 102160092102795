import classNames from 'classnames';
import classes from './PageContainerActionButtonContainer.module.less';

/**
 * PageContainerActionButtonContainer is a container for the action buttons in the PageContainer header.
 * @param {Object} props - The props of the component.
 * @param {React.ReactNode} props.children - The children of the component.
 * @param {string} props.className - The class name of the component.
 * @param {boolean} props.responsiveModeEnabled - Flag indicating if the responsive mode is enabled.
 * @param {React.RefObject} props.actionButtonsContainerRef - The reference to the action buttons container.
 * @returns {React.ReactNode} The PageContainerActionButtonContainer component.
 * @component
 */
const PageContainerActionButtonContainer = ({
  children,
  className,
  responsiveModeEnabled,
  actionButtonsContainerRef,
  ...props
}) => {
  return (
    <div
      ref={actionButtonsContainerRef}
      className={classNames(
        classes.topHeaderbuttonsContainer,
        responsiveModeEnabled ? classes.topHeaderbuttonsContainerResponsive : '',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default PageContainerActionButtonContainer;
