import PageContainer from 'components/layout/PageContainer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLibraryContext } from 'contexts/LibraryContext';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import ImportEmailTemplates from '../components/ImportEmailTemplates';
import openAddEmailTemplate from '../components/AddEmailTemplate';
import EmailTemplatesList from '../components/EmailTemplatesList';

/**
 * Page component for email template management for tenant instance and common library
 * @returns {JSX.Element} Page for email templates
 * @component
 */
const EmailTemplatesPage = () => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickImportButton = () => setIsModalVisible(true);

  const ActionButtons = [
    {
      icon: <ImportOutlined />,
      onClick: onClickImportButton,
      label: t('admin.EmailTemplates.import'),
      hide: isLibrary,
    },
    {
      icon: <PlusOutlined />,
      onClick: () => openAddEmailTemplate({ isLibrary }),
      type: 'primary',
      label: t('admin.EmailTemplates.add'),
    },
  ];

  return (
    <PageContainer
      title={t(isLibrary ? 'NavBar.commonLibrary.emailCommonLibrary.title' : 'admin.EmailTemplates.title')}
      actionButtons={ActionButtons}
    >
      <EmailTemplatesList />
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ImportEmailTemplates visible={isModalVisible} handleClose={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
};

// const EmailTemplatesLayoutMemo = memo(EmailTemplatesLayout, equal);

export default EmailTemplatesPage;
