import concatClassNames from 'utils/classNames';
import classes from './Card.module.less';

/**
 * Card component used in the list
 * @param {object} inputProperties - Input properties of the component
 * @returns {JSX.Element} Card component
 * @component
 */
const Card = ({ children, bordered = false }) => {
  if (bordered) {
    return <div className={concatClassNames(classes.card, classes.card_bordered)}>{children}</div>;
  }
  return <div className={classes.card}>{children}</div>;
};

export default Card;
