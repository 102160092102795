import i18n from 'i18n';
import * as Yup from 'yup';

/**
 * Email template validation schema with validation for name of email template
 * @returns {object} EmailTemplateSchema
 */
export const EmailTemplateSchema = () => ({
  name: Yup.string().required().label(i18n.t('admin.EmailTemplates.fields.name.label')),
});
export const EmailTemplateValidationSchema = () => Yup.object().shape(EmailTemplateSchema());
