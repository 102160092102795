import CollapsableCard from '../CollapsableCard';
import ServiceEntry from './ServiceEntry/ServiceEntry';
import InputFieldValuesFromCategory from '../InputFieldValuesFromCategory';

/**
 * CategoryOverview component to show selected category infos on the service overview step
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.category - Category object
 * @param {Object} inputParameters.category.texts - Texts of the category
 * @param {String} inputParameters.category.texts.name - Name of the category
 * @param {Object[]} inputParameters.category.inputFields - Inputfields of the category
 * @param {Object[]} inputParameters.category.items - Selected services of the category
 * @param {Date} inputParameters.category.startOfService - Date of start of service
 * @param {Object} inputParameters.category.discount - Informations about selected discount
 * @param {Boolean} inputParameters.showDigits - Show digits on prices
 * @param {Boolean} inputParameters.isFixedMonthlyFeeType - Is the shopping cart in fixed monthly fee mode
 * @returns {JSX.Element} collapsable card to show informations about a selected category
 * @component
 */
const CategoryOverview = ({ category, showDigits, isFixedMonthlyFeeType }) => {
  const { texts, items, discount } = category;
  const { name } = texts;

  const itemsComponents = items.map((item) => (
    <ServiceEntry
      item={item}
      key={item.texts.name}
      showDigits={showDigits}
      isFixedMonthlyFeeType={isFixedMonthlyFeeType}
      activeDiscount={discount}
    />
  ));

  if (!itemsComponents.length) return null;

  return (
    <CollapsableCard title={name} openByDefault>
      <InputFieldValuesFromCategory category={category} />
      {itemsComponents}
    </CollapsableCard>
  );
};

export default CategoryOverview;
