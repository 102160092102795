import { GrDrag } from 'react-icons/gr';
import concatClassNames from 'utils/classNames';
import { useDragContext } from './DragContext';
import classes from './DragButton.module.less';

const DragButton = ({ ...buttonProps }) => {
  const { drag, isDragging } = useDragContext();
  return (
    <div ref={drag} className={classes.buttonWrapper}>
      <GrDrag className={concatClassNames(classes.drag, isDragging ? classes.dragging : null)} {...buttonProps} />
    </div>
  );
};

export default DragButton;
