import concatClassNames from 'utils/classNames';
import { Button } from '../../Button';
import classes from './ActionButton.module.less';

/**
 * Button component for actions in the list items
 * @param {object} inputProperties - Input properties of the component
 * @returns {JSX.Element} ActionButton component
 * @component
 */
const ActionButton = ({ icon, danger = false, onClick = () => console.log('Actionbutton clicked') }) => {
  return (
    <Button
      icon={icon}
      danger={danger}
      onClick={onClick}
      className={concatClassNames(classes.ActionButton, danger ? classes.ActionButtonDanger : null)}
    />
  );
};

export default ActionButton;
