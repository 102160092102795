import apollo from 'graphql/apollo';
import { deleteDiscountMutation } from 'graphql/mutations';
import i18n from 'i18n';
import { find } from 'lodash';
import confirmModal from 'utils/confirmModal';
import { clientDelete, toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import { adminCategoryListQuery } from 'graphql/queries';
import isDiscountUsedInCart from './isDiscountUsedInCart';

const deleteDiscount = async ({ _id, isLibrary }) =>
  apollo.mutate({
    mutation: deleteDiscountMutation,
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
      const { categories } = cachedData;
      const category = categories.find(({ discounts }) => find(discounts, { _id }));
      if (!category) return;
      const newDiscounts = clientDelete({ array: category.discounts, _id });
      cache.writeQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary },
        data: {
          categories: cachedData.categories.map((c) =>
            find(c.discounts, { _id }) ? { ...c, discounts: newDiscounts } : c,
          ),
        },
      });
    },
  });
export const deleteDiscountWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    okText: i18n.t('admin.deleteDiscountConfirmation.ok'),
    cancelText: i18n.t('admin.deleteDiscountConfirmation.cancel'),
    okType: 'danger',
    onOk: async () => {
      const used = await isDiscountUsedInCart({ _id });
      const onConfirm = () => deleteDiscount({ _id, isLibrary }).then(toast.successDefault).catch(toastGraphQLError);
      if (!used) return onConfirm();
      return confirmModal({
        cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
        okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
        okType: 'danger',
        onOk: onConfirm,
        title: i18n.t('admin.discountUsedInShoppingCartConfirmation.titleDelete'),
      });
    },
    title: i18n.t('admin.deleteDiscountConfirmation.title'),
  });
export default deleteDiscount;
