import { Form } from 'formik-antd';
import * as Yup from 'yup';
import SaveChangesButton from 'components/common/SaveChangesButton';
import { useMemo, memo, useCallback } from 'react';
import { priceSchema } from 'components/admin/itemModal/schema';
import I18nFormik from 'components/common/I18nFormik';
import { useLibraryContext } from 'contexts/LibraryContext';
import equal from 'fast-deep-equal/es6/react';
import { CalculationMode, HighlightBindingness, PaymentInterval } from 'constants/item';
import { getMessageFromGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';
import PriceTabForm from './PriceTabForm';

export const priceValidationSchema = (props) => Yup.object().shape({ ...priceSchema(props) });

const PriceTab = ({
  categoryId,
  calculationMode,
  highlightBindingness,
  graduatedScaleMode,
  minPrice,
  paymentInterval,
  pricingFormula,
  roundPriceId,
  scalePricingFormulaTitle,
  scaleTitle,
  scales,
  scalesEnabled,
  scalesScaleUnitName,
  scalesUnitName,
  onSubmit: onSubmitProps,
  _id,
  formikRef,
}) => {
  const { isLibrary } = useLibraryContext();

  const schema = useCallback(
    () => priceValidationSchema({ formikRef, categoryId, _id, isLibrary }),
    [_id, categoryId, formikRef, isLibrary],
  );
  const initialValues = useMemo(
    () => ({
      calculationMode: calculationMode ?? CalculationMode.VALUE,
      highlightBindingness: highlightBindingness ?? HighlightBindingness.NO_HIGHLIGHT,
      minPrice: minPrice ?? undefined,
      paymentInterval: paymentInterval ?? PaymentInterval.ONE_OFF,
      pricingFormula: pricingFormula ?? undefined,
      roundPriceId: roundPriceId ?? undefined,
      scalePricingFormulaTitle: scalePricingFormulaTitle ?? '',
      graduatedScaleMode: graduatedScaleMode ?? false,
      scaleTitle: scaleTitle ?? '',
      scales: scales ?? [],
      scalesEnabled: scalesEnabled ?? false,
      scalesScaleUnitName: scalesScaleUnitName ?? '',
      scalesUnitName: scalesUnitName ?? '€',
    }),
    [
      calculationMode,
      graduatedScaleMode,
      highlightBindingness,
      minPrice,
      paymentInterval,
      pricingFormula,
      roundPriceId,
      scalePricingFormulaTitle,
      scaleTitle,
      scales,
      scalesEnabled,
      scalesScaleUnitName,
      scalesUnitName,
    ],
  );
  const onSubmit = useCallback(
    // eslint-disable-next-line no-shadow
    ({ roundPriceId = null, ...values }, formik) =>
      onSubmitProps({ roundPriceId, ...values }, formik).catch(
        (e) => console.log(e) || toast.error(getMessageFromGraphQLError(e)),
      ),
    [onSubmitProps],
  );
  return (
    <I18nFormik
      enableReinitialize
      initialValues={initialValues}
      ref={formikRef}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Form layout="vertical">
        <div className="margin-16 margin-top-0">
          <PriceTabForm categoryId={categoryId} _id={_id} />
          <SaveChangesButton marginTop={0} initialValues={initialValues} />
        </div>
      </Form>
    </I18nFormik>
  );
};

export default memo(PriceTab, equal);
