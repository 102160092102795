import { ConfigProvider } from 'antd';
import color from 'color';
import defaultTheme from 'config/theme';

/**
 * Used prefix for ant design style classes and variables
 */
export const ANTD_CLASS_PREFIX = 'ant';

/**
 * Change app theme with specified colors
 * @param {string} primaryColor - Color code for the primary color of the theme
 * @param {string} textPrimaryColor - Color code for the text color of the theme
 */
export const changeTheme = (primaryColor, textPrimaryColor) => {
  const theme = {
    primaryColor,
    hoverPrimaryColor: color(primaryColor).lighten(0.35),
    primaryColorAlpha50: color(primaryColor).alpha(0.5),
    veryLightPrimaryColor: color(primaryColor).alpha(0.15),
    btnPrimaryColor: textPrimaryColor,
  };
  document.documentElement.style.setProperty('--primary-color', primaryColor);
  document.documentElement.style.setProperty('--btn-primary-color', textPrimaryColor);
  document.documentElement.style.setProperty('--hover-primary-color', theme.hoverPrimaryColor);
  document.documentElement.style.setProperty('--primary-color-alpha-50', theme.primaryColorAlpha50);
  document.documentElement.style.setProperty('--very-light-primary-color', theme.veryLightPrimaryColor);
  ConfigProvider.config({
    prefixCls: ANTD_CLASS_PREFIX,
    theme,
  });
};

/**
 * Change app theme to default kp app theme
 */
export const changeToDefaultTheme = () => {
  const { primaryColor, textPrimaryColor } = defaultTheme;
  changeTheme(primaryColor, textPrimaryColor);
};
