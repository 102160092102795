import { allInputFieldsInCategories } from 'components/user/shoppingCart/utils';
import apollo from 'graphql/apollo';
import { adminInputFieldFragment } from 'graphql/fragments';
import { deleteInputFieldMutation } from 'graphql/mutations';
import { adminCategoryItemQuery, adminCategoryListQuery, adminPlaceholderDataQuery } from 'graphql/queries';
import i18n from 'i18n';
import { find } from 'lodash';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import { clientDeleteInputField, toastGraphQLError } from 'utils/helpers';

const deleteInputField = async ({ _id, isLibrary, categoryId, parentId }) => {
  const inputField = apollo.readFragment({ fragment: adminInputFieldFragment, id: `InputField___${_id}` });
  apollo
    .mutate({
      mutation: deleteInputFieldMutation,
      ...(inputField.parentType === 'categoryItem' && {
        refetchQueries: [
          {
            query: adminCategoryItemQuery,
            variables: { _id: inputField.parentId },
          },
          {
            query: adminPlaceholderDataQuery,
            variables: { categoryId, parentId, isLibrary },
          },
        ],
      }),
      variables: { _id },
      update: (cache) => {
        const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
        const { categories } = cachedData;
        const category = categories.find(({ inputFields }) => find(inputFields, { _id }));
        if (!category) return;
        const newInputFields = clientDeleteInputField({ array: allInputFieldsInCategories([category]), _id });
        cache.writeQuery({
          query: adminCategoryListQuery,
          variables: { isLibrary },
          data: {
            categories: cachedData.categories.map((c) =>
              find(c.inputFields, { _id }) ? { ...c, inputFields: newInputFields } : c,
            ),
          },
        });
      },
    })
    .catch(toastGraphQLError);
};
export const deleteInputFieldWithConfirmation = ({ _id, isLibrary, categoryId, parentId }) =>
  confirmModal({
    okText: i18n.t('admin.deleteInputConfirmation.ok'),
    cancelText: i18n.t('admin.deleteInputConfirmation.cancel'),
    okType: 'danger',
    onOk: () =>
      deleteInputField({ _id, isLibrary, categoryId, parentId }).then(toast.successDefault).catch(toastGraphQLError),
    title: i18n.t('admin.deleteInputConfirmation.title'),
  });
export default deleteInputField;
