import ActionButton from './ActionButton';
import classes from './ActionButtonGroup.module.less';

/**
 * Types of ActionButtonGroup
 * Action buttons can be displayed in a row or stacked
 * @readonly
 */
export const ActionButtonGroupTypes = {
  STACKED: 'STACKED',
  ROW: 'ROW',
};

/**
 * Button group for action buttons on a list item
 * @param {object} inputProperties - Input properties of the component
 * @returns {JSX.Element} ActionButtonGroup component
 * @component
 */
const ActionButtonGroup = ({ actionButtons, type = ActionButtonGroupTypes.STACKED }) => {
  const buttons = actionButtons.map((actionButton) => {
    return <ActionButton key={actionButton.key} {...actionButton} />;
  });

  if (type === ActionButtonGroupTypes.STACKED) {
    return <div className={classes.ActionButtonGroupStacked}>{buttons}</div>;
  }

  return <div className={classes.ActionButtonGroupRow}>{buttons}</div>;
};

export default ActionButtonGroup;
