import { NAVIGATION_DIRECTION, checkForChangesInModalOnNavigation } from './ModalNavigation';

/**
 * A generic navigation class that can be used to navigate between entities.
 * @param {Object} params - The parameters for the navigation.
 * @param {Function} params.navigate - The navigate function from react-router-dom
 * @param {Object} params.location - The location object from react-router-dom
 * @param {Function} params.fetchEntities - A function that fetches the entities to navigate between
 * @param {Function} params.constructPath - A function that constructs the url path for an entity
 * @param {Object} params.entity - The current entity to navigate from
 * @param {Object} [params.overrides] - An object containing overrides for the navigation methods
 * @param {Function} [params.overrides.getNextEntityInOrder] - A function that returns the next entity in order
 * @param {Function} [params.overrides.getPreviousEntityInOrder] - A function that returns the previous entity in order
 */
export default class GenericNavigation {
  constructor({ navigate, location, fetchEntities, constructPath, entity, overrides = {} }) {
    this.navigate = navigate;
    this.location = location;
    this.fetchEntities = fetchEntities;
    this.constructPath = constructPath;
    this.entity = entity;
    this.overrides = overrides;
  }

  /**
   * Gets the entities to navigate between
   * @returns {Promise<Array<Object>>} The entities to navigate between
   */
  getEntities = async () => {
    return this.fetchEntities();
  };

  /**
   * Gets the next entity in order
   * @returns {Promise<Object>} The next entity in order
   */
  getNextEntityInOrder = async () => {
    if (this.overrides.getNextEntityInOrder) {
      return this.overrides.getNextEntityInOrder(this.entity);
    }

    const entities = await this.getEntities();
    const currentId = this.entity._id;
    const currentIndex = entities.findIndex((entity) => entity._id === currentId);
    const nextIndex = (currentIndex + 1) % entities.length;
    const nextEntity = entities[nextIndex];
    return nextEntity || entities[0];
  };

  /**
   * Gets the previous entity in order
   * @returns {Promise<Object>} The previous entity in order
   */
  getPreviousEntityInOrder = async () => {
    if (this.overrides.getPreviousEntityInOrder) {
      return this.overrides.getPreviousEntityInOrder(this.entity);
    }

    const entities = await this.getEntities();
    const currentId = this.entity._id;
    const currentIndex = entities.findIndex((entity) => entity._id === currentId);
    const previousIndex = (currentIndex - 1 + entities.length) % entities.length;
    const previousEntity = entities[previousIndex];
    return previousEntity || entities[entities.length - 1];
  };

  /**
   * Navigates to an entity
   * @param {Object} entity - The entity to navigate to
   * @param {string} activeTab - The active tab to navigate to
   * @returns {void}
   */
  navigateToEntity = (entity, activeTab) => {
    const newPath = this.constructPath(entity);
    this.navigate(newPath, {
      replace: true,
      state: { prevLocation: this.location.pathname, activeTab },
    });
  };

  /**
   * Navigates to the next entity in order
   * @param {string} activeTab - The active tab to navigate to
   * @returns {void}
   */
  navigateToNextEntity = async (activeTab = null) => {
    const nextEntity = await this.getNextEntityInOrder();
    this.navigateToEntity(nextEntity, activeTab);
  };

  /**
   * Navigates to the previous entity in order
   * @param {string} activeTab - The active tab to navigate to
   * returns {void}
   */
  navigateToPreviousEntity = async (activeTab = null) => {
    const previousEntity = await this.getPreviousEntityInOrder();
    this.navigateToEntity(previousEntity, activeTab);
  };

  /**
   * Handles navigation between entities
   * @param {NAVIGATION_DIRECTION.NEXT|NAVIGATION_DIRECTION.PREVIOUS} direction - The direction to navigate in
   * @param {Object} formikRef - The formik reference for the form
   * @param {string} activeTab - The active tab to navigate to
   * @returns {void}
   */
  handleNavigation = async (direction, formikRef, activeTab = null) => {
    const cancelNavigation = await checkForChangesInModalOnNavigation(formikRef);

    if (cancelNavigation) return;

    let submissionErrorOccurred = false;

    if (formikRef) {
      if (formikRef.dirty && formikRef.isValid) {
        submissionErrorOccurred = false;
        await formikRef.submitForm().catch(() => {
          submissionErrorOccurred = true;
        });
      }
    }

    if (!submissionErrorOccurred) {
      if (direction === NAVIGATION_DIRECTION.NEXT) {
        await this.navigateToNextEntity(activeTab);
      } else if (direction === NAVIGATION_DIRECTION.PREVIOUS) {
        await this.navigateToPreviousEntity(activeTab);
      }
    }
  };
}
