import formattedInputValue from 'pages/shoppingCartManagement/ShoppingCartView/Categories/components/InputFieldsValuePreparation';
import { useTranslation } from 'react-i18next';
import KeyValueBlock from './ServiceOverviewStep/KeyValueBlock';
import classes from './ServiceOverviewStep/CategoryOverview.module.less';

const InputFieldValuesFromCategory = ({ category }) => {
  const { t: translation } = useTranslation();
  const { inputFields, startOfService } = category;

  const mapInputFieldToKeyValueBlockEntries = (inputField) => {
    let value = formattedInputValue({
      inputField: {
        name: inputField.orginalInputFieldData.name,
        unitName: inputField.orginalInputFieldData.unitName,
        options: inputField.orginalInputFieldData.options,
        ...inputField,
      },
      showDigits: true,
      t: translation,
    });
    if (Array.isArray(value)) value = value.map((entry, index) => ({ ...entry, key: index }));
    return {
      key: inputField.orginalInputFieldData.name,
      value,
    };
  };

  let inputFieldDataAsKeyValueStore = inputFields
    .filter(
      (inputField) =>
        inputField?.orginalInputFieldData?.name &&
        inputField?.value &&
        inputField?.orginalInputFieldData?.type !== 'internal',
    )
    .map(mapInputFieldToKeyValueBlockEntries);

  /**
   * Filters key value pairs with duplicated keys
   * @param {Object[]} keyValuePairs - Collection of key value pairs
   * @returns {Object[]} filtered key value pairs collection
   */
  const filterForDuplicatedKeys = (keyValuePairs) => {
    const foundKeys = new Set();
    return keyValuePairs.filter((keyValuePair) => {
      const found = foundKeys.has(keyValuePair.key);
      if (found) return false;
      foundKeys.add(keyValuePair.key);
      return true;
    });
  };

  inputFieldDataAsKeyValueStore = filterForDuplicatedKeys(inputFieldDataAsKeyValueStore);
  if (startOfService)
    inputFieldDataAsKeyValueStore.unshift({
      key: translation('user.ShoppingCart.Category.ValidationLabels.startOfService'),
      value: <span className="info-text">{startOfService}</span>,
    });
  inputFieldDataAsKeyValueStore = inputFieldDataAsKeyValueStore.map((keyValue) => ({
    key: `${keyValue.key}:`,
    value: keyValue.value,
  }));
  return <KeyValueBlock keyValuePairs={inputFieldDataAsKeyValueStore} className={classes.keyValueBlock} />;
};

export default InputFieldValuesFromCategory;
