import { useState } from 'react';
import ActionButtonGroup, { ActionButtonGroupTypes } from './ActionButtonGroup';
import Card from '../Card';
import ListItemRow from './ListItemRow';
import DragHandle from './DragHandle';
import Checkbox from './Checkbox';
import CollapsableButton from './CollapsableButton';
import classes from './ListItem.module.less';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

/**
 * List item component for list layout
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.rows - Rows to show in the list item
 * @param {object[]} inputProperties.rows.rowColumns - Columns to show in the row
 * @param {string} inputProperties.rows.rowColumns.fieldName - Field name of the item
 * @param {string} inputProperties.rows.rowColumns.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} inputProperties.rows.rowColumns.renderValue - Optional: Function to render the value
 * @param {boolean} inputProperties.rows.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object[]} inputProperties.actionButtons - Action buttons for the list item
 * @param {React.ReactNode} inputProperties.actionButtons.icon - Icon for the action button
 * @param {string} inputProperties.actionButtons.key - Key for the action button
 * @param {(item: object) => void} inputProperties.actionButtons.onClick - Function to call when the action button is clicked
 * @param {boolean} inputProperties.actionButtons.danger - Should the action button in danger style
 * @param {object} inputProperties.item - Item to show in the list item (data)
 * @param {boolean} inputProperties.bordered - Should the list item be bordered
 * @param {boolean} inputProperties.showLabel - Should the label be shown
 * @param {boolean} inputProperties.draggable - Should the list item be draggable
 * @param {boolean} inputProperties.selectable - Should the list item be selectable
 * @param {(selected: boolean) => void} inputProperties.onSelect - Function to call when the list item selected state changes
 * @param {() => void} inputProperties.onClick - Function to call when the list item gets clicked
 * @param {JSX.Element} inputProperties.collapseContent - Collapsable content of the list item
 * @param {boolean} inputProperties.collapsable - Should the list item be collapsable
 * @param {boolean} inputProperties.selected - Is the list item selected
 * @returns {JSX.Element} list item
 * @component
 */
const ListItem = ({
  rows = [],
  actionButtons = [],
  item,
  bordered,
  showLabel,
  draggable = false,
  selectable = false,
  onSelect = () => {},
  onClick,
  collapseContent,
  collapsable,
  selected,
}) => {
  const [open, setOpen] = useState(false);

  const listItemData = rows.map((row, index) => {
    return (
      <ListItemRow
        rowConfig={row.rowColumns}
        item={item}
        alignLastEntryRight={row.alignLastEntryRight}
        showLabel={showLabel}
        key={row.rowColumns[0].fieldName}
        bottomBorder={index !== rows.length - 1}
      />
    );
  });

  // eslint-disable-next-line no-param-reassign
  if (!onClick && collapsable) onClick = () => setOpen(!open);

  return (
    <Card bordered={bordered}>
      <div className={classes.ListItem}>
        {selectable && <Checkbox onSelect={onSelect} selected={selected} />}
        {draggable && <DragHandle />}
        {collapsable && <CollapsableButton open={open} onClick={() => setOpen(!open)} />}
        <div className={classes.ListItemDataContainer} onClick={onClick}>
          {listItemData}
        </div>
        <ActionButtonGroup
          actionButtons={actionButtons}
          type={rows.length > 1 ? ActionButtonGroupTypes.STACKED : ActionButtonGroupTypes.ROW}
        />
      </div>
      {open && <div className={classes.CollapsableContentWrapper}>{collapseContent}</div>}
    </Card>
  );
};

/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export default ListItem;
