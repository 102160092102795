import { Button } from 'antd';
import classnames from 'classnames';
import classes from './PageContainerActionButton.module.less';

/**
 * PageContainerActionButton is a button that is used in the PageContainer header.
 * @param {Object} props - The props of the component.
 * @param {string} props.icon - The icon of the button.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {React.ReactNode} props.children - The children of the component, usually a string.
 * @param {string} props.className - The class name of the component.
 * @param {boolean} props.hide - Flag indicating if the button should be hidden.
 * @returns {React.ReactNode} The PageContainerActionButton component.
 * @component
 */
export const PageContainerActionButton = ({ icon, onClick, children, className, hide, ...props }) => {
  if (hide) {
    return null;
  }
  return (
    <Button icon={icon} onClick={onClick} className={classnames(classes.actionButton, className)} {...props}>
      {children}
    </Button>
  );
};

export default PageContainerActionButton;
