import ImportDefaultTemplatesButton from './ImportDefaultTemplatesButton';
import ResetPreferencesButton from './ResetPreferencesButton';

/**
 * ActionButtons contain the import default templates and reset preferences button for the digital signature preferences page
 * @type {Array<{key: string, component: JSX.Element}>}
 */
const ActionButtons = [
  {
    key: 'importDefaultTemplates',
    component: <ImportDefaultTemplatesButton />,
  },
  {
    key: 'resetPreferences',
    component: <ResetPreferencesButton />,
  },
];

export default ActionButtons;
