import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import { SigningProcessShoppingCartContextProvider } from 'contexts/SigningProcessShoppingCartContext';
import useSigningContext from 'hooks/signing/useSigningContext';
import CustomWarningAlert from './CustomWarningAlert';
import DecisionForm from './MonthlyPaymentDecision/DecisionForm';
import PriceCard from './ServiceOverviewStep/PriceCard';
/**
 * MonthlyPaymentDecision component is the step component when monthly payment need to be decided
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @returns {JSX.Element} step to decide on monthly payment in the signing process
 */
const MonthlyPaymentDecisionStep = ({ submitReference }) => {
  const { t: translation } = useTranslation('translation', { keyPrefix: 'signing.monthlyPaymentDecisionStep' });
  const [decision, setDecision] = useState('decideLater');
  const { shoppingCart, refetch } = useSigningShoppingCart();
  const { signingContext } = useSigningContext();

  const stepWasDone = shoppingCart?.signatureData.isMonthlypaymentStepDone;

  return (
    <>
      <h1>{shoppingCart?.monthlyPaymentSignatureSettings.title || translation('defaultHeadline')}</h1>
      <CustomWarningAlert
        key="decision-alert"
        message=""
        description={
          stepWasDone
            ? translation('warning.monthlyPaymentStepDone')
            : shoppingCart?.monthlyPaymentSignatureSettings?.introductionText || translation('warning.description')
        }
      />
      <div>
        {shoppingCart ? (
          <DecisionForm
            onError={() => {
              refetch && refetch();
            }}
            onDecisionChange={(value) => setDecision(value)}
            defaultValues={{ decision: shoppingCart?.monthlyPaymentDecision }}
            disabled={
              shoppingCart?.monthlyPaymentDecision !== 'decideLater' ||
              signingContext.typeOfSignee.toLowerCase() === 'tenant'
            }
            submitReference={submitReference}
            labels={{
              yes:
                shoppingCart?.monthlyPaymentSignatureSettings?.optionPayMonthlyPaymentText ||
                translation('options.yes'),
              no: shoppingCart?.monthlyPaymentSignatureSettings?.optionPaySeparatelyText || translation('options.no'),
            }}
          />
        ) : (
          ''
        )}

        <div>
          <SigningProcessShoppingCartContextProvider
            value={{
              ...shoppingCart,
              monthlyPaymentDecision: stepWasDone ? shoppingCart?.monthlyPaymentDecision : decision,
            }}
          >
            <PriceCard />
          </SigningProcessShoppingCartContextProvider>
        </div>
      </div>
    </>
  );
};

export default MonthlyPaymentDecisionStep;
