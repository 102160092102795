import { useCallback, useState } from 'react';

/**
 * Handles select logic for a not paginated list
 * @param {object} inputProperties - Input properties of the hook
 * @param {(selectedItems: object[]) => void} inputProperties.onSelectChange - Function to call when selection changes
 * @param {object[]} inputProperties.sortedAndFilteredData - Data to select from
 * @returns {[() => void, (item: object, select: boolean = true) => void, object[], boolean]} - Array with selectAll function, selectOne function, selected items and selectAllActive state
 */
const useSelectable = ({ onSelectChange, sortedAndFilteredData }) => {
  const [selected, setSelected] = useState([]);
  const [selectAllActive, setSelectAllActive] = useState(false);

  const selectAll = useCallback(() => {
    setSelectAllActive((currentlyActive) => {
      if (currentlyActive) {
        setSelected([]);
        onSelectChange([]);
        return false;
      }
      setSelected(sortedAndFilteredData);
      onSelectChange(sortedAndFilteredData);
      return true;
    });
  }, [sortedAndFilteredData, onSelectChange]);

  const selectOne = useCallback(
    (item, select = true) => {
      setSelected((prevSelected) => {
        if (select) {
          const newState = [...prevSelected, item];
          onSelectChange(newState);
          return newState;
        }
        setSelectAllActive(false);
        const newState = prevSelected.filter((element) => element !== item);
        onSelectChange(newState);
        return newState;
      });
    },
    [onSelectChange],
  );

  return [selectAll, selectOne, selected, selectAllActive];
};

export default useSelectable;
