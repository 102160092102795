import { useState, useCallback } from 'react';
import { SigningProcessShoppingCartContextProvider } from 'contexts/SigningProcessShoppingCartContext';
import useSigningShoppingCart from 'hooks/signing/useSigningShoppingCart';
import useSigningGraphqlContext from 'hooks/signing/useSigningGraphqlContext';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useQuery, useMutation } from '@apollo/client';
import isOrderBookmarkedServicesStepDone from 'graphql/queries/signing/isOrderBookmarkedServicesStepDone Query';
import orderBookmarkedServices from 'graphql/mutations/signing/orderBookmarkedServices';
import useSigningContext from 'hooks/signing/useSigningContext';
import PriceCard from './ServiceOverviewStep/PriceCard';
import BookmarkedItemsOverview from './OrderBookmarkedServices/BookmarkedItemsOverview';
import CustomWarningAlert from './CustomWarningAlert';

/**
 * OrderBookmarkedServicesStep component is the step component when you can ordere bookmarked items
 * @param {Object} inputParameters - Input parameters of the component
 * @param {React.Ref} inputParameters.submitReference - Reference, which gets the onContinue function
 * @returns {JSX.Element} step to configure sepa mandate
 */
const OrderBookmarkedServicesStep = ({ submitReference }) => {
  const { shoppingCart } = useSigningShoppingCart();
  const [cartWithSelectedBookmarkedItems, setCartWithSelectedBookmarkedItems] = useState(shoppingCart);
  const signingGraphlQlContext = useSigningGraphqlContext();
  const { signingContext } = useSigningContext();
  const { data, loading } = useQuery(isOrderBookmarkedServicesStepDone, {
    context: signingGraphlQlContext,
    pollInterval: 1000 * 60 * 10, // 10 minutes
    fetchPolicy: 'network-only',
  });
  const [submitOrderBookmarkedServices] = useMutation(orderBookmarkedServices, {
    context: signingGraphlQlContext,
  });

  const isTenant = signingContext?.typeOfSignee?.toLowerCase() === 'tenant';

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    showContinueLoading: true,
    onContinue: async () => {
      if (data.isOrderBookmarkedServicesStepDone || isTenant) {
        return true;
      }

      const bookmarkedItems = [];
      cartWithSelectedBookmarkedItems?.categories.forEach((category) => {
        const originalCategory = shoppingCart.categories.find((c) => c._id === category._id);

        category.items.forEach((item) => {
          if (item._id && !originalCategory?.itemIds.includes(item._id)) {
            bookmarkedItems.push(item._id);
          }
        });
      });

      try {
        const response = await submitOrderBookmarkedServices({
          variables: {
            bookmarkedServiceIds: bookmarkedItems,
          },
        });
        return response?.data?.orderBookmarkedServices.success;
      } catch (e) {
        message.error(e.message, 5);
      }

      return false;
    },
  };

  const handleBookmarkedItemsSelected = useCallback(
    (categoryId, newSelectedBookmarkedItems) => {
      let updatedShoppingCart = { ...(cartWithSelectedBookmarkedItems || shoppingCart) };
      updatedShoppingCart.categories = updatedShoppingCart.categories.map((category) => {
        if (category._id !== categoryId) {
          return category;
        }
        const baseCategory = shoppingCart.categories.find((c) => c._id === categoryId);
        const newCategory = { ...category };
        newCategory.items = [...baseCategory.items, ...newSelectedBookmarkedItems];
        newCategory.itemIds = [...baseCategory.itemIds, ...newSelectedBookmarkedItems.map((item) => item._id)];
        return newCategory;
      });
      const newTotal = Calc.calcCartTotalFromCart(updatedShoppingCart.categories, parseFloat(shoppingCart.vat.value));
      updatedShoppingCart = { ...updatedShoppingCart, ...newTotal };
      setCartWithSelectedBookmarkedItems(updatedShoppingCart);
    },
    [shoppingCart, cartWithSelectedBookmarkedItems],
  );

  if (!shoppingCart) {
    return null;
  }

  const disabled = loading ? true : data?.isOrderBookmarkedServicesStepDone || isTenant;

  return (
    <SigningProcessShoppingCartContextProvider value={shoppingCart}>
      {data?.isOrderBookmarkedServicesStepDone && (
        <CustomWarningAlert
          description={<Trans i18nKey="signing.orderBookmarkedItemsStep.warning.orderBookmarkedItemsStepDone" />}
        />
      )}
      <CustomWarningAlert description={<Trans i18nKey="signing.orderBookmarkedItemsStep.warning.description" />} />
      {shoppingCart.categories.map((category) => {
        return (
          <BookmarkedItemsOverview
            disabled={disabled}
            category={category}
            onBookmarkedItemsSelected={handleBookmarkedItemsSelected}
          />
        );
      })}
      <PriceCard shoppingCartFromProps={cartWithSelectedBookmarkedItems} />
    </SigningProcessShoppingCartContextProvider>
  );
};

export default OrderBookmarkedServicesStep;
