import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { useTranslation } from 'react-i18next';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import PageContainer from 'components/layout/PageContainer';
import ListLayout from 'components/common/List/ListLayout';
import { createDocumentTemplate, deleteDocumentTemplateWithConfirmation } from 'graphql/methods';
import { useLibraryContext } from 'contexts/LibraryContext';
import { ImportDocumentFromLibrary } from 'components/admin/DocumentTemplate/modals';
import { RenderDateTime, RenderTrueFalse } from 'components/common/List/FieldRenderers';
import { DeleteButton, DuplicateButton, EditButton } from 'components/common/List/DefaultActionButtons';
import { useDocumentTemplateListForPage } from 'graphql/hooks';
import DocumentTemplateHelperWidget from '../../../pages/admin/DocumentTemplatesHelperWidget';
import openDuplicateDocumentTemplateModal from '../components/DuplicateDocumentTemplateModal';

// Definitions of generator functions for list configuration

const generateListItemConfig = (t, isLibrary, navigate) => ({
  rows: [
    {
      rowColumns: [
        { fieldName: 'name', fieldLabel: t('admin.DocumentTemplateListPage.list.name') },
        !isLibrary && {
          fieldName: 'nrOfDocumentsCreated',
          fieldLabel: t('admin.DocumentTemplateListPage.list.countDocuments'),
          renderValue: (value) => value || '0',
        },
        {
          fieldName: 'isSignable',
          fieldLabel: t('admin.DocumentTemplateListPage.list.signable'),
          renderValue: (value) => RenderTrueFalse(value !== 'NOT_SIGNABLE'),
        },
      ].filter(Boolean),
      alignLastEntryRight: true,
    },
    {
      rowColumns: [
        { fieldName: 'description', fieldLabel: t('admin.DocumentTemplateListPage.list.description') },
        {
          fieldName: 'createdAt',
          fieldLabel: t('admin.DocumentTemplateListPage.list.createdAt'),
          renderValue: RenderDateTime,
        },
      ],
      alignLastEntryRight: true,
    },
  ],
  actionButtons: [
    EditButton((documentTemplate) =>
      navigate(
        routePatterns[
          isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
        ].stringify({ id: documentTemplate._id }),
      ),
    ),
    DuplicateButton((documentTemplate) => openDuplicateDocumentTemplateModal({ _id: documentTemplate._id, isLibrary })),
    DeleteButton((documentTemplate) =>
      deleteDocumentTemplateWithConfirmation({ _id: documentTemplate._id, isLibrary }),
    ),
  ],
  onClick: (documentTemplate) =>
    navigate(
      routePatterns[
        isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
      ].stringify({ id: documentTemplate._id }),
    ),
});

const generateSortBarConfig = (t, isLibrary) => ({
  sortFields: [
    { fieldName: 'name', label: t('admin.DocumentTemplateListPage.list.name') },
    !isLibrary && { fieldName: 'nrOfDocumentsCreated', label: t('admin.DocumentTemplateListPage.list.countDocuments') },
    {
      fieldName: 'isSignable',
      label: t('admin.DocumentTemplateListPage.list.signable'),
      compareFunc: (item1, item2) => {
        if (item1 === 'NOT_SIGNABLE' && item2 === 'NOT_SIGNABLE') {
          return 0;
        }
        if (item1 !== 'NOT_SIGNABLE' && item2 !== 'NOT_SIGNABLE') {
          return 0;
        }
        if (item1 === 'NOT_SIGNABLE') {
          return 1;
        }
        return -1;
      },
    },
    { fieldName: 'createdAt', label: t('admin.DocumentTemplateListPage.list.createdAt') },
  ].filter(Boolean),
  initSort: ['name', 'ASC'],
});

const generateSearchCardConfig = (t) => ({
  searchPlaceholder: t('admin.DocumentTemplateListPage.list.searchPlaceholder'),
  queryFields: ['name'],
  filters: [
    {
      fieldName: 'isSignable',
      applyFilter: (item, value) => {
        if (value === 'null') {
          return true;
        }
        if (value === 'false') {
          return item.isSignable === 'NOT_SIGNABLE';
        }
        return item.isSignable !== 'NOT_SIGNABLE';
      },
      label: t('admin.DocumentTemplateListPage.list.signable'),
      options: [
        { value: 'null', label: t('admin.DocumentTemplateListPage.list.all') },
        { value: 'false', label: t('admin.DocumentTemplateListPage.list.notSignable') },
        { value: 'true', label: t('admin.DocumentTemplateListPage.list.signable') },
      ],
    },
  ],
});

const generateListDataHook = (isLibrary) => {
  return () => useDocumentTemplateListForPage(isLibrary);
};

/**
 * Page for the document template list, which is used to display the list of document templates, create, edit, duplicate and delete document templates.
 * @returns {JSX.Element} Page component for document template list
 */
const DocumentTemplateListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLibrary } = useLibraryContext();
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const onClickImportButton = () => setIsImportModalVisible(true);

  const listItemConfig = useMemo(() => generateListItemConfig(t, isLibrary, navigate), [t, isLibrary, navigate]);
  const searchCardConfig = useMemo(() => generateSearchCardConfig(t), [t]);
  const sortBarConfig = useMemo(() => generateSortBarConfig(t, isLibrary), [t, isLibrary]);
  const useDataFetching = useMemo(() => generateListDataHook(isLibrary), [isLibrary]);

  const ActionButtons = [
    {
      label: t('admin.DocumentTemplateListPage.importFromLibraryButton'),
      icon: <ImportOutlined />,
      onClick: onClickImportButton,
      hide: isLibrary,
    },
    {
      label: t('admin.DocumentTemplateListPage.addDocumentTemplateButton'),
      icon: <PlusOutlined />,
      onClick: () => {
        createDocumentTemplate({
          isLibrary,
          onSuccess: (documentTemplate) => {
            navigate(
              routePatterns[
                isLibrary ? 'commonLibraryDocumentTemplateConfiguration' : 'documentTemplateConfiguration'
              ].stringify({ id: documentTemplate._id }),
            );
          },
        });
      },
      type: 'primary',
    },
  ];

  return (
    <PageContainer
      title={t(`admin.DocumentTemplateListPage.${isLibrary ? 'titleCommonLibrary' : 'title'}`)}
      actionButtons={ActionButtons}
      left={<DocumentTemplateHelperWidget />}
    >
      <div className="xs-mt-20">
        <ListLayout
          listItemConfig={listItemConfig}
          searchCardConfig={searchCardConfig}
          sortBarConfig={sortBarConfig}
          useDataFetching={useDataFetching}
          shouldUseSearchQueryParams
        />
      </div>
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          <ImportDocumentFromLibrary visible={isImportModalVisible} handleClose={setIsImportModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
};

export default DocumentTemplateListPage;
