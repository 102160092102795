import { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragContextProvider } from './DragContext';

const DragAndDropBox = ({ type, moveData, id, orderValue, afterDrop, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: () => {
      return { id, index: orderValue };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = orderValue;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveData(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop(item) {
      afterDrop(item);
    },
  });

  const dragValues = useMemo(() => ({ drag, isDragging }), [drag, isDragging]);

  drop(preview(ref));

  const opacity = isDragging ? 0 : 1;
  return (
    <div
      style={{
        opacity,
      }}
      ref={ref}
      data-handler-id={handlerId}
    >
      <DragContextProvider value={dragValues}>{children}</DragContextProvider>
    </div>
  );
};

export default DragAndDropBox;
