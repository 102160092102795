import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { FaBookmark, FaCheck, FaInfoCircle } from 'react-icons/fa';
import { Button, Tooltip } from 'antd';
import { useFormikField } from 'hooks/common/useFormikField';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import ItemDifferences from 'components/user/compareToGlobal/ItemDifferences';
import { useFormikContext } from 'formik';
import { useDeselectItem } from 'components/user/shoppingCart/utils';
import { useParams } from 'react-router';
import { CreationMode } from 'constants/shoppingCart';
import classes from './ItemCard.module.less';
import { useShoppingCartRevisionContext } from '../context';
import { showInfoTextsModal } from './showInfoTextsModal';

const CheckButton = ({ _id }) => {
  const { t } = useTranslation();
  const { value } = useFormikField(_id);
  const deselectItem = useDeselectItem();
  return (
    <Tooltip title={!isMobile ? t('user.ShoppingCart.Category.Item.selectTooltip') : ''} mouseEnterDelay={1}>
      <Button
        onClick={() => {
          deselectItem({ itemId: _id, isBookmark: false });
        }}
        icon={<FaCheck className={classes.checkIcon} />}
        className={cn(classes.itemButton, value && classes.activeItemCheckbox, 'primary-color-wave')}
      />
    </Tooltip>
  );
};
export const CheckButtonMemo = memo(CheckButton, equal);

const BookmarkButton = ({ _id }) => {
  const { t } = useTranslation();
  const { value } = useFormikField(`${_id}_bookmark`);
  const deselectItem = useDeselectItem();
  return (
    <Tooltip title={!isMobile ? t('user.ShoppingCart.Category.Item.bookmarkTooltip') : ''} mouseEnterDelay={0.5}>
      <Button
        onClick={() => {
          deselectItem({ itemId: _id, isBookmark: true });
        }}
        icon={<FaBookmark className={classes.checkIcon} />}
        className={cn(classes.itemButton, value && classes.activeItemCheckbox, 'primary-color-wave')}
      />
    </Tooltip>
  );
};
export const BookmarkButtonMemo = memo(BookmarkButton, equal);

const ItemCardHeaderPanel = ({
  benefits,
  descriptionForContract,
  infoText,
  itemName,
  notesForFeeAgreement,
  notesToEmployee,
  pleaseNote,
  subTitle,
  item,
}) => {
  const { values } = useFormikContext();
  const { initializationConfigDate } = useShoppingCartRevisionContext();
  const { mode } = useParams();
  const isGlobal = useMemo(() => mode === CreationMode.GLOBAL, [mode]);
  const { t } = useTranslation();

  const itemTexts = useMemo(() => {
    const texts = {};
    if (!RichText.isRichTextEmpty(benefits)) texts.benefits = benefits;
    if (!RichText.isRichTextEmpty(descriptionForContract)) texts.descriptionForContract = descriptionForContract;
    if (!RichText.isRichTextEmpty(infoText)) texts.infoText = infoText;
    if (!RichText.isRichTextEmpty(notesForFeeAgreement)) texts.notesForFeeAgreement = notesForFeeAgreement;
    if (!RichText.isRichTextEmpty(notesToEmployee)) texts.notesToEmployee = notesToEmployee;
    if (!RichText.isRichTextEmpty(pleaseNote)) texts.pleaseNote = pleaseNote;
    return texts;
  }, [benefits, descriptionForContract, infoText, notesForFeeAgreement, notesToEmployee, pleaseNote]);

  return (
    <div className={classes.itemHeader}>
      <div>
        <div>
          <h3 className={classes.title}>{itemName}</h3>
          {!isEmpty(itemTexts) ? (
            <Button
              icon={<FaInfoCircle className={classes.infoIcon} />}
              className={cn(classes.itemButton, 'button-border-none button-box-shadow-none center')}
              ghost
              onClick={() => {
                showInfoTextsModal({
                  itemTexts,
                  modalTitle: itemName,
                  values,
                  initializationConfigDate: isGlobal ? null : initializationConfigDate,
                  t,
                });
              }}
            />
          ) : null}
        </div>
        <div>{subTitle}</div>
      </div>
      <div className="center">
        <ItemDifferences className={classes.differences} item={item} />
      </div>
    </div>
  );
};

export default memo(ItemCardHeaderPanel, equal);
