import { gql } from '@apollo/client';

const signingShoppingCartQuery = gql`
  query shoppingCartOfSigningContextQuery {
    shoppingCartOfSigningContext {
      _id
      categories {
        _id
        texts {
          name
          subTitle
        }
        discountId
        inputFields {
          value
          defaultValue
          orginalInputFieldData {
            name
            unitName
            type
            options {
              name
              _id
            }
          }
        }
        items {
          calculationMode
          customPrice
          discountedValue
          highlightBindingness
          isRange
          officialReasonText
          showCalculatedPrice
          value
          paymentInterval
          texts {
            subTitle
            name
            benefits
          }
        }
        discount {
          name
          value
          type
        }
        bookmarkedItems {
          _id
          calculationMode
          customPrice
          discountedValue
          highlightBindingness
          isRange
          officialReasonText
          showCalculatedPrice
          value
          paymentInterval
          wasSelected
          texts {
            subTitle
            name
            benefits
          }
        }
        startOfService
        itemIds
        total {
          oneOff {
            value
            discountedValue
            discountedVAT
            highlightBindingnessValues
          }
          monthly {
            value
            discountedValue
            discountedVAT
            highlightBindingnessValues
          }
          yearly {
            value
            discountedValue
            discountedVAT
            highlightBindingnessValues
          }
        }
      }
      feeType
      monthly {
        value
        discountedValue
        discountedVAT
        highlightBindingnessValues
      }
      monthlyAdvance {
        value
        discountedValue
        discountedVAT
        highlightBindingnessValues
      }
      monthlyPaymentDecision
      monthlyPaymentTitle
      monthlyPaymentSignatureSettings {
        title
        introductionText
        optionPayMonthlyPaymentText
        optionPaySeparatelyText
      }
      name
      oneOff {
        value
        discountedValue
        discountedVAT
        highlightBindingnessValues
      }
      pdfs {
        _id
        documentTemplateId
        type
        isGenerating
        name
        templateName
        documentNumber
      }
      sepaMandateSignatureSettings {
        sepaIntroductionText
        sepaMode
        tenantCompanyName
        mandateText
      }
      showDigits
      showVat
      signatureData {
        companySignees {
          firstName
          lastName
          email
        }
        tenantSignees {
          firstName
          lastName
          email
        }
        signedCompanySignees {
          signDate
          signeeEmail
        }
        signedTenantSignees {
          signDate
          signeeEmail
        }
        isMonthlypaymentStepDone
        isSepaMandateStepDone
        sepa {
          iban
          bic
          accountOwnerName
          hasSepaMandate
          bankAccountId
          bankData {
            bankName
            bankCity
          }
        }
      }
      timeOfLastFetching @client
      vat {
        value
      }
      vatType
      yearly {
        value
        discountedValue
        discountedVAT
        highlightBindingnessValues
      }
    }
  }
`;
export default signingShoppingCartQuery;
