import apollo from 'graphql/apollo';
import { moveDiscountMutation } from 'graphql/mutations';

const moveCategoryDiscount = async ({ _id, offset, order }, categoryListQuery, categoryListQueryVariables) => {
  return apollo.mutate({
    mutation: moveDiscountMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery, variables: categoryListQueryVariables }],
  });
};
export default moveCategoryDiscount;
