import apollo from 'graphql/apollo';
import { duplicateCategoryMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const duplicateCategory = async ({ _id, name, isLibrary } = {}) =>
  apollo.mutate({
    mutation: duplicateCategoryMutation,
    variables: {
      _id,
      name,
      isLibrary,
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
      const categories = cachedData.categories
        .map((category) => (category._id === _id ? [category, result] : category))
        .flat()
        .map((category, i, arr) => ({ ...category, order: arr.length - i }));
      cache.writeQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary },
        data: {
          categories,
        },
      });
    },
  });
export default duplicateCategory;
