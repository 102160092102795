import { useMemo } from 'react';

/**
 * Checks, if conditions for drag and drop are given
 * @param {string} orderField - Field by which the data is ordered
 * @param {string} orderDirection - Direction of the order
 * @param {string[][]} filterState - Array of current applied filters [[fieldName, value], ...]
 * @param {string} queryState - Search query
 * @param {string[]} sortState - Array of current applied filters [fieldName, direction]
 * @returns {boolean} - Whether drag and drop is enabled
 */
const useIsDragEnabled = (orderField, orderDirection, filterState, queryState, sortState) => {
  return useMemo(() => {
    return (
      filterState.length === 0 &&
      queryState === '' &&
      sortState.length === 2 &&
      sortState[0] === orderField &&
      sortState[1] === orderDirection
    );
  }, [orderField, orderDirection, filterState, queryState, sortState]);
};

export default useIsDragEnabled;
