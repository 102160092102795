import apollo from 'graphql/apollo';
import { moveCategoryMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';

const moveCategory = async ({ _id, offset, order }, isLibrary) =>
  apollo.mutate({
    mutation: moveCategoryMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: adminCategoryListQuery, variables: { isLibrary } }],
  });
export default moveCategory;
