import { Radio } from 'antd';
import classes from './FilterSwitch.module.less';

const { Button, Group } = Radio;
/**
 * Switch for a filter for list layouts
 * Shows label and filter options for a filter
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label for the filter switch
 * @param {object[]} inputProperties.options - Options for the filter switch
 * @param {string} inputProperties.options[].label - Label for the option
 * @param {string} inputProperties.options[].value - Value for the option
 * @param {string} inputProperties.value - Current value of the filter switch
 * @param {(value: string) => void} inputProperties.onChange - Function to call when the value changes
 * @returns {JSX.Element} Filter switch for lists
 * @component
 */
const FilterSwitch = ({ label, options = [], value, onChange }) => {
  const filterOptions = options.map((option) => (
    <Button key={option.value} value={option.value}>
      {option.label}
    </Button>
  ));

  return (
    <div>
      <div className={classes.Label}>{label}</div>
      <Group value={value} onChange={onChange} size="small">
        {filterOptions}
      </Group>
    </div>
  );
};

export default FilterSwitch;
