import { useCallback } from 'react';

/**
 * Recalculates the order value for each item in the array by its index
 * @param {object[]} items - Array of items to update
 * @param {string} orderField - Field to update
 * @returns {void}
 */
const updateOrderFieldValue = (items, orderField) => {
  return items.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    item[orderField] = index;
  });
};

/**
 * Get a function to move data entries for example in a drag and drop scenario
 * @param {string} orderField - Field by which the data is ordered
 * @param {(func: (prevState: object[]) => object[]) => void} setSortedAndFilteredData - Setter function for the sorted and filtered data
 * @returns {(dragIndex: number, hoverIndex: number) => void} - Function to move data entries
 */
export const useMoveData = (orderField, setSortedAndFilteredData) => {
  return useCallback(
    (dragIndex, hoverIndex) => {
      setSortedAndFilteredData((prevDataEntries) => {
        const dataEntries = [...prevDataEntries];
        const dragCard = dataEntries[dragIndex];
        // Remove the dragged
        dataEntries.splice(dragIndex, 1);
        // Add the dragged card to the new position
        dataEntries.splice(hoverIndex, 0, dragCard);
        updateOrderFieldValue(dataEntries, orderField);
        return dataEntries;
      });
    },
    [orderField, setSortedAndFilteredData],
  );
};

export default useMoveData;
