import apollo from 'graphql/apollo';
import { createEmailTemplateMutation } from 'graphql/mutations';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createEmailTemplate = async ({
  isLibrary = false,
  name,
  subject,
  body,
  description,
  isDefault,
  shouldSendAttachments,
}) =>
  apollo.mutate({
    mutation: createEmailTemplateMutation,
    variables: { isLibrary, emailTemplate: { name, subject, body, description, isDefault, shouldSendAttachments } },
    update: (cache, { data }) => {
      const newEmailTemplate = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({
        query: adminEmailTemplatesQuery,
        variables: { isLibrary },
      });
      cache.writeQuery({
        query: adminEmailTemplatesQuery,
        variables: { isLibrary },
        data: {
          emailTemplates: [...cachedData.emailTemplates, newEmailTemplate],
        },
      });
    },
  });
export default createEmailTemplate;
