import { gql } from '@apollo/client';
import { adminCategoryListFragment } from 'graphql/fragments';

const adminCategoryListQuery = gql`
  query adminCategoryListQuery($isLibrary: Boolean!) {
    categories(isLibrary: $isLibrary) {
      ...adminCategoryListFragment
    }
  }
  ${adminCategoryListFragment}
`;

export default adminCategoryListQuery;
