import { memo } from 'react';
import routePaths from 'router/route-paths';
import ItemCatalogueConfiguration from 'pages/admin/ItemCatalogueConfiguration';
import { DisplayByRoute } from 'components/common/DisplayByRoute';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import { confirmUnsavedChangesOfFormWithModal } from 'utils/helpers';
import { AllApolloCategoriesContext } from 'components/user/shoppingCart/context';
import { ModalWithSupressableAnimation } from 'components/layout/navigation/ModalWithSupressableAnimation';

const ModalItemConfiguration = memo(({ getAllApolloCategories, path }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Disable animation when navigating through items
  const activeTabRouterState = location.state?.activeTab;
  const disableAnimation = Boolean(activeTabRouterState);

  return (
    <ModalWithSupressableAnimation
      disableAnimation={disableAnimation}
      visible
      onCancel={async (event) => {
        if (await confirmUnsavedChangesOfFormWithModal({ event })) return true;
        switch (path) {
          case routePaths.itemCatalogueConfiguration:
            navigate(routePaths.catalogueConfiguration, { replace: true, state: { prevLocation: location.pathname } });
            break;
          case routePaths.commonLibraryItemConfiguration:
            navigate(routePaths.commonLibraryConfiguration, {
              replace: true,
              state: { prevLocation: location.pathname },
            });
            break;
          default:
        }
        return undefined;
      }}
      width="80%"
      footer={null}
      style={{ top: 40 }}
    >
      <AllApolloCategoriesContext.Provider value={getAllApolloCategories}>
        <ItemCatalogueConfiguration />
      </AllApolloCategoriesContext.Provider>
    </ModalWithSupressableAnimation>
  );
}, equal);

function ItemEditModal(props) {
  const match1 = useMatch(routePaths.itemCatalogueConfiguration);
  const match2 = useMatch(routePaths.commonLibraryItemConfiguration);
  const match = match1 || match2;
  return (
    <DisplayByRoute
      // eslint-disable-next-line react/no-unstable-nested-components
      component={() => <ModalItemConfiguration {...props} path={match?.pattern?.path} />}
      path={[routePaths.commonLibraryItemConfiguration, routePaths.itemCatalogueConfiguration]}
    />
  );
}

export default memo(ItemEditModal, equal);
