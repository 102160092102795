import apollo from 'graphql/apollo';
import { duplicateItemMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const duplicateCategoryItem = async ({ itemId, isLibrary, name }) =>
  apollo.mutate({
    mutation: duplicateItemMutation,
    variables: {
      itemId,
      name,
    },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
      cache.writeQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary },
        data: {
          categories: cachedData.categories.map((c) => {
            if (c._id === result.categoryId) {
              const items = c.items
                .map((item) => (item._id === itemId ? [item, result] : item))
                .flat()
                .map((category, i) => ({ ...category, order: i + 1 }));

              return { ...c, items };
            }
            return c;
          }),
        },
      });
    },
  });
export default duplicateCategoryItem;
