import { Alert, Button, Radio as RadioAntd } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { createInputField, deleteInputFieldWithConfirmation, updateInputField } from 'graphql/methods';
import { TableMemo } from 'memo';
import { Radio, Input, Select } from 'formik-antd';
import BorderedBox from 'components/common/BorderedBox';
import { InputNumber } from 'components/common/InputNumber';
import { memo, useCallback, useMemo } from 'react';
import EmptyBox from 'components/common/EmptyBox';
import * as Yup from 'yup';
import { find } from 'lodash';
import { adminVariableListQuery } from 'graphql/queries';
import { useLibraryContext } from 'contexts/LibraryContext';
import { numberToLocaleString } from '@JavaScriptSuperstars/kanzleipilot-shared';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import equal from 'fast-deep-equal/es6/react';
import FormItem from 'components/common/FormItem';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { InputFieldType, InternalInputFieldType } from 'constants/inputField';
import apollo from 'graphql/apollo';
import { textsSchema } from './itemModal/schema';
import { DragHandle, useDragTableComponents } from './drag/DragTable';
import classes from './InputFieldsTab.module.less';
import InputFieldOptionsField from './InputFieldOptions';

const OptionPDFConfiguration = () => {
  const { t } = useTranslation();
  return (
    <BorderedBox type="transparent" label={t('admin.inputFieldModal.pdfConfigurationLabel')}>
      <FormItem name="optionPDFNameTitle" label={t('admin.inputFieldModal.fields.optionPDFNameTitle.label')}>
        <Input
          name="optionPDFNameTitle"
          placeholder={t('admin.inputFieldModal.fields.optionPDFNameTitle.placeholder')}
        />
      </FormItem>
      <FormItem name="optionPDFValueTitle" label={t('admin.inputFieldModal.fields.optionPDFValueTitle.label')}>
        <Input
          name="optionPDFValueTitle"
          placeholder={t('admin.inputFieldModal.fields.optionPDFValueTitle.placeholder')}
        />
      </FormItem>
    </BorderedBox>
  );
};

const AllowedRangeFormikInput = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.allowedRange}>
      <FormItem name="allowedRangeMinValue" label={t('admin.inputFieldModal.fields.allowedRangeMinValue.label')}>
        <InputNumber name="allowedRangeMinValue" />
      </FormItem>
      <FormItem name="allowedRangeMaxValue" label={t('admin.inputFieldModal.fields.allowedRangeMaxValue.label')}>
        <InputNumber name="allowedRangeMaxValue" />
      </FormItem>
    </div>
  );
};
const DefaultTypeFormikInput = ({ name, ...props }) => {
  const { setValues, values } = useFormikContext(name);
  const onChange = useCallback(
    (e) => setValues((state) => ({ ...state, [name]: e.target.value, defaultValue: null })),
    [name, setValues],
  );
  return (
    <RadioAntd.Group onChange={onChange} value={values[name]} className={classes.defaultType} {...props}>
      {Object.values(InternalInputFieldType).map((type) => (
        <RadioAntd.Button value={type}>{i18n.t(`admin.inputFieldModal.fields.defaultType.${type}`)}</RadioAntd.Button>
      ))}
    </RadioAntd.Group>
  );
};
const VariableAlert = ({ variables }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const variable = useMemo(() => find(variables, { _id: values.defaultValue }), [values.defaultValue, variables]);
  return (
    <Alert
      showIcon
      type="info"
      className="alert-info"
      description={t('admin.inputFieldModal.variableAlert', {
        value: numberToLocaleString(variable.value, t('localeCode'), {
          isRestrictMaximumDigits: false,
        }),
      })}
    />
  );
};
const getInputFieldFields = ({ categoryId, parentId, inputFieldId, variables }) => [
  {
    label: i18n.t('admin.inputFieldModal.fields.name.label'),
    name: 'name',
    description: i18n.t('admin.inputFieldModal.fields.name.description'),
  },
  {
    label: i18n.t('admin.inputFieldModal.typeFieldLabel'),
    name: 'type',
    component: (props) => {
      return (
        <Radio.Group className={classes.type} {...props}>
          {Object.values(InputFieldType)
            .filter((type) => !(type === InputFieldType.INTERNAL && categoryId === parentId))
            .map((type) => (
              <Radio.Button value={type}>{i18n.t(`common.InputField.${type}`)}</Radio.Button>
            ))}
        </Radio.Group>
      );
    },
  },
  {
    name: 'options',
    label: '',
    component: (props) => (
      <InputFieldOptionsField categoryId={categoryId} parentId={parentId} inputFieldId={inputFieldId} {...props} />
    ),
    shouldRenderFn: ({ values }) => values.type === InputFieldType.COMBO,
  },
  {
    name: 'pdfConfiguration',
    label: '',
    component: () => <OptionPDFConfiguration />,
    shouldRenderFn: ({ values }) => values.type === InputFieldType.COMBO,
  },
  {
    name: 'allowedRange',
    className: 'margin-bottom-0',
    label: <b>{i18n.t('admin.inputFieldModal.fields.allowedRange.label')}</b>,
    component: () => <AllowedRangeFormikInput />,
    shouldRenderFn: ({ values }) => values.type === InputFieldType.INTERNAL,
  },

  {
    name: 'defaultType',
    label: i18n.t('admin.inputFieldModal.fields.defaultType.label'),
    component: (props) => <DefaultTypeFormikInput {...props} />,
    shouldRenderFn: ({ values }) => values.type === InputFieldType.INTERNAL,
  },
  {
    name: 'defaultValue',
    label: i18n.t('admin.inputFieldModal.fields.defaultInput.label'),
    component: (props) => <InputNumber {...props} />,
    shouldRenderFn: ({ values }) =>
      values.type === InputFieldType.INTERNAL && values.defaultType === InternalInputFieldType.INPUT,
  },
  {
    name: 'defaultValue',
    label: i18n.t('admin.inputFieldModal.fields.defaultVariable.label'),
    component: (props) => (
      <Select {...props}>
        {variables.map((variable) => (
          <Select.Option name="defaultValue" key={variable._id} value={variable._id}>
            {variable.label}
          </Select.Option>
        ))}
      </Select>
    ),
    shouldRenderFn: ({ values }) =>
      values.type === InputFieldType.INTERNAL && values.defaultType === InternalInputFieldType.VARIABLE,
  },
  {
    label: '',
    component: () => <VariableAlert variables={variables} />,
    shouldRenderFn: ({ values }) =>
      values.type === InputFieldType.INTERNAL &&
      values.defaultType === InternalInputFieldType.VARIABLE &&
      values.defaultValue,
  },
  {
    label: i18n.t('admin.inputFieldModal.unitNameLabel'),
    name: 'unitName',
    description: i18n.t('admin.inputFieldModal.unitNameDescription'),
  },
];

const getDefaultValue = ({ values: { defaultType, defaultValue }, variables }) =>
  defaultType === InternalInputFieldType.INPUT ? defaultValue : find(variables, { _id: defaultValue })?.value;

const optionsValidationSchema = ({ variables, values }) =>
  Yup.object().shape({
    ...textsSchema({}),
    options: Yup.array().when('type', {
      is: (value) => value === 'combo',
      then: Yup.array().min(1).required(),
    }),
    ...(values.type === InputFieldType.INTERNAL && {
      allowedRangeMinValue: Yup.number()
        .required()
        .nullable()
        .moreThan(0)
        .test(
          'min-is-more-then-max',
          i18n.t('admin.inputFieldModal.fields.allowedRangeMinValue.minIsMoreThenMax'),
          (minValue) => !values.allowedRangeMaxValue || minValue <= values.allowedRangeMaxValue,
        )
        .test(
          'min-is-more-then-defaultValue',
          i18n.t('admin.inputFieldModal.fields.allowedRangeMinValue.minIsMoreThenDefaultValue'),
          (minValue) => !values.defaultValue || minValue <= getDefaultValue({ values, variables }),
        )
        .label(i18n.t('admin.inputFieldModal.fields.allowedRangeMinValue.label')),
      allowedRangeMaxValue: Yup.number()
        .required()
        .test(
          'max-is-less-then-min',
          i18n.t('admin.inputFieldModal.fields.allowedRangeMaxValue.maxIsLessThenMin'),
          (maxValue) => !values.allowedRangeMinValue || maxValue >= values.allowedRangeMinValue,
        )
        .test(
          'max-is-less-then-defaultValue',
          i18n.t('admin.inputFieldModal.fields.allowedRangeMaxValue.maxIsLessThenDefaultValue'),
          (maxValue) => !values.defaultValue || maxValue >= getDefaultValue({ values, variables }),
        )
        .nullable()
        .moreThan(0)
        .label(i18n.t('admin.inputFieldModal.fields.allowedRangeMaxValue.label')),
      defaultValue: Yup[values.defaultType === InternalInputFieldType.INPUT ? 'number' : 'string']()
        .required()
        .nullable()
        .test(
          'defaultValue-is-out-of-range',
          i18n.t('admin.inputFieldModal.fields.defaultValue.defaultValueIsOutOfRange'),
          (defaultValue) => {
            let value;
            if (values.defaultType === InternalInputFieldType.VARIABLE && defaultValue)
              value = find(variables, { _id: defaultValue }).value;
            else value = defaultValue;
            if (values.allowedRangeMinValue > values.allowedRangeMaxValue) return true;
            return !(
              (values.allowedRangeMinValue && values.allowedRangeMinValue > value) ||
              (values.allowedRangeMaxValue && values.allowedRangeMaxValue < value)
            );
          },
        )
        .label(i18n.t('admin.inputFieldModal.fields.defaultInput.label')),
    }),
  });

const addNewInputField = ({ categoryId, parentId, parentType, isLibrary }) =>
  apollo
    .query({
      query: adminVariableListQuery,
      variables: { isLibrary },
      fetchPolicy: 'cache-first',
    })
    .then(({ data }) => {
      const variables = data?.variables?.variables.filter((variable) => variable.type !== 'text');
      return inputConfirmModal({
        okText: i18n.t('admin.inputFieldModal.ok'),
        cancelText: i18n.t('admin.inputFieldModal.cancel'),
        fields: getInputFieldFields({ categoryId, parentId, variables }),
        headerText: i18n.t('admin.inputFieldModal.addModalTitle'),
        onSubmit: (props) =>
          createInputField({
            categoryId,
            parentId,
            parentType,
            isLibrary,
            ...props,
          }),
        value: {
          name: '',
          type: InputFieldType.INPUT,
          options: [],
          optionPDFNameTitle: '',
          optionPDFValueTitle: '',
          defaultType: InternalInputFieldType.INPUT,
          allowedRangeMaxValue: null,
          allowedRangeMinValue: null,
          defaultValue: null,
        },
        categoryId,
        validationSchema: (props) => optionsValidationSchema({ variables, ...props }),
        errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.duplicatedErrorMessage')] },
        width: '800px',
      });
    });

const editInputField = ({ categoryId, parentId, isLibrary, ...inputField }) =>
  apollo
    .query({
      query: adminVariableListQuery,
      variables: { isLibrary },
      fetchPolicy: 'cache-first',
    })
    .then(({ data }) => {
      const variables = data?.variables?.variables.filter((variable) => variable.type !== 'text');
      return inputConfirmModal({
        fields: getInputFieldFields({ categoryId, parentId, inputFieldId: inputField._id, variables }),
        headerText: i18n.t('admin.inputFieldModal.editModalTitle'),
        onSubmit: updateInputField,
        value: {
          defaultType: InternalInputFieldType.INPUT,
          allowedRangeMaxValue: null,
          allowedRangeMinValue: null,
          defaultValue: null,
          ...inputField,
        },
        okText: i18n.t('admin.inputFieldModal.ok'),
        cancelText: i18n.t('admin.inputFieldModal.cancel'),
        validationSchema: (props) => optionsValidationSchema({ variables, ...props }),
        errorResolver: { Duplicated: ['name', i18n.t('admin.inputFieldModal.duplicatedErrorMessage')] },
        width: '800px',
      });
    });

const inputFieldTableColumns = ({ t, categoryId, parentId, isLibrary }) => [
  {
    dataIndex: 'sort',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
  },
  {
    className: classNames('col-8', 'table-text'),
    title: t('admin.inputFieldTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: classNames('col-4', 'table-text'),
    title: t('admin.inputFieldTableColumns.type'),
    dataIndex: 'type',
    render: (e) => t(`common.InputField.${e}`),
    key: 'type',
  },
  {
    title: t('admin.inputFieldTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    className: 'action-column-2',
    render: ({ _id, ...props }) => {
      return (
        <>
          <Button
            className="ant-btn-default"
            ghost
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editInputField({ categoryId, parentId, _id, isLibrary, ...props })}
          />{' '}
          <Button
            className="ant-btn-default"
            type="danger"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteInputFieldWithConfirmation({ _id, isLibrary, categoryId, parentId })}
          />
        </>
      );
    },
  },
];

const InputFieldsHelperWidget = ({ parentType }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.howUseButton`,
      )}
      title={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.modalInfo.title`,
      )}
      helpText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.modalInfo.helpText`,
      )}
      videoCaption={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.modalInfo.videoCaption`,
      )}
      videoUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.modalInfo.videoUrl`,
      )}
      imageUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.InputFieldsHelperWidget.modalInfo.imageUrl`,
      )}
    />
  );
};

const InputFieldsHelperWidgetMemo = memo(InputFieldsHelperWidget, equal);

const InputFieldsTab = ({ categoryId, parentId, parentType, inputFields, onSortEnd }) => {
  const { t } = useTranslation();
  const extraDropProps = {};
  const libraryContext = useLibraryContext();
  const { isLibrary } = libraryContext || {};
  if (parentType === 'categoryItem') extraDropProps.itemId = parentId;

  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: inputFields, onSortEnd });

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={() => addNewInputField({ categoryId, parentId, parentType, isLibrary })}
        type="primary"
        className="margin-right-16"
      >
        {t('admin.addInputButton')}
      </Button>
      <InputFieldsHelperWidgetMemo parentType={parentType} />
      <br />
      <br />
      <div className="table-wrapper">
        <TableMemo
          className="table-cell-break-all"
          columns={inputFieldTableColumns({ t, categoryId, parentId, isLibrary })}
          dataSource={inputFields}
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyInputFieldListMessage')} /> }}
          pagination={false}
          rowKey={(record) => record?._id}
          scroll={{
            x: 372,
          }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
    </>
  );
};

export default memo(InputFieldsTab, equal);
