import apollo from 'graphql/apollo';
import { deleteCategoryMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { clientDelete, toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

export const deleteCategory = async ({ _id, isLibrary }) =>
  apollo.mutate({
    mutation: deleteCategoryMutation,
    variables: { _id },
    update: (cache) => {
      const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });

      const newCategories = clientDelete({ array: cachedData.categories, _id });

      cache.writeQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary },
        data: {
          categories: newCategories,
        },
      });
    },
  });
export default deleteCategory;

export const deleteCategoryWithConfirmation = ({ _id, isLibrary }) =>
  confirmModal({
    cancelText: i18n.t('admin.deleteCategoryConfirmation.cancel'),
    maskClosable: true,
    okText: i18n.t('admin.deleteCategoryConfirmation.ok'),
    okType: 'danger',
    onOk: () => deleteCategory({ _id, isLibrary }).then(toast.successDefault).catch(toastGraphQLError),
    title: i18n.t('admin.deleteCategoryConfirmation.title'),
  });
