import i18n from 'i18n';
import { cloneDeep, find, sortBy } from 'lodash';
import get from 'lodash/get';
import { useEffect, useRef } from 'react';
import confirmModal from './confirmModal';
import toast from './toast';

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value, initialValue = {}) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/*
  Put helper functions here. If you notice that there are few functions with the same domain,
  there is no better time than now to move them to a separate file
*/
export const wait = (timeout = 3000) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export const formatCurrency = (() => {
  const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
  return (number) => {
    const parts = formatter.formatToParts(number);
    const strArray = [];
    // filter out the decimal part
    parts.forEach((part) => {
      if (['decimal', 'fraction'].includes(part.type)) return;
      strArray.push(part.value);
    });
    return strArray.join('');
  };
})();

export function getMessageFromGraphQLError(error = {}) {
  if (error.networkError) {
    return error.message;
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors.map((err) => err.message).join('\n');
  }
  return error.message;
}

export function getOriginalMessageFromGraphQLError(error = {}) {
  if (error.networkError) {
    return error.message;
  }
  if (error.graphQLErrors) {
    return error.graphQLErrors.map((err) => err.originalMessage).join('\n');
  }
  return error.message;
}

export function grabFirstGQLDataResult(data) {
  if (!data) return undefined;
  return Object.values(data)[0];
}

const replaceErrorPlaceholder = (text) =>
  typeof text === 'string'
    ? text
        .replace(/^\[(.*)\]$/, /* remove '[' from start and ']' from end */ '$1')
        .replace('Error: ', '')
        .replace('GraphQL error: ', '')
    : '';
export const graphQLErrorText = (error, logError) => {
  if (
    !error?.graphQLErrors?.[0]?.extensions?.exception?.doNotLogOnClient &&
    !error?.graphQLErrors?.[0]?.extensions?.exception?.logged &&
    logError
  )
    console.error(error);
  return (
    '' ||
    replaceErrorPlaceholder(get(error, 'graphQLErrors.0.extensions.exception.reason', '')) ||
    replaceErrorPlaceholder(error.reason) ||
    replaceErrorPlaceholder(get(error, 'graphQLErrors.0.extensions.exception.message', '')) ||
    replaceErrorPlaceholder(get(error, 'graphQLErrors.0.message', '')) ||
    replaceErrorPlaceholder(error.message) ||
    replaceErrorPlaceholder(get(error, 'graphQLErrors.0.extensions.exception.stacktrace[0]', '')) ||
    replaceErrorPlaceholder(error?.error) ||
    replaceErrorPlaceholder(`${error}`) ||
    'Unknown error'
  );
};
export const toastGraphQLError = (error, logError = 1) => {
  toast.error(graphQLErrorText(error, logError));
};

export const openURL = (href) =>
  (function aaa(a) {
    document.body.appendChild(a);
    a.setAttribute('href', href);
    a.dispatchEvent(
      (function aa(e) {
        e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
        return e;
      })(document.createEvent('MouseEvents')),
    );
  })(document.createElement('a'));

// https://stackoverflow.com/questions/10593337/is-there-any-way-to-create-mongodb-like-id-strings-without-mongodb
// eslint-disable-next-line no-shadow
export const ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
  s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

export const clientShift = ({ array: _array, _id, shift, orderBy = 'asc' }) => {
  const array = _array.map((e, i) => ({ order: i + 1, ...e }));

  const countCategories = array.length;
  const currentOrder = find(array, { _id }).order || 1;

  let newArr = cloneDeep(array);
  if (shift > 0) {
    newArr = newArr.map((e) => {
      if (e.order > currentOrder && e.order <= currentOrder + shift) return { ...e, order: (e.order ?? 2) - 1 };
      return e;
    });
  } else {
    newArr = newArr.map((e) => {
      if (e.order >= currentOrder + shift && e.order < currentOrder) return { ...e, order: (e.order ?? 0) + 1 };
      return e;
    });
  }

  const newItem = find(newArr, { _id });
  newItem.order += shift;
  if (newItem.order < 1) newItem.order = 1;
  if (newItem.order > countCategories) newItem.order = countCategories;
  return sortBy(newArr, ({ order }) => (orderBy === 'asc' ? order : -order));
};

export const clientDelete = ({ array, _id }) => {
  const deletedElement = find(array, { _id });

  return array
    .filter((element) => element._id !== _id)
    .map((element) => {
      return { ...element, order: deletedElement.order < element.order ? element.order - 1 : element.order };
    });
};

export const clientDeleteInputField = ({ array, _id }) => {
  const newArray = cloneDeep(array);

  const deletedElement = find(newArray, { _id });
  const { parent } = deletedElement;
  const res = newArray
    .filter((element) => element._id !== _id)
    .map((element) => {
      if (parent !== element.parent) return element;
      return { ...element, order: deletedElement.order < element.order ? element.order - 1 : element.order };
    });

  return res;
};

export const isSaveChangesButtonFoundInModalByEvent = ({ event, displayToast }) => {
  const saveChangesButtonFound = event.target?.closest?.('.ant-modal-wrap')?.querySelector?.('.save-changes-button');
  if (saveChangesButtonFound) {
    displayToast && toast.error(i18n.t('common.toast.errors.pleaseSaveChanges'));
    return true;
  }
  return false;
};
export const checkChangesModal = () => {
  return new Promise((resolve) => {
    confirmModal({
      cancelText: i18n.t('common.unsavedChangesModal.cancel'),
      okText: i18n.t('common.unsavedChangesModal.proceed'),
      okType: 'danger',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      title: i18n.t('common.unsavedChangesModal.title'),
    });
  });
};

/**
 * Confirms if there are unsaved changes in a form by displaying a modal.
 * @param {Object} params - The parameters object.
 * @param {Event} params.event - The event object to check for the save changes button.
 * @returns {Promise<boolean>} - Returns a promise that resolves to `true` if there are unsaved changes and the user chooses to proceed, otherwise `false`.
 */
export const confirmUnsavedChangesOfFormWithModal = async ({ event }) => {
  const isFound = isSaveChangesButtonFoundInModalByEvent({ event });
  if (isFound) {
    const proceed = await checkChangesModal();
    return !proceed;
  }
  return false;
};

// https://stackoverflow.com/a/33511005/11116360
export const trimObj = (obj) => {
  if (!Array.isArray(obj) && typeof obj !== 'object') return obj;
  return Object.keys(obj).reduce(
    (acc, key) => {
      acc[key.trim()] = typeof obj[key] === 'string' ? obj[key].trim() : trimObj(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );
};

export const downloadByUrl = (url) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
