import { useMemo } from 'react';
import { useOptimizedCachedQuery } from 'graphql/utils';
import { adminDocumentTemplateListForPageQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const useDocumentTemplateListForPage = (isLibrary) => {
  const { data, loading, error, ...rest } = useOptimizedCachedQuery(adminDocumentTemplateListForPageQuery, {
    variables: { isLibrary },
    fetchPolicy: 'cache-and-network',
  });
  return useMemo(() => {
    const documentTemplates = grabFirstGQLDataResult(data) || [];
    return {
      data: Array.isArray(documentTemplates)
        ? documentTemplates.map((row) => ({
            ...row,
            createdAt: new Date(row.createdAt),
          }))
        : [],
      loading,
      error,
      ...rest,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);
};

export default useDocumentTemplateListForPage;
