import { Pagination as AntDPagination } from 'antd';
import classes from './Pagination.module.less';

/**
 * Pagination component for list layouts to change the page and page size
 * @param {object} inputProperties - Input properties of the component
 * @param {number} inputProperties.currentPage - Current page of the pagination
 * @param {number} inputProperties.currentPageSize - Current page size of the pagination
 * @param {number} inputProperties.count - Total count of the items
 * @param {(page: number, pageSize: number) => void} inputProperties.changePage - Function to change the page and or page size
 * @returns {JSX.Element} Pagination component
 * @component
 */
const Pagination = ({ currentPage, currentPageSize, count, changePage }) => {
  return (
    <div className={classes.PaginationWrapper}>
      <AntDPagination
        current={currentPage}
        total={count}
        showSizeChanger
        onChange={changePage}
        pageSize={currentPageSize}
        className={classes.Pagination}
      />
    </div>
  );
};

export default Pagination;
