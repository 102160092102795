import { useCallback, useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from 'lodash';

/**
 * Search field component for search input with debounced query change
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.placeholder - Placeholder for the search input
 * @param {string} inputProperties.currentQuery - Current search query
 * @param {(newQuery: string) => void} inputProperties.onQueryChange - Function to call when the search query changes
 * @returns {JSX.Element} Search field component
 * @component
 */
const SearchField = ({ placeholder, currentQuery, onQueryChange }) => {
  const [query, setQuery] = useState(currentQuery);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnQueryChange = useCallback(
    debounce((value) => {
      if (onQueryChange) onQueryChange(value);
    }, 300),
    [onQueryChange],
  );

  useEffect(() => {
    if (query !== currentQuery) setQuery(currentQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuery]);

  return (
    <Input
      allowClear
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      size="large"
      value={query}
      onChange={(event) => {
        setQuery(event.target.value);
        if (onQueryChange) debouncedOnQueryChange(event.target.value);
      }}
    />
  );
};

export default SearchField;
