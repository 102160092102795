import i18n from 'i18n';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/exports';
import { SortDirection } from '../SortBar/SortBarEntry';

/**
 * Parse sort parameter extracted from URL to state array
 * @param {string} parameter - Sort parameter from URL
 * @returns {string[]} Array of sort parameters [fieldName, direction] or empty array if parameter is empty
 */
export const parseSortParameter = (parameter) => {
  if (!parameter || parameter === '') return [];
  const [fieldName, direction] = parameter.split(',');
  return [fieldName, direction];
};

/**
 * Parse filter parameter extracted from URL to state array
 * @param {string} parameter - Filter parameter from URL
 * @returns {string[][]} Array of filter parameters [[fieldName, value], ...] or empty array if parameter is empty
 */
export const parseFilterParameter = (parameter) => {
  if (!parameter || parameter === '') return [];
  const filter = parameter.split(';');
  return filter.map((filterElement) => filterElement.split(','));
};

/**
 * Build sort parameter for URL from state array
 * @param {string[]} sort - Sort state [fieldName, direction]
 * @returns {string} Sort parameter for URL
 */
export const buildSortParameter = (sort) => {
  return sort.join(',');
};

/**
 * Build filter parameter for URL from state array
 * @param {string[][]} filter - Filter state [[fieldName, value], ...]
 * @returns {string} Filter parameter for URL
 */
export const buildFilterParameter = (filter) => {
  return filter.map((filterElement) => filterElement.join(',')).join(';');
};

/**
 * Compare function for sorting elements in ascending order for different data types (numbers, strings, dates)
 * Compares strings by converting them to lowercase
 * @param {*} element1 - First element to compare
 * @param {*} element2 - Second element to compare to
 * @returns {number} -1 if element1 < element2, 1 if element1 > element2, 0 if element1 === element2
 */
export const defaultCompareFunc = (element1, element2) => {
  const value1 = typeof element1 === 'string' ? element1.toLowerCase() : element1;
  const value2 = typeof element2 === 'string' ? element2.toLowerCase() : element2;

  if (value1 < value2) return -1;
  if (value1 > value2) return 1;
  return 0;
};

/**
 * Sorts data based on sort state
 * @param {object[]} data - Data to be sorted
 * @param {string[]} sort - Sort state [fieldName, direction]
 * @param {(a: any, b: any) => number} compareFunc - Optional compare function for sorting
 * @returns {object[]} Sorted data
 */
export const sortData = (data, sort, compareFunc) => {
  if (!data || data.length === 0) return [];
  if (!sort || sort.length !== 2) return data;
  if (compareFunc)
    return [...data].sort((element1, element2) =>
      sort[1] === SortDirection.ASC
        ? compareFunc(element1[sort[0]], element2[sort[0]])
        : compareFunc(element2[sort[0]], element1[sort[0]]),
    );

  return [...data].sort((element1, element2) =>
    sort[1] === SortDirection.ASC
      ? defaultCompareFunc(element1[sort[0]], element2[sort[0]])
      : defaultCompareFunc(element2[sort[0]], element1[sort[0]]),
  );
};

/**
 * Filters data based on filter state
 * Uses custom filter functions if provided in filterSettings as applyFilter (element: object, filterValue: string) => boolean
 * @param {object[]} data - Data to be filtered
 * @param {string[][]} filter - Filter state [[fieldName, value], ...]
 * @param {object[]} filterSettings - Optional filter settings for custom filter functions
 * @returns {object[]} Filtered data
 */
export const filterData = (data, filter, filterSettings) => {
  if (!data || data.length === 0) return [];
  if (!filter || filter.length === 0) return data;
  return data.filter((element) =>
    filter.every((filterElement) => {
      if (!filterElement[1]) return true;
      if (filterSettings) {
        const filterSetting = filterSettings.find((setting) => setting.fieldName === filterElement[0]);
        if (filterSetting && filterSetting.applyFilter) return filterSetting.applyFilter(element, filterElement[1]);
      }
      return `${element[filterElement[0]]}` === filterElement[1];
    }),
  );
};

/**
 * Builds an object with placeholder keys as keys and the localized placeholder labels as values for rich text to plain text conversation for search and sort
 * @returns {object} Placeholder label object
 */
export const buildPlaceholderLabelObject = () => {
  const placeholderTranslations = i18n.t('admin.Placeholders', { returnObjects: true });
  Object.keys(placeholderTranslations).forEach((key) => {
    placeholderTranslations[key] = placeholderTranslations[key].label;
  });
  return placeholderTranslations;
};

/**
 * Searches data based on search query
 * Searches in all string fields if fields are not provided
 * Or searches in provided fields
 * @param {object[]} data - Data to be searched
 * @param {string} query - Search query
 * @param {string[]} fields - Optional fields to search in
 * @returns {object[]} Filtered data
 */
export const searchQuery = (data, query, fields) => {
  if (!data || data.length === 0) return [];
  if (!query || query === '') return data;
  if (fields)
    return data.filter((element) =>
      fields.some((field) => {
        let value = element[field];
        if (value && RichText.isRichTextString(value))
          value = RichText.richEditorTextToText(value, buildPlaceholderLabelObject());
        return value.toLowerCase().includes(query.toLowerCase());
      }),
    );
  return data.filter((element) =>
    Object.values(element).some((value) => {
      if (!value || typeof value !== 'string') return false;
      let convertedValue = value;
      if (RichText.isRichTextString(value))
        convertedValue = RichText.richEditorTextToText(value, buildPlaceholderLabelObject());
      return convertedValue.toLowerCase().includes(query.toLowerCase());
    }),
  );
};

/**
 * Sets search parameter in URL by pushing new history item to url history stack
 * @param {string} key - Search parameter key
 * @param {string} value - Search parameter value
 */
export const modifySearchParameters = (key, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url);
};

/**
 * Calculates page number from skip and limit
 * @param {number} skip - Number of items to skip
 * @param {number} limit - Number of items per page
 * @returns {number} Page number
 */
export const getPageFromSkipAndLimit = (skip, limit) => {
  return skip / limit + 1;
};

/**
 * Calculates number of items to skip from page and limit
 * @param {number} page - Page number
 * @param {number} limit - Number of items per page
 * @returns {number} Number of items to skip
 */
export const getSkipFromPageAndLimit = (page, limit) => {
  return (page - 1) * limit;
};

/**
 * Checks if all data is selected
 * @param {object[]} data - Data to be checked
 * @param {object[]} selected - Selected data
 * @returns {boolean} true if all data is selected, false otherwise
 */
export const checkIfSelectAllActive = (data, selected) => {
  return data.every(
    (element) => !!selected.find((selectedElement) => JSON.stringify(selectedElement) === JSON.stringify(element)),
  );
};
