import { useCallback } from 'react';
import { deleteProjectCardRevision, deleteProjectCard, deleteProjectCardAndClient } from 'graphql/methods';
import routePaths from 'router/route-paths';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileExcelOutlined, RedoOutlined, SolutionOutlined } from '@ant-design/icons';
import classes from './DeleteTypeSelectionModal.module.less';
import { DeleteOptionCard } from './DeleteOptionCard';

/**
 * Modal component for selecting the type of deletion for a project card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visible - Determines if the modal is visible.
 * @param {Function} props.handleCancel - Callback function for canceling the deletion.
 * @param {boolean} props.isDigitalSignatureStarted - Determines if the digital signature process has started.
 * @returns {JSX.Element} The DeleteTypeSelectionModal component.
 */
const DeleteTypeSelectionModal = ({ visible, handleCancel, isDigitalSignatureStarted }) => {
  const { id: shoppingCartId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDeleteCurrentRevision = useCallback(() => {
    if (isDigitalSignatureStarted) return;
    deleteProjectCardRevision({
      _id: shoppingCartId,
      onOk: () => {
        navigate(routePaths.shoppingCartEntries, { replace: true });
      },
    });
  }, [navigate, shoppingCartId, isDigitalSignatureStarted]);

  const handleDeleteProjectCard = useCallback(() => {
    if (isDigitalSignatureStarted) return;
    deleteProjectCard({
      _id: shoppingCartId,
      onOk: () => {
        navigate(routePaths.shoppingCartEntries, { replace: true });
      },
    });
  }, [navigate, shoppingCartId, isDigitalSignatureStarted]);

  const handleDeleteProjectCardAndClient = useCallback(() => {
    if (isDigitalSignatureStarted) return;
    deleteProjectCardAndClient({
      _id: shoppingCartId,
      onOk: () => {
        navigate(routePaths.shoppingCartEntries, { replace: true });
      },
    });
  }, [navigate, shoppingCartId, isDigitalSignatureStarted]);

  return (
    <Modal
      className="w-70"
      visible={visible}
      onCancel={handleCancel}
      maskClosable
      okButtonProps={{ style: { display: 'none' } }}
      title={t('admin.projectCard.deletion.modalOptions.title')}
    >
      <Alert
        type="info"
        showIcon
        className={classes.alertInfoBig}
        message={
          <>
            <p>{t('admin.projectCard.deletion.alert.introText')}</p>
            <p>{t('admin.projectCard.deletion.alert.paragraph')}</p>
            <p className="red-warning-text">
              <i>{t('admin.projectCard.deletion.alert.notRestorableWarning')}</i>
            </p>
            <p>
              <strong>{t('admin.projectCard.deletion.alert.chooseDesiredAction')}</strong>
            </p>
          </>
        }
      />
      <DeleteOptionCard
        title={t('admin.projectCard.deletion.modalOptions.deleteCurrentRevision.title')}
        description={t('admin.projectCard.deletion.modalOptions.deleteCurrentRevision.description')}
        deleteButton={
          <Button danger type="primary" className={classes.deleteButton} onClick={handleDeleteCurrentRevision}>
            <strong>{t('admin.projectCard.deletion.modalOptions.deleteCurrentRevision.button')}</strong>
          </Button>
        }
        icon={<RedoOutlined />}
      />
      <DeleteOptionCard
        title={t('admin.projectCard.deletion.modalOptions.deleteProjectCard.title')}
        description={t('admin.projectCard.deletion.modalOptions.deleteProjectCard.description')}
        deleteButton={
          <Button danger type="primary" className={classes.deleteButton} onClick={handleDeleteProjectCard}>
            <strong>{t('admin.projectCard.deletion.modalOptions.deleteProjectCard.button')}</strong>
          </Button>
        }
        icon={<FileExcelOutlined />}
      />
      <DeleteOptionCard
        title={t('admin.projectCard.deletion.modalOptions.deleteProjectCardAndClient.title')}
        description={t('admin.projectCard.deletion.modalOptions.deleteProjectCardAndClient.description')}
        deleteButton={
          <Button danger type="primary" className={classes.deleteButton} onClick={handleDeleteProjectCardAndClient}>
            <strong>{t('admin.projectCard.deletion.modalOptions.deleteProjectCardAndClient.button')}</strong>
          </Button>
        }
        icon={<SolutionOutlined />}
      />
    </Modal>
  );
};
export default DeleteTypeSelectionModal;
