import { useEffect } from 'react';
import Card from 'components/common/Card';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FaSignature } from 'react-icons/fa';
import useUsersInTenantList from 'hooks/user/useUsersInTenantList';
import useContactList from 'hooks/user/useContactList';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import Switch from 'components/common/Switch';
import SelectInputField from 'components/common/SelectInputField';
import usePreferences from 'hooks/user/usePreferences';
import { useDocumentTemplatesForShoppingCartContext } from 'contexts/DocumentTemplatesForShoppingCartContext';
import ErrorAlert from './ErrorAlert';
import classes from './SignatureCard.module.less';
import { filterKanzleipilotTeamUsers } from './utils';

/** @namespace ShoppingCartEdit */

/**
 * @enum {String}
 */
export const SIGNABLE = {
  BOTH: 'BOTH',
  ONLY_TENANT: 'ONLY_TENANT',
  ONLY_COMPANY: 'ONLY_COMPANY',
  NOT_SIGNABLE: 'NOT_SIGNABLE',
};

/**
 * Checks which signable state all docs result in
 * @param {SIGNABLE[]} isSignablePropsOfSelectedDocumentTemplates - Collection of isSignable prop of all selected document templates
 * @returns {SIGNABLE} returns both signable if min one both signable doc temp is included or min one tenant only and one company only, else if min one tenant only doc temp is included it returns tenant only, else if min one company only doc temp is included it returns company only else returns not signable
 */
const getSignatureCardIsSignableProp = (isSignablePropsOfSelectedDocumentTemplates) => {
  if (!isSignablePropsOfSelectedDocumentTemplates || isSignablePropsOfSelectedDocumentTemplates.length === 0)
    return SIGNABLE.NOT_SIGNABLE;
  const foundBoth = isSignablePropsOfSelectedDocumentTemplates.find((isSignable) => isSignable === SIGNABLE.BOTH);
  if (foundBoth) return SIGNABLE.BOTH;
  const foundOnlyCompany = isSignablePropsOfSelectedDocumentTemplates.find(
    (isSignable) => isSignable === SIGNABLE.ONLY_COMPANY,
  );
  const foundOnlyTenant = isSignablePropsOfSelectedDocumentTemplates.find(
    (isSignable) => isSignable === SIGNABLE.ONLY_TENANT,
  );
  if (foundOnlyCompany && foundOnlyTenant) return SIGNABLE.BOTH;
  if (foundOnlyCompany) return SIGNABLE.ONLY_COMPANY;
  if (foundOnlyTenant) return SIGNABLE.ONLY_TENANT;
  return SIGNABLE.NOT_SIGNABLE;
};

/**
 * Wrapper component for SignatureCard, which hides card, if card shouldn't visible</br>
 * Needs formik context of shopping cart edit/creation
 * (Needs a document template for shopping cart context)
 * @component
 * @returns {Object} Null or SignatureCard component
 */
const SignatureCardWrapper = () => {
  const { values, setFieldValueAndTouched, initialValues } = useFormikContext();
  const { allDocumentTemplateOptions } = useDocumentTemplatesForShoppingCartContext();
  const { preferences } = usePreferences({ fetchPolicy: 'cache-and-network' });

  const isSignablePropsOfSelectedDocumentTemplates = values?.documentTemplates?.map(
    (selectedTemplate) =>
      allDocumentTemplateOptions?.find((template) => template.value === selectedTemplate)?.isSignable,
  );

  const showCard =
    preferences?.digitalSignaturePreferences?.digitalSignatureEnabled &&
    isSignablePropsOfSelectedDocumentTemplates.find((isSignable) => isSignable !== SIGNABLE.NOT_SIGNABLE);

  useEffect(() => {
    if (!showCard) setFieldValueAndTouched('enableDigitalSignature', false);
    else setFieldValueAndTouched('enableDigitalSignature', initialValues?.enableDigitalSignature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCard]);

  if (!showCard) {
    return null;
  }

  const isSignable = getSignatureCardIsSignableProp(isSignablePropsOfSelectedDocumentTemplates);

  return <SignatureCard isSignable={isSignable} />;
};

/**
 * SignatureCard component for signee select in shopping cart edit/creation</br>
 * Needs formik context of shopping cart edit/creation
 * @param {Object} inputProperties - Input properties of component
 * @param {SIGNABLE} inputProperties.isSignable - Resulted isSignable property of all selected document templates
 * @component
 * @returns {Object} Card component with signee select and switch for enabling ds for the shopping cart
 */
const SignatureCard = ({ isSignable }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValueAndTouched, setFieldValue } = useFormikContext();
  const { data: users } = useUsersInTenantList();
  const { data: contacts } = useContactList(values.initializationConfigDate);
  const { preferences } = usePreferences({ fetchPolicy: 'cache-and-network' });
  const [currentUser] = useCurrentUser();

  const isForDigitalSignature = preferences?.digitalSignaturePreferences.digitalSignatureEnabled;
  const emailAddressError = /.*missingEmailAddress/.test(errors.companySignees);

  const tenantSigneesOptions = users
    ?.filter((user) => filterKanzleipilotTeamUsers(user, currentUser))
    .map(mapUsersToOptions);

  const companySigneesOption = values.contacts.map((contact) => {
    const contactProps = contacts?.find((fullContact) => fullContact._id === contact._id);
    const positionExtension = contactProps && contactProps.position && `(${contactProps.position})`;
    return {
      label: contactProps ? `${contactProps.firstName} ${contactProps.lastName} ${positionExtension}` : contact._id,
      value: JSON.stringify({
        _id: contact?._id || null,
        email: contact?.email || null,
      }),
    };
  });

  // Change switch state to false, if switch isn't rendered
  useEffect(() => {
    if (!isForDigitalSignature && values.enableDigitalSignature) {
      setFieldValueAndTouched('enableDigitalSignature', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForDigitalSignature, values]);

  // Update selected company signees, if contacts have changed after 100ms
  useEffect(() => {
    if (values.companySignees && values.companySignees.length > 0) {
      setTimeout(() => {
        const updatedSelectedContacts = values.companySignees
          .map((contact) => {
            const contactObj = JSON.parse(contact);
            const currentContactData = contacts?.find((fullContact) => fullContact._id === contactObj._id);
            if (!currentContactData) return null;
            return JSON.stringify({
              _id: currentContactData?._id || null,
              email: currentContactData?.email || null,
            });
          })
          .filter(Boolean);
        setFieldValue('companySignees', updatedSelectedContacts, true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  // Add current user, if this option is selected as default option
  useEffect(() => {
    if (
      isForDigitalSignature &&
      preferences?.digitalSignaturePreferences?.defaultTenantSignees?.length > 0 &&
      currentUser
    ) {
      const preSelectedSignees = preferences?.digitalSignaturePreferences?.defaultTenantSignees.filter(
        (signee) => signee !== 'CURRENT_USER',
      );
      if (
        preferences?.digitalSignaturePreferences?.defaultTenantSignees.find((signee) => signee === 'CURRENT_USER') &&
        !preSelectedSignees.find((signeeId) => signeeId === currentUser._id)
      )
        preSelectedSignees.push(currentUser._id);
      setFieldValue('tenantSignees', preSelectedSignees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForDigitalSignature, setFieldValue, preferences, currentUser]);

  const { enableDigitalSignature } = values;

  return (
    <Card
      bordered
      icon={<FaSignature />}
      title={
        isForDigitalSignature
          ? t('user.ShoppingCart.SignatureCard.label.withDigitalSignature')
          : t('user.ShoppingCart.SignatureCard.label.withoutDigitalSignature')
      }
    >
      {isForDigitalSignature ? (
        <div className={classes.enableDigitalSignatureSwitch}>
          <Switch
            label={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.label')}
            name="enableDigitalSignature"
            checkedChildren={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.on')}
            unCheckedChildren={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.off')}
          />
        </div>
      ) : null}
      {(isSignable === SIGNABLE.BOTH || isSignable === SIGNABLE.ONLY_COMPANY) && enableDigitalSignature ? (
        <SelectInputField
          name="companySignees"
          options={companySigneesOption}
          label={t('user.ShoppingCart.SignatureCard.companySigneesSelect.label')}
          enableErrorHandling={!emailAddressError}
          errorComponent={ErrorAlert}
        />
      ) : null}
      {(isSignable === SIGNABLE.BOTH || isSignable === SIGNABLE.ONLY_TENANT) && enableDigitalSignature ? (
        <SelectInputField
          name="tenantSignees"
          options={tenantSigneesOptions}
          label={t('user.ShoppingCart.SignatureCard.tenantSigneesSelect.label')}
          className={classes.tenantSigneeSelection}
          enableErrorHandling
          errorComponent={ErrorAlert}
        />
      ) : null}
      {emailAddressError && (
        <ErrorAlert className={classes.bookmarkedItemAlert}>
          {t('user.ShoppingCart.SignatureCard.errorMessage')}
        </ErrorAlert>
      )}
    </Card>
  );
};

export default SignatureCardWrapper;

/**
 * Map users to options for selecting signees
 * @param {Object} user - Input properties of component
 * @param {Obejct} user.profile - Profile of current user
 * @param {String} user.profile.firstName - First name of current user
 * @param {String} user.profile.lastName - Last name of current user
 * @param {String} user._id - Id of current user
 * @returns {Object} Object with label and value for select component
 */
const mapUsersToOptions = (user) => {
  return {
    label: `${user.profile.firstName} ${user.profile.lastName}`,
    value: user._id,
  };
};
