import { Row, Col } from 'antd';
import concatClassNames from 'utils/classNames';
import ListItemRowEntry from './ListItemRowEntry';
import classes from './ListItemRow.module.less';

/**
 * List item row component for list layout
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.rowConfig - Columns to show in the row
 * @param {string} inputProperties.rowConfig.fieldName - Field name of the item
 * @param {string} inputProperties.rowConfig.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} inputProperties.rowConfig.renderValue - Optional: Function to render the value
 * @param {boolean} inputProperties.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object} inputProperties.item - Item to show in the list item (data)
 * @param {boolean} inputProperties.showLabel - Should the label be shown
 * @param {boolean} inputProperties.bottomBorder - Should the row have a bottom border (Default: true)
 * @returns {JSX.Element} Row for a list item
 * @component
 */
const ListItemRow = ({ rowConfig, item, showLabel, alignLastEntryRight, bottomBorder = true }) => {
  const columns = rowConfig.map(({ fieldName, fieldLabel, renderValue }, index) => {
    const value = item[fieldName];
    const renderedValue = renderValue ? renderValue(value, item) : value;
    return (
      <Col
        key={fieldName}
        style={{
          paddingRight: '20px',
          textAlign: alignLastEntryRight && index === rowConfig.length - 1 ? 'right' : 'left',
          marginRight: index !== rowConfig.length - 1 ? '20px' : '0px',
          borderRight: index !== rowConfig.length - 1 ? '1px solid #D9D9D9' : 'none',
          marginTop: !showLabel ? '2.5px' : '0',
          marginBottom: !showLabel ? '2.5px' : '0',
        }}
        flex={alignLastEntryRight && index === rowConfig.length - 2 ? 'auto' : null}
      >
        <ListItemRowEntry label={fieldLabel} value={renderedValue} showLabel={showLabel} />
      </Col>
    );
  });
  return <Row className={concatClassNames(classes.Row, bottomBorder && classes.BottomBorderedRow)}>{columns}</Row>;
};

export default ListItemRow;
