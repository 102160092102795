import apollo from 'graphql/apollo';
import { moveItemMutation } from 'graphql/mutations';

const moveCategoryItem = async ({ _id, offset, order }, categoryListQuery, categoryListQueryVariables) =>
  apollo.mutate({
    mutation: moveItemMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery, variables: categoryListQueryVariables }],
  });
export default moveCategoryItem;
