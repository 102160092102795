/* eslint-disable react/no-unescaped-entities */
import { useLibraryContext } from 'contexts/LibraryContext';
import { deleteCategoryWithConfirmation, moveCategory } from 'graphql/methods';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DraggableCollapse from 'components/admin/drag/Collapse/Collapse';
import ItemTypes from 'constants/ItemTypes';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, FileAddOutlined, MoreOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { difference, filter, find } from 'lodash';
import equal from 'fast-deep-equal/es6/react';
import CategoryDragLayer from 'components/admin/drag/DragLayer/Category/CategoryDragLayer';
import inputConfirmModal from 'utils/inputConfirmModal';
import duplicateCategory from 'graphql/methods/admin/category/duplicateCategory';
import i18n from 'i18n';
import * as Yup from 'yup';
import toast from 'utils/toast';
import CollapsibleCategoryContent from './CollapsibleCategoryContent';
import CustomExpandIcon from './components/CustomExpandIcon/CustomExpandIcon';
import DraggableDecoration from './drag/DraggableDecoration';
import classes from './CatalogueConfiguration.module.less';
import { textsSchema } from './itemModal/schema';
import { FieldDiv } from './ItemsContainer';

const duplicateExistingCategory = ({ _id, isLibrary }) =>
  inputConfirmModal({
    fields: FieldDiv(),
    onSubmit: ({ name }) =>
      duplicateCategory({
        _id,
        name,
        isLibrary,
      }).then(toast.successDefault),
    value: {
      name: '',
    },
    errorResolver: { Duplicated: ['name', i18n.t('admin.duplicateCategoryConfirmation.duplicatedErrorMessage')] },
    headerText: i18n.t('admin.duplicateCategoryConfirmation.title'),
    okText: i18n.t('admin.duplicateCategoryConfirmation.ok'),
    cancelText: i18n.t('admin.duplicateCategoryConfirmation.cancel'),
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
      }),
    forceMultiField: true,
    width: '600px',
  });

function Extra({ _id, onOpen }) {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const menu = useMemo(
    () => (
      <Menu onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item onClick={() => duplicateExistingCategory({ _id, isLibrary })} icon={<FileAddOutlined />}>
          {t('admin.CatalogueConfiguration.collapseOptions.duplicate')}
        </Menu.Item>
        <Menu.Item onClick={onOpen} icon={<EditOutlined />}>
          {t('admin.CatalogueConfiguration.collapseOptions.edit')}
        </Menu.Item>
        <Menu.Item danger onClick={() => deleteCategoryWithConfirmation({ _id, isLibrary })} icon={<DeleteOutlined />}>
          {t('admin.CatalogueConfiguration.collapseOptions.remove')}
        </Menu.Item>
      </Menu>
    ),
    [_id, isLibrary, onOpen, t],
  );
  return (
    <Dropdown overlay={menu}>
      <MoreOutlined
        onClick={(e) => e.stopPropagation()}
        className={`${classes.categoryOptions} hover-background-light-primary`}
      />
    </Dropdown>
  );
}
const ExtraMemo = memo(Extra, equal);

const DragDecoration = <DraggableDecoration height="49px" />;
const draggableProps = { isConnectedPreview: false, isTable: false };
const collapseProps = {
  expandIcon: (props) => <CustomExpandIcon {...props} />,
};
const panelProps = {
  header: (record) => record.name,
  extra: ExtraMemo,
};

function CatalogueConfigurationDraggable({ categories }) {
  const { isLibrary } = useLibraryContext();
  const newCategoriesIdsRef = useRef([]);
  const [current, setCurrent] = useState(newCategoriesIdsRef?.current);
  useEffect(() => {
    const newCategories = filter(categories, { isNew: true }).map((e) => e._id);
    if (difference(newCategories, current).length) {
      if (newCategories?.length > 0) {
        const focusedCategory = [newCategories[0]];
        setCurrent(focusedCategory);
      } else {
        setCurrent(newCategories);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories?.length]);

  const moveCategoryMutation = useCallback(
    (_id, data) => {
      const order = find(data, { _id })?.order;
      moveCategory({ _id, order }, isLibrary);
    },
    [isLibrary],
  );
  const moveOptions = useMemo(() => ({ orderBy: 'desc', shiftBy: 'order' }), []);

  return (
    <>
      <CategoryDragLayer />

      <DraggableCollapse
        moveOptions={moveOptions}
        newKeys={current}
        component={CollapsibleCategoryContent}
        nameElementData="category"
        data={categories}
        moveMutation={moveCategoryMutation}
        itemTypes={ItemTypes.CATEGORY}
        draggableProps={draggableProps}
        collapseProps={collapseProps}
        panelProps={panelProps}
        decorate={DragDecoration}
        listOnlyNewestEntryOnCreate
      />
    </>
  );
}

export default memo(CatalogueConfigurationDraggable, equal);
