/* eslint-disable import/prefer-default-export */

import groupBy from 'lodash/groupBy';
import max from 'lodash/max';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

// adds "^" symbol to query string where error exist
export const formatQueryByErrorPositions = ({ queryString, errorPositions }) => {
  if (!errorPositions) return queryString;
  try {
    const columnsByLine = groupBy(errorPositions, 'line');
    const lineNumbers = Object.keys(columnsByLine).sort((a, b) => b - a);
    const queryStringLines = queryString.split('\n');
    lineNumbers.forEach((lineIndex) => {
      const columns = columnsByLine[lineIndex].map((object) => object.column);
      const maxColumn = max(columns);
      queryStringLines.splice(lineIndex, 0, '');
      queryStringLines[lineIndex] = new Array(maxColumn).fill(' ');
      columns.forEach((column) => {
        queryStringLines[lineIndex][column - 1] = '^';
      });
      queryStringLines[lineIndex] = queryStringLines[lineIndex].join('');
    });
    return queryStringLines.join('\n').trim();
  } catch (e) {
    console.error(e);
    return queryString;
  }
};

const config = {
  nextFetchPolicy: 'cache-first',
};

export const useCachedQuery = (query, options) => {
  const props = useQuery(query, { ...config, ...options });
  const prevData = useRef();
  if (props.data) prevData.current = props.data;
  return { ...props, data: prevData.current };
};

export const useOptimizedCachedQuery = (query, options) => {
  const { data, loading, error, ...advanced } = useQuery(query, { ...config, ...options });
  const prevData = useRef();
  if (data) prevData.current = data;
  return useMemo(() => {
    return { ...advanced, error, loading, data: prevData.current };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);
};

export const useCachedLazyQuery = (query, options) => {
  const [callback, props] = useLazyQuery(query, { ...config, ...options });
  const prevData = useRef();
  if (props.data) prevData.current = props.data;
  return [callback, { ...props, data: prevData.current }];
};

export const useRerender = () => {
  const [, rerender] = useState(1);
  return useCallback(() => rerender((prev) => prev + 1), []);
};
