/**
 * Utility class for managing the responsive layout of the PageContainer component.
 */
class PageContainerUtils {
  /**
   * @param {Object} params - Parameters for initializing the PageContainerUtils class.
   * @param {Object} params.titleContainerRef - Ref object for the title container.
   * @param {Object} params.defaultActionButtonsContainerRef - Ref object for the default action buttons container.
   * @param {Object} params.responsiveActionButtonsContainerRef - Ref object for the responsive action buttons container.
   * @param {number} params.width - Current width of the window.
   * @param {Function} params.setResponsiveMode - Function to set the responsive mode state.
   */
  constructor({
    titleContainerRef,
    defaultActionButtonsContainerRef,
    responsiveActionButtonsContainerRef,
    width,
    setResponsiveMode,
  }) {
    this.titleContainerRef = titleContainerRef;
    this.defaultActionButtonsContainerRef = defaultActionButtonsContainerRef;
    this.responsiveActionButtonsContainerRef = responsiveActionButtonsContainerRef;
    this.width = width;
    this.setResponsiveMode = setResponsiveMode;

    this.handleCurrentLayout = this.handleCurrentLayout.bind(this);
    this.areButtonsFullyWrapped = this.areButtonsFullyWrapped.bind(this);
    this.calculateButtonDistanceAndFindNearest = this.calculateButtonDistanceAndFindNearest.bind(this);
    this.calculateRightContainerFreeSpace = this.calculateRightContainerFreeSpace.bind(this);
    this.determineBestResponsiveLayout = this.determineBestResponsiveLayout.bind(this);
    this.activateResponsiveBelowHeader = this.activateResponsiveBelowHeader.bind(this);
    this.getActionButtons = this.getActionButtons.bind(this);

    this.responsiveMode = false;

    this.initialBreakpoint = 9999;
    this.windowBreakpoint = this.initialBreakpoint;
  }

  /**
   * Handles the current layout of the page container, determining whether to activate responsive mode.
   */
  handleCurrentLayout() {
    const {
      titleContainerRef,
      defaultActionButtonsContainerRef,
      responsiveActionButtonsContainerRef,
      getActionButtons,
      areButtonsFullyWrapped,
      calculateButtonDistanceAndFindNearest,
      calculateRightContainerFreeSpace,
      determineBestResponsiveLayout,
    } = this;

    if (
      (titleContainerRef?.current && defaultActionButtonsContainerRef?.current) ||
      responsiveActionButtonsContainerRef?.current
    ) {
      const actionButtons = getActionButtons();
      const buttonsAreFullyWrapped = areButtonsFullyWrapped();
      const { smallestDistance, nearestButton, widestButton } = calculateButtonDistanceAndFindNearest(actionButtons);

      const rightContainerFreeSpace = calculateRightContainerFreeSpace(defaultActionButtonsContainerRef.current);
      const rightContainerHasSpaceForWidestButton = () =>
        rightContainerFreeSpace > widestButton?.getBoundingClientRect().width;

      determineBestResponsiveLayout(
        nearestButton,
        buttonsAreFullyWrapped,
        smallestDistance,
        rightContainerHasSpaceForWidestButton,
      );
    }
  }

  /**
   * Checks if the action buttons are fully wrapped, meaning each button is on a new line.
   * @returns {boolean} - True if buttons are fully wrapped, false otherwise.
   */
  areButtonsFullyWrapped() {
    const actionButtons = this.getActionButtons();
    if (!actionButtons?.length) return false;

    const yPositions = [];
    actionButtons.forEach((button) => {
      const rectButton = button.getBoundingClientRect();
      yPositions.push(rectButton.y);
    });
    const uniqueYPositions = [...new Set(yPositions)];

    return uniqueYPositions.length === actionButtons.length;
  }

  /**
   * Calculates the distance between the title container and action buttons, finding the nearest button.
   * @param {Array<HTMLElement>} actionButtons - Array of action button elements.
   * @returns {Object} - Object containing the widest button, smallest distance, and nearest button.
   */
  calculateButtonDistanceAndFindNearest(actionButtons) {
    const titleContainerRect = this.titleContainerRef.current.getBoundingClientRect();

    let nearestButton;
    let smallestDistance = Infinity;
    let widestButton = null;
    let maxWidth = 0;

    if (actionButtons?.length) {
      actionButtons.forEach((button) => {
        const rectButton = button.getBoundingClientRect();
        const buttonWidth = rectButton.width;

        if (buttonWidth > maxWidth) {
          maxWidth = buttonWidth;
          widestButton = button;
        }
        const distanceX = rectButton.left - titleContainerRect.right;

        if (distanceX < smallestDistance) {
          smallestDistance = distanceX;
          nearestButton = button;
        }
      });
    }
    return { widestButton, smallestDistance, nearestButton };
  }

  /**
   * Calculates the free space available in the right container for additional elements.
   * @returns {number} - The available space in the right container.
   */
  calculateRightContainerFreeSpace() {
    const rightContainer = this.defaultActionButtonsContainerRef.current;
    const rightContainerRect = rightContainer.getBoundingClientRect();
    const rightContainerWidth = rightContainerRect.width;
    const rightContainerTolerance = 20;

    const childElements = Array.from(rightContainer.children);

    // Subtract the width of each child element from the available space
    let availableSpace = rightContainerWidth;
    childElements.forEach((child) => {
      const childRect = child.getBoundingClientRect();
      const childWidth = childRect.width;
      availableSpace -= childWidth;
    });

    availableSpace -= rightContainerTolerance;
    return availableSpace;
  }

  /**
   * Determines the best responsive layout based on various conditions.
   * @param {HTMLElement} nearestButton - The nearest button element.
   * @param {boolean} buttonsAreFullyWrapped - Flag indicating if buttons are fully wrapped.
   * @param {number} smallestDistance - The smallest distance between elements.
   * @param {Function} rightContainerHasSpaceForWidestButton - Function to check if there is space for the widest button.
   */
  determineBestResponsiveLayout(
    nearestButton,
    buttonsAreFullyWrapped,
    smallestDistance,
    rightContainerHasSpaceForWidestButton,
  ) {
    const maxDistanceBeforeBreakLayout = 20;
    const { activateResponsiveBelowHeader, initialBreakpoint, width, responsiveMode } = this;
    let { windowBreakpoint } = this;

    if (nearestButton && buttonsAreFullyWrapped && smallestDistance < maxDistanceBeforeBreakLayout) {
      activateResponsiveBelowHeader(true);
      windowBreakpoint = width;
    } else if (responsiveMode && rightContainerHasSpaceForWidestButton()) {
      if (windowBreakpoint === initialBreakpoint) {
        activateResponsiveBelowHeader(false);
      } else if (width > windowBreakpoint) {
        activateResponsiveBelowHeader(false);
      }
    }
  }

  /**
   * Activates or deactivates the responsive layout below the header.
   * @param {boolean} bool - Flag to indicate if responsive mode should be activated.
   */
  activateResponsiveBelowHeader(bool) {
    const { setResponsiveMode } = this;

    if (bool) {
      setResponsiveMode(true);
      this.responsiveMode = true;
    } else {
      setResponsiveMode(false);
      this.responsiveMode = false;
    }
  }

  /**
   * Gets the action buttons from the appropriate container based on the current mode.
   * @returns {Array<HTMLElement>} - Array of action button elements.
   */
  getActionButtons() {
    const { defaultActionButtonsContainerRef, responsiveActionButtonsContainerRef } = this;

    if (responsiveActionButtonsContainerRef.current) {
      const responsiveContainerResult = responsiveActionButtonsContainerRef.current.querySelectorAll(
        'button.ant-btn[type="button"]',
      );
      if (responsiveContainerResult.length > 0) {
        return responsiveContainerResult;
      }
    }
    if (defaultActionButtonsContainerRef.current) {
      return defaultActionButtonsContainerRef.current.querySelectorAll('button.ant-btn[type="button"]');
    }
    return [];
  }
}

export default PageContainerUtils;
