import { BrowserRouter as Router, useNavigate, useMatch, Routes, Route } from 'react-router-dom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { ConfirmAlertContainer } from '@uplab/react-confirm-alert';
import { LibraryProvider } from 'contexts/LibraryContext';
import useLoadTheme from 'hooks/common/useLoadTheme';
import OutOfServicePage from 'pages/global/OutOfServicePage';
import SigningOutOfService from 'pages/signing/SigningOutOfService';
import { isGlobalPage } from 'utils/isGlobalPage';
import AppRouter from './AppRouter';
import VerifyRouter from './VerifyRouter';
import AuthRouter from './AuthRouter';
import useCurrentUser from '../hooks/auth/useCurrentUser';
import routePaths from './route-paths';
import CustomFrontendRouter from './CustomFrontendRouter';
import { signingRoutesRouterComponents } from './SigningRouter';

const WindowHistory = () => {
  const navigate = useNavigate();
  window.currentNavigate = navigate;
  return null;
};

const CommonLibraryContext = ({ children }) => {
  const isLibrary = !![
    routePaths.commonLibraryConfiguration,
    routePaths.commonLibraryDocumentTemplateConfiguration,
    routePaths.commonLibraryDocumentTemplateList,
    routePaths.commonLibraryEmailTemplates,
    routePaths.commonLibraryItemConfiguration,
  ]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    .map((path) => useMatch(path))
    .filter(Boolean).length;
  return <LibraryProvider value={{ isLibrary }}>{children}</LibraryProvider>;
};

const ChildRouter = ({ user }) => {
  const match = useMatch('/view/:id');
  if (match) return <CustomFrontendRouter match={match} />;
  return user ? <AppRouter /> : <AuthRouter />;
};

const RootRouter = ({ children }) => {
  const [user, loading] = useCurrentUser(isGlobalPage());
  const [themeLoading] = useLoadTheme();
  const isLoading = loading || themeLoading;
  window.history.replaceState({}, document.title);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <CommonLibraryContext>
        <WindowHistory />
        {isLoading ? <LoadingOverlay /> : children}
        <Routes>
          <Route path={routePaths.outOfService} element={<OutOfServicePage />} />
          <Route path={routePaths.signingOutOfService} element={<SigningOutOfService />} />
          {!isLoading ? (
            <>
              <Route path="verify/*" element={<VerifyRouter />} />
              {signingRoutesRouterComponents}
              <Route path="*" element={<ChildRouter user={user} />} />
            </>
          ) : null}
        </Routes>
        <ConfirmAlertContainer />
      </CommonLibraryContext>
    </Router>
  );
};

export default RootRouter;
