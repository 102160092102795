import { gql } from '@apollo/client';

const currentUserFragment = gql`
  fragment currentUserFragment on User {
    _id
    role
    email
    profile {
      _id
      firstName
      lastName
      salutation
      position
      phone
      fullSalutation
    }
    state
    tenantId
    locale
    isAlpha
    isDev
    tenantInformation {
      name
      theme {
        primaryColor
        textPrimaryColor
      }
    }
  }
`;

export default currentUserFragment;
