import { useFormikContext } from 'formik';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { useRef, useEffect, useCallback, memo, useMemo } from 'react';
import {
  ConfigurePricingScalesCheckbox,
  CalculationModeFormikInput,
  MinimalPriceFormikInput,
  PaymentIntervalFormikInput,
  PricingFormulaFormikInput,
  PricingFormulaHelperWidget,
  RoundPriceIdFormikInput,
  HighlightBindingnessFormikInput,
} from 'components/admin/itemModal/components';
import { useAdminInputFields } from 'graphql/hooks';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { useTranslation } from 'react-i18next';
import equal from 'fast-deep-equal/es6/react';
import { Alert, Col, Row } from 'antd';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { CalculationMode } from 'constants/item';
import classes from './PriceTab.module.less';
import Scales from './Scales';

const PricingWarningAlert = memo(({ minPrice, pricingFormula }) => {
  const { t } = useTranslation();
  const isShowAlert = useMemo(() => {
    if (Mentions.richTextToFormula(pricingFormula)[0] === '-' && Number.isFinite(minPrice)) return true;
    return false;
  }, [minPrice, pricingFormula]);

  if (isShowAlert)
    return (
      <Alert
        message={isShowAlert ? <div>{t('admin.itemModal.pricingWarning', { value: minPrice })}</div> : null}
        type="warning"
        closable
      />
    );
  return null;
}, equal);

const ValidateFormulaOnMountContainer = (props) => {
  const ref = useRef();
  const setIsDone = useCallback(() => {
    ref.current = true;
  }, []);
  if (ref.current) return null;
  return <ValidateFormulaOnMount {...props} setIsDone={setIsDone} />;
};
const ValidateFormulaOnMount = ({ categoryId, parentId, doNotValidateOnMount, setIsDone }) => {
  const useValidateFormOncePropIsTrue = (prop) => {
    const form = useFormikContext();
    const isValidatedRef = useRef();
    useEffect(() => {
      if (prop && !isValidatedRef.current) {
        isValidatedRef.current = true;
        setIsDone();
        form.validateForm();
      }
    }, [form, prop]);
  };

  const [inputFields] = useAdminInputFields({ categoryId, parentId, fetchPolicy: 'cache-only' });
  useValidateFormOncePropIsTrue(!doNotValidateOnMount && inputFields?.length);
  return null;
};

const PricingWarningAlertMemo = () => {
  const {
    values: { pricingFormula, minPrice },
  } = useFormikContext();
  return <PricingWarningAlert minPrice={minPrice} pricingFormula={pricingFormula} />;
};

const PriceHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.itemModal.PriceHelperWidget.howUseButton')}
      title={t('admin.itemModal.PriceHelperWidget.modalInfo.title')}
      helpText={t('admin.itemModal.PriceHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.itemModal.PriceHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.itemModal.PriceHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.itemModal.PriceHelperWidget.modalInfo.imageUrl')}
    />
  );
};

const PriceHelperWidgetMemo = memo(PriceHelperWidget, equal);

const PricingFormulaSection = ({ categoryId, _id }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="margin-bottom-0-item-form">
        <PricingFormulaFormikInput
          _id={_id}
          categoryId={categoryId}
          tooltip={t('admin.itemModal.inputs.pricingFormula.tooltip')}
        />
      </div>
      <PricingFormulaHelperWidget buttonClassName={classes.infoButton} />
      <ValidateFormulaOnMountContainer categoryId={categoryId} parentId={_id} />
    </>
  );
};

const PricingFormulaSectionMemo = memo(PricingFormulaSection, equal);

const CalculationModeSection = ({ categoryId, _id }) => {
  const { t } = useTranslation();
  return (
    <>
      <PricingFormulaSectionMemo categoryId={categoryId} _id={_id} />
      <Row gutter={16}>
        <Col xs={24} md={12} lg={8}>
          <MinimalPriceFormikInput tooltip={t('admin.itemModal.inputs.minPrice.tooltip')} />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <HighlightBindingnessFormikInput />
        </Col>
        <Col xs={24} md={24} lg={8}>
          <RoundPriceIdFormikInput
            tooltip={t('admin.itemModal.inputs.roundPrice.tooltip')}
            label={t('admin.itemModal.inputs.roundPrice.label')}
          />
        </Col>
      </Row>
      <ConfigurePricingScalesCheckbox />
      <Scales itemId={_id} />
    </>
  );
};

const CalculationModeSectionMemo = memo(CalculationModeSection, equal);

const CalculationModeSectionOnActualCost = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col xs={24} md={12} lg={8}>
        <MinimalPriceFormikInput tooltip={t('admin.itemModal.inputs.minPrice.tooltipOnActualCost')} />
      </Col>
    </Row>
  );
};

const PriceTabForm = ({ categoryId, _id }) => {
  const { t } = useTranslation();
  const {
    values: { calculationMode },
  } = useFormikContext();
  return (
    <>
      <PriceHelperWidgetMemo />
      <PricingWarningAlertMemo />
      <AlertFromFormik />
      <PaymentIntervalFormikInput tooltip={t('admin.itemModal.inputs.paymentInterval.tooltip')} />
      <CalculationModeFormikInput _id={_id} categoryId={categoryId} />
      {calculationMode !== CalculationMode.ON_ACTUAL_COST ? (
        <CalculationModeSectionMemo categoryId={categoryId} _id={_id} />
      ) : (
        <CalculationModeSectionOnActualCost />
      )}
    </>
  );
};

export default memo(PriceTabForm, equal);
