import i18n from 'i18n';

/**
 * Builds an object with placeholder keys as keys and the localized placeholder labels as values for rich text to plain text conversation for search and sort
 * @returns {object} Placeholder label object
 */
export const buildPlaceholderLabelObject = () => {
  const placeholderTranslations = i18n.t('admin.Placeholders', { returnObjects: true });
  Object.keys(placeholderTranslations).forEach((key) => {
    placeholderTranslations[key] = placeholderTranslations[key].label;
  });
  return placeholderTranslations;
};
