import DragButton from 'components/common/DragAndDrop/DragButton';
import classes from './DragHandle.module.less';

/**
 * Handle for dragging list items
 * @returns {JSX.Element} drag handle
 * @component
 */
const DragHandle = () => {
  return (
    <div className={classes.DragHandleWrapper}>
      <DragButton size={26} color="#BFBFBF" />
    </div>
  );
};

export default DragHandle;
