import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import vhCheck from 'vh-check';
import NotificationHandler from 'components/NotificationHandler';
import ServiceIsRestrictedWarningHandler from 'components/ServiceIsRestrictedWarningHandler';
import { ANTD_CLASS_PREFIX, changeToDefaultTheme } from 'utils/changeTheme';
import { antdTranslations, useUtilityLanguage } from 'utils/languageHandling';
import { SidebarContextProvider } from './contexts/SidebarContext';
import startup from './startup';
import RootRouter from './router/RootRouter';
import apolloClient from './graphql/apollo';
import 'normalize.css';
// import 'antd/dist/antd.variable.min.css';
import './styles/index.less';
import './i18n';
import './testing';
import './componentPatch';

import('./i18n/usedKeysReporter'); // async import
startup();

const App = () => {
  vhCheck({ redefineVh: true }); // set var(--vh, 1vh) for real 1vh

  const { i18n } = useTranslation();

  useUtilityLanguage(i18n.language);

  useEffect(() => {
    changeToDefaultTheme();
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={antdTranslations[i18n.language]} prefixCls={ANTD_CLASS_PREFIX}>
        <ServiceIsRestrictedWarningHandler />
        <div id="content-holder">
          <SidebarContextProvider>
            <DndProvider
              backend={isMobile ? TouchBackend : HTML5Backend}
              options={isMobile ? { enableMouseEvents: true } : {}}
            >
              <RootRouter />
              <NotificationHandler />
            </DndProvider>
          </SidebarContextProvider>
        </div>
      </ConfigProvider>
    </ApolloProvider>
  );
};

// KJ-998
const updateViewportOffsetOnKeyboardOpen = throttle(() => {
  const newOffset = `${window.innerHeight - visualViewport.height}px`;
  const oldOffset = document.body.style.getPropertyValue('--viewport-offset');
  if (oldOffset !== newOffset) document.body.style.setProperty('--viewport-offset', newOffset);
}, 100);
visualViewport.addEventListener('resize', updateViewportOffsetOnKeyboardOpen);
updateViewportOffsetOnKeyboardOpen();

export default App;
