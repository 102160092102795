import { gql } from '@apollo/client';

const adminEmailTemplateFragment = gql`
  fragment adminEmailTemplateFragment on EmailTemplate {
    _id
    name
    subject
    body
    description
    isDefault
    shouldSendAttachments
    createdAt
  }
`;

export default adminEmailTemplateFragment;
