import { Checkbox as AntDCheckbox, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import SortBarEntry, { SortBarButton } from './SortBarEntry';
import classes from './SortBar.module.less';

/**
 * Checkbox component for select all functionality in the sort bar
 * @param {object} inputProperties - Input properties of the component
 * @param {() => void} inputProperties.onSelectAll - Function to call when the checkbox is clicked
 * @param {boolean} inputProperties.selectAllActive - Whether the checkbox is active
 * @returns {JSX.Element} Checkbox component
 * @component
 */
const Checkbox = ({ onSelectAll, selectAllActive }) => {
  return (
    <div className={classes.CheckboxWrapper}>
      <AntDCheckbox checked={selectAllActive} onChange={onSelectAll} />
    </div>
  );
};

/**
 * Sort bar component for changing the sort order of a list
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.currentSortField - Current sort field
 * @param {string} inputProperties.currentSortDirection - Current sort direction
 * @param {object[]} inputProperties.fields - Fields to sort by
 * @param {string} inputProperties.fields.fieldName - Field name to sort by
 * @param {string} inputProperties.fields.label - Label for the field
 * @param {(itemA: object, itemB: object) => number} inputProperties.fields.compareFunc - Optional: Function to compare the field
 * @param {(fieldName: string, direction: string) => void} inputProperties.onChange - Function to call when the sort order changes
 * @param {boolean} inputProperties.selectable - Whether the list is in selectable mode
 * @param {() => void} inputProperties.onSelectAll - Function to call when the select all checkbox is clicked
 * @param {boolean} inputProperties.selectAllActive - Whether the select all checkbox is active
 * @param {boolean} inputProperties.draggable - Whether the list is in draggable mode
 * @param {() => void} inputProperties.resetSortAndFilter - Function to call when the sort and filter should be reset for get drag and drop conditions
 * @param {boolean} inputProperties.dragEnabled - Whether the drag and drop functionality is enabled
 * @returns {JSX.Element} Sort bar component
 * @component
 */
const SortBar = ({
  currentSortField,
  currentSortDirection,
  fields,
  onChange,
  selectable,
  onSelectAll,
  selectAllActive,
  draggable,
  resetSortAndFilter,
  dragEnabled,
}) => {
  const { t } = useTranslation();
  const sortBarEntries = fields.map((field) => {
    return (
      <SortBarEntry
        key={field.fieldName}
        label={field.label}
        fieldName={field.fieldName}
        onChange={onChange}
        activeDirection={currentSortField === field.fieldName ? currentSortDirection : null}
      />
    );
  });

  return (
    <Row className={classes.SortBar}>
      {selectable && <Checkbox onSelectAll={onSelectAll} selectAllActive={selectAllActive} />}
      {draggable && (
        <SortBarButton label={t('List.orderButton')} onClick={resetSortAndFilter} orderChangeActive={dragEnabled} />
      )}
      {sortBarEntries}
    </Row>
  );
};

export default SortBar;
