import { Collapse } from 'antd';
import { RichEditorField } from 'components/common/RichEditor';
import showInfoModal from 'utils/showInfoModal';
import RichTextViewer from 'components/common/RichEditor/RichTextViewer';
import { userPreferencesQuery } from 'graphql/queries';
import apollo from 'graphql/apollo';
import classes from './ItemCard.module.less';
import { createPlaceHolderObject } from '../utils';

const { Panel } = Collapse;

/**
 * Displays an information text modal with various content sections also rendering rich text.
 * @param {Object} params - The params object
 * @param {string} params.itemTexts - The texts object for the item.
 * @param {string} params.itemTexts.benefits - The benefits of the item.
 * @param {string} params.itemTexts.descriptionForContract - The description for the contract.
 * @param {string} params.itemTexts.infoText - The information text.
 * @param {string} params.itemTexts.notesForFeeAgreement - The notes for the fee agreement.
 * @param {string} params.itemTexts.notesToEmployee - The notes to the employee.
 * @param {string} params.itemTexts.pleaseNote - The please note text.
 * @param {string} params.modalTitle - The title of the modal.
 * @param {Object} params.values - The form values.
 * @param {Object} params.initializationConfigDate - The initialization config date.
 * @param {function} params.t - The translation function.
 * @returns {Promise<void>} - A promise that resolves when the modal is displayed.
 */
export const showInfoTextsModal = async ({ itemTexts, modalTitle, values, initializationConfigDate, t }) => {
  const placeholderObject = await createPlaceHolderObject({
    values,
    userPreferences: await apollo.query({ query: userPreferencesQuery }),
    initializationConfigDate,
  });

  showInfoModal({
    title: modalTitle,
    maskClosable: true,
    content: ModalContent({ itemTexts, placeholderObject, t }),
    width: 800,
  });
};

/**
 * The content of the modal.
 * @param {Object} params - The params object
 * @param {string} params.itemTexts - The texts object for the item.
 * @param {string} params.itemTexts.benefits - The benefits of the item.
 * @param {string} params.itemTexts.descriptionForContract - The description for the contract.
 * @param {string} params.itemTexts.infoText - The information text.
 * @param {string} params.itemTexts.notesForFeeAgreement - The notes for the fee agreement.
 * @param {string} params.itemTexts.notesToEmployee - The notes to the employee.
 * @param {string} params.itemTexts.pleaseNote - The please note text.
 * @param {string} params.placeholderObject - The placeholder object for the rich text.
 * @param {function} params.t - The translation function.
 * @returns {JSX.Element} - The modal content.
 * @component
 */
const ModalContent = ({ itemTexts, placeholderObject, t }) => {
  const { benefits, descriptionForContract, infoText, notesForFeeAgreement, notesToEmployee, pleaseNote } = itemTexts;

  return (
    <div>
      {infoText ? (
        <RichEditorField readOnly className="rich-editor-read-only margin-bottom-16" initialValue={infoText} />
      ) : null}
      {benefits ? (
        <>
          <div>
            <b>{t('user.ShoppingCart.Category.Item.infoTextModal.benefits')}</b>
          </div>
          <RichTextViewer richText={benefits} placeholderObject={placeholderObject} />
        </>
      ) : null}
      {pleaseNote || descriptionForContract || notesForFeeAgreement || notesToEmployee ? (
        <Collapse ghost className={classes.infoTextModalCollapse}>
          <Panel header={t('user.ShoppingCart.Category.Item.infoTextModal.collapsePanelTitle')}>
            {descriptionForContract ? (
              <>
                <div>
                  <b>{t('user.ShoppingCart.Category.Item.infoTextModal.descriptionForContract')}</b>
                </div>
                <RichTextViewer richText={descriptionForContract} placeholderObject={placeholderObject} />
              </>
            ) : null}
            {notesForFeeAgreement ? (
              <>
                <div>
                  <b>{t('user.ShoppingCart.Category.Item.infoTextModal.notesForFeeAgreement')}</b>
                </div>
                <RichTextViewer richText={notesForFeeAgreement} placeholderObject={placeholderObject} />
              </>
            ) : null}
            {pleaseNote ? (
              <>
                <div>
                  <b>{t('user.ShoppingCart.Category.Item.infoTextModal.pleaseNote')}</b>
                </div>
                <RichTextViewer richText={pleaseNote} placeholderObject={placeholderObject} />
              </>
            ) : null}
            {notesToEmployee ? (
              <>
                <div>
                  <b>{t('user.ShoppingCart.Category.Item.infoTextModal.notesToEmployee')}</b>
                </div>
                <RichTextViewer richText={notesToEmployee} placeholderObject={placeholderObject} />
              </>
            ) : null}
          </Panel>
        </Collapse>
      ) : null}
    </div>
  );
};
