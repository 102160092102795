import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { createVariable } from 'graphql/methods';
import toast from 'utils/toast';
import variableTypes from 'constants/variableTypes';
import { Variable } from '@JavaScriptSuperstars/kanzleipilot-shared';
import VariableList from './VariableList';
import { getVariableFields, variableValidationSchema, errorResolver } from './utils';
import ConstantsAndPlaceholdersHelperWidget from './ConstantsAndPlaceholdersHelperWidget';

const addNewVariable = () =>
  inputConfirmModal({
    fields: getVariableFields(),
    onSubmit: (modifier) => {
      let { value } = modifier;
      if (modifier.type === 'percent') {
        value = Variable.formatPercentValue(modifier.value, true);
      }
      return createVariable({
        ...modifier,
        value: value.toString(),
      }).then(toast.successDefault);
    },
    value: {
      label: '',
      value: '',
      type: variableTypes.float,
      description: '',
    },
    errorResolver,
    headerText: i18n.t('admin.ConstantsAndPlaceholders.addVariableModalTitle'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: variableValidationSchema,
    forceMultiField: true,
    width: '600px',
  });

const ActionButtons = [
  {
    label: i18n.t('admin.ConstantsAndPlaceholders.addVariableButton'),
    icon: <PlusOutlined />,
    type: 'primary',
    onClick: addNewVariable,
  },
];

function CompanySettingWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer
      title={t('admin.ConstantsAndPlaceholders.title')}
      actionButtons={ActionButtons}
      left={<ConstantsAndPlaceholdersHelperWidget />}
    >
      <VariableList />
    </PageContainer>
  );
}

export default memo(CompanySettingWrapper, equal);
