import jwtDecode from 'jwt-decode';
import { useEffect, useCallback, useState } from 'react';
import { isGlobalPage } from 'utils/isGlobalPage';
import { changeTheme, changeToDefaultTheme } from 'utils/changeTheme';
import useCurrentUser from '../auth/useCurrentUser';

export const SIGNING_CSS_PATH_KEY = 'signingCssPath';

const checkLocationPathname = () => {
  return /(\/signing)\/?([^/]*)/.exec(window?.location?.pathname);
};

const useLoadTheme = () => {
  const [user, loading] = useCurrentUser(isGlobalPage());
  const [themeLoading, setThemeLoading] = useState(true);

  const loadTheme = useCallback(async () => {
    const matchSigning = checkLocationPathname();
    if (matchSigning) {
      try {
        const decodedToken = jwtDecode(matchSigning[2]);

        if (!decodedToken || !decodedToken.theme) return changeToDefaultTheme();

        const { background: primaryColor, color } = decodedToken.theme;

        if (!primaryColor || !color) return changeToDefaultTheme();

        return changeTheme(primaryColor, color);
      } catch {
        return changeToDefaultTheme();
      }
    } else if (user?.tenantInformation?.theme) {
      const { primaryColor, textPrimaryColor } = user.tenantInformation.theme;
      return changeTheme(primaryColor, textPrimaryColor);
    } else {
      return changeToDefaultTheme();
    }
  }, [user]);
  const userId = user?._id;
  useEffect(() => {
    if (loading) return;
    setThemeLoading(false);
    loadTheme().then(() => setThemeLoading(false));
  }, [loadTheme, loading, userId]);
  return [themeLoading];
};

export default useLoadTheme;
