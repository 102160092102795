import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import concatClassNames from 'utils/classNames';
import classes from './SortBarEntry.module.less';

/**
 * Sort direction constants
 */
export const SortDirection = {
  DESC: 'DESC',
  ASC: 'ASC',
};

/**
 * A sort field entry of the sort bar for the list layout to change sort order of a list
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label for the field
 * @param {string} inputProperties.fieldName - Field name to sort by
 * @param {(fieldName: string, direction: string) => void} inputProperties.onChange - Function to call when the sort order changes
 * @param {SortDirection | null} inputProperties.activeDirection - Active sort direction
 * @returns {JSX.Element} Sort bar entry component
 * @component
 */
const SortBarEntry = ({ label, fieldName, onChange, activeDirection }) => {
  const onSortChange = () => {
    if (activeDirection === SortDirection.ASC) {
      onChange(fieldName, SortDirection.DESC);
    } else {
      onChange(fieldName, SortDirection.ASC);
    }
  };

  return (
    <button className={classes.SortBarEntry} onClick={onSortChange} type="button">
      <div className={classes.Label}>{label}</div>
      <div className={classes.ButtonWrapper}>
        <div className={classes.Button}>
          <CaretUpOutlined
            className={concatClassNames(
              classes.UpButton,
              activeDirection === SortDirection.ASC ? classes.ActiveSort : null,
            )}
          />
        </div>
        <div className={classes.Button}>
          <CaretDownOutlined
            className={concatClassNames(
              classes.DownButton,
              activeDirection === SortDirection.DESC ? classes.ActiveSort : null,
            )}
          />
        </div>
      </div>
    </button>
  );
};

/**
 * Sort bar entry button component for the sort bar for enable sort in one direction for drag and drop condtions in list layout
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label for the field
 * @param {() => void} inputProperties.onClick - Function to call when the button is clicked
 * @param {boolean} inputProperties.orderChangeActive - Whether the button is active
 * @returns {JSX.Element} Sort bar entry component with only one direction
 * @component
 */
export const SortBarButton = ({ label, onClick, orderChangeActive }) => {
  return (
    <button className={classes.SortBarEntry} onClick={onClick} type="button">
      <div className={classes.Label}>{label}</div>
      <div className={classes.ButtonWrapperForSortButton}>
        <div className={classes.Button}>
          <CaretDownOutlined
            className={concatClassNames(classes.DownButton, orderChangeActive ? classes.ActiveSort : null)}
          />
        </div>
      </div>
    </button>
  );
};

export default SortBarEntry;
