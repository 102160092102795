import { Modal } from 'antd';

/**
 * A modal component with an option to disable animation.
 * @component
 *
 * @param {boolean} disableAnimation - Flag to disable animation (default: false)
 * @param {object} props - Other props to be passed to the Modal component
 * @returns {JSX.Element} - The rendered ModalWithSupressableAnimation component
 */
export const ModalWithSupressableAnimation = ({ disableAnimation = false, ...props }) => (
  <Modal
    transitionName={disableAnimation ? '' : undefined}
    maskTransitionName={disableAnimation ? '' : undefined}
    {...props}
  />
);
