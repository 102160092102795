import { Checkbox as AntDCheckbox } from 'antd';
import classes from './Checkbox.module.less';

/**
 * Checkbox for select functionality in list items
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.selected - Is the checkbox selected
 * @param {(selected: boolean) => void} inputProperties.onSelect - Function to call when the checkbox gets changed
 * @returns {JSX.Element} checkbox for list item
 * @component
 */
const Checkbox = ({ selected, onSelect }) => {
  return (
    <div className={classes.CheckboxWrapper}>
      <AntDCheckbox checked={selected} onChange={() => onSelect(!selected)} />
    </div>
  );
};

export default Checkbox;
