import i18n from 'i18n';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { updateEmailTemplate } from 'graphql/methods';
import inputConfirmModal from 'utils/inputConfirmModal';
import EmailTemplateForm from './EmailTemplateForm';
import { EmailTemplateValidationSchema } from './schema';

/**
 * Opens the edit email template modal to update an existing email template
 * @param {object} inputProperties - The input properties for the modal
 * @param {object} inputProperties.emailTemplate - The email template to edit
 * @param {string} inputProperties.emailTemplate._id - The id of the email template
 * @param {string} inputProperties.emailTemplate.name - The name of the email template
 * @param {string} inputProperties.emailTemplate.subject - The subject of the email template (richtext)
 * @param {string} inputProperties.emailTemplate.body - The body of the email template (richtext)
 * @param {boolean} inputProperties.emailTemplate.isDefault - A boolean value indicating whether the email template is the default email template
 * @param {boolean} inputProperties.emailTemplate.shouldSendAttachments - A boolean value indicating whether the email template should send attachments
 * @param {string} inputProperties.emailTemplate.description - The description of the email template
 * @param {string} inputProperties.emailTemplate.description - The description of the email template
 * @param {boolean} inputProperties.isLibrary - A boolean value indicating whether the email template is a common library email template
 * @returns {void}
 */
const openEditEmailTemplate = ({ emailTemplate, isLibrary }) =>
  inputConfirmModal({
    formContent: () => <EmailTemplateForm />,
    fields: [],
    onSubmit: ({ name, subject, body, isDefault, shouldSendAttachments, description }) => {
      updateEmailTemplate({
        _id: emailTemplate._id,
        name,
        subject,
        body,
        isLibrary,
        isDefault,
        shouldSendAttachments,
        description,
      });
    },
    value: {
      name: emailTemplate.name ?? '',
      subject: emailTemplate.subject ?? RichText.getDefaultRichEditorValue(),
      body: emailTemplate.body ?? RichText.getDefaultRichEditorValue(),
      description: emailTemplate.description ?? '',
      isDefault: emailTemplate.isDefault ?? false,
      shouldSendAttachments: emailTemplate.shouldSendAttachments ?? true,
    },
    headerText: i18n.t('admin.EmailTemplates.modal.editTitle'),
    errorResolver: { Duplicated: ['name', i18n.t('admin.EmailTemplates.modal.error.duplicatedErrorMessage')] },
    okText: i18n.t('common.ok'),
    validationSchema: EmailTemplateValidationSchema,
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
  });

export default openEditEmailTemplate;
