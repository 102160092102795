import { findIndex } from 'lodash';
import { useCallback } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { clientShift } from 'utils/helpers';
import { DragButton } from './DragButton';

export const DragHandle = sortableHandle(() => <DragButton style={{ cursor: 'grab', color: '#999' }} />);

export const SortableItem = sortableElement((props) => <tr {...props} />);
export const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const useSortEnd = ({
  dataSource,
  moveInCache,
  moveMutation,
  refetchQuery,
  moveMutationProps,
  refetchQueryVariables,
}) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const dataId = dataSource[oldIndex]?._id;
        const newItems = clientShift({
          array: dataSource,
          _id: dataId,
          shift: newIndex - oldIndex,
        });
        typeof moveInCache === 'function' && moveInCache(newItems);

        if (typeof moveMutation === 'function') {
          const order = dataSource[newIndex]?.order;
          window.setTimeout(
            moveMutation({ _id: dataId, order, ...moveMutationProps }, refetchQuery, refetchQueryVariables),
          );
        }
      }
    },
    [dataSource, moveInCache, moveMutation, moveMutationProps, refetchQuery, refetchQueryVariables],
  );
  return {
    onSortEnd,
  };
};

export const useDragTableComponents = ({ dataSource, onSortEnd }) => {
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(dataSource, (x) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  const DraggableContainer = (props) => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  return {
    DraggableContainer,
    DraggableBodyRow,
  };
};
