import { useTranslation } from 'react-i18next';
import Card from '../../Card';
import classes from './SearchCard.module.less';
import FilterSwitch from './FilterSwitch';
import SearchField from './SearchField';

/**
 * Search card for list layout with search input and filter switches
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.filters - Filters to display
 * @param {string} inputProperties.filters.fieldName - Field name to filter by
 * @param {string} inputProperties.filters.label - Label for the field
 * @param {object[]} inputProperties.filters.options - Options for the filter
 * @param {string} inputProperties.filters.options.value - Value for the filter
 * @param {string} inputProperties.filters.options.label - Label for the filter
 * @param {string} inputProperties.filters.value - Current filter value
 * @param {(item: object, value: string) => boolean} inputProperties.filters.applyFilter - Optional: Function to apply the filter
 * @param {string} inputProperties.searchPlaceholder - Placeholder for the search input
 * @param {(fieldName: string, value: string) => void} inputProperties.onFilterChange - Function to call when a filter changes
 * @param {(newQuery: string) => void} inputProperties.onQueryChange - Function to call when the search query changes
 * @param {string} inputProperties.currentQuery - Current search query
 * @returns {JSX.Element} Search card component
 * @component
 */
const SearchCard = ({ filters = [], searchPlaceholder, onFilterChange, onQueryChange, currentQuery }) => {
  const { t } = useTranslation();

  const filterSwitches = filters.map((filter) => (
    <FilterSwitch
      key={filter.fieldName}
      label={filter.label}
      options={filter.options}
      value={filter.value}
      onChange={(e) => onFilterChange(filter.fieldName, e.target.value)}
    />
  ));

  return (
    <Card title={t('List.searchAndFilterCard.title')} className={classes.SearchCard}>
      <SearchField placeholder={searchPlaceholder} currentQuery={currentQuery} onQueryChange={onQueryChange} />
      <div className={classes.Filters}>{filterSwitches}</div>
    </Card>
  );
};

export default SearchCard;
