import { Alert, Dropdown, Menu, Tooltip } from 'antd';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FaStop } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { userShoppingCartViewQuery, userVariableListQuery, userViewShoppingCartCategoriesQuery } from 'graphql/queries';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared/lib/digitalSignature';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { fullNameFromUser } from 'components/layout/CurrentUserDropdown';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { formatDate, formatDateTime } from 'utils/date';
import { totalValueToString } from 'utils/formatValues';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { VariablesContext, DigitalSignatureEmailProvider } from 'components/user/shoppingCart/context';
import { Button } from 'components/common/Button';
import { CompareToGlobalProvider } from 'contexts/CompareToGlobalContext';
import LockButton from 'components/user/compareToGlobal/LockButton';
import MigratedShoppingCartHelperWidget from 'components/user/compareToGlobal/MigratedShoppingCartHelperWidget';
import { useComparingVariables } from 'graphql/hooks';
import RevokeDigitalSignatureProcessModal from 'components/user/RevokeDigitalSignatureProcessModal/RevokeDigitalSignatureProcessModal';
import RemindAllSigneesModal from 'components/user/RemindAllSigneesModal/RemindAllSigneesModal';
import StartDigitalSignatureProcessButton from 'components/user/shoppingCart/StartDigitalSignatureProcessButton';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import EditShoppingCartButton from 'components/user/shoppingCart/EditShoppingCartButton';
import useFasterPreferences from 'hooks/user/useFasterPreferences';
import { MONTHLY_PAYMENT_MODE } from 'constants/monthlyPayment';
import DeleteTypeSelectionModal from 'components/admin/ProjectCard/DeleteTypeSelectionModal';
import PageContainerActionButton from 'components/common/PageContainerActionButton';
import { CustomizedDocumentsCard } from './CustomizedDocumentsCard';
import HistoricRevisions from './HistoricRevisions';
import ShoppingCartOverview from './ShoppingCartOverview';
import { getCategoryItems } from './utils';
import ShoppingCartHistoryItems from './ShoppingCartHistoryItems';
import ShoppingCartViewHelperWidget from './ShoppingCartViewHelperWidget';
import SendEmail from './SendEmail';
import Categories from './Categories';
import GeneralShoppingCartDataCard from './GeneralShoppingCartDataCard';
import DigitalSignatureCard from './DigitalSignatureCard';
import SignCartButton from './SignCartButton';
import classes from './ShoppingCartView.module.less';

/**
 * ShoppingCartView component shows the content of a specified shopping cart in cards
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.shoppingCart - Shopping cart object to show in the page
 * @param {Object[]} inputParameters.categories - Collection of categories of the tenant
 * @returns {JSX.Element} page content for the view shopping cart page with a list of cards to show informations about existing shopping cart
 * @component
 */
const ShoppingCartView = ({ shoppingCart, categories }) => {
  const { t } = useTranslation();
  const { preferences } = useFasterPreferences({ fetchPolicy: 'cache-and-network', shoppingCartId: shoppingCart?._id });
  const items = useMemo(() => getCategoryItems({ shoppingCart, categories }), [categories, shoppingCart]);

  const monthlyPaymentStatusRow = useMemo(
    () =>
      preferences?.monthlyPaymentPreferences?.monthlyPaymentMode === MONTHLY_PAYMENT_MODE.DISABLED ||
      shoppingCart.feeType === 'fixedMonthly' ||
      shoppingCart?.monthlyAdvance?.value === 0
        ? null
        : {
            monthlyPaymentStatus: t(
              `viewer.ShoppingCartView.Overview.fields.monthlyPaymentDecision.${shoppingCart.monthlyPaymentDecision}`,
            ),
          },
    [
      shoppingCart.monthlyPaymentDecision,
      shoppingCart.feeType,
      preferences?.monthlyPaymentPreferences?.monthlyPaymentMode,
      shoppingCart?.monthlyAdvance?.value,
      t,
    ],
  );

  const monthlyAdvancesRow = useMemo(
    () =>
      preferences?.monthlyPaymentPreferences?.monthlyPaymentMode === MONTHLY_PAYMENT_MODE.DISABLED ||
      shoppingCart.feeType === 'fixedMonthly' ||
      shoppingCart?.monthlyAdvance?.value === 0
        ? null
        : {
            monthlyAdvance: totalValueToString({
              paymentIntervalValues: shoppingCart.monthlyAdvance,
              showDigits: shoppingCart.showDigits,
            }),
          },
    [
      shoppingCart.showDigits,
      shoppingCart?.monthlyAdvance,
      shoppingCart.feeType,
      preferences?.monthlyPaymentPreferences?.monthlyPaymentMode,
    ],
  );

  const monthlyFee = useMemo(
    () =>
      shoppingCart.feeType === 'fixedMonthly'
        ? {
            monthlyFixedFee: totalValueToString({
              paymentIntervalValues: Calc.formatFixedValue({
                yearly: shoppingCart.yearly,
                monthly: shoppingCart.monthly,
              }),
              t,
              showDigits: shoppingCart.showDigits,
            }),
          }
        : null,
    [shoppingCart.yearly, shoppingCart.monthly, shoppingCart.showDigits, shoppingCart.feeType, t],
  );

  const overviewData = useMemo(
    () => ({
      companyIdentifier: shoppingCart.company?.identifier,
      company: shoppingCart.company,
      contacts: shoppingCart.contacts,
      valueOneOff: totalValueToString({
        paymentIntervalValues: shoppingCart.oneOff,
        showDigits: shoppingCart.showDigits,
      }),
      valueMonthly: totalValueToString({
        paymentIntervalValues: shoppingCart.monthly,
        showDigits: shoppingCart.showDigits,
      }),
      valueYearly: totalValueToString({
        paymentIntervalValues: shoppingCart.yearly,
        showDigits: shoppingCart.showDigits,
      }),
      ...monthlyPaymentStatusRow,
      ...monthlyAdvancesRow,
      ...monthlyFee,
      monthlyFeeMode: t(`viewer.ShoppingCartView.feeMode.${shoppingCart.feeType}`),
      status: shoppingCart.status ? shoppingCart.status.name : t(`ShoppingCartEntriesPage.noStatus`),
      createdAt: formatDateTime(shoppingCart.createdAt),
      createdBy: fullNameFromUser(shoppingCart.createdBy),
      meetingAt: shoppingCart.meetingAt ? formatDate(shoppingCart.meetingAt) : null,
      startOfContract: shoppingCart.startOfContract ? formatDate(shoppingCart.startOfContract) : null,
    }),
    [
      shoppingCart.company,
      shoppingCart.contacts,
      shoppingCart.createdAt,
      shoppingCart.createdBy,
      shoppingCart.feeType,
      shoppingCart.meetingAt,
      shoppingCart.monthly,
      monthlyAdvancesRow,
      monthlyPaymentStatusRow,
      monthlyFee,
      shoppingCart.oneOff,
      shoppingCart.showDigits,
      shoppingCart.startOfContract,
      shoppingCart.status,
      shoppingCart.yearly,
      t,
    ],
  );
  return (
    <>
      {shoppingCart.migrated ? (
        <Alert
          type="info"
          className="alert-info"
          showIcon
          description={
            <>
              <div>
                {t('viewer.ShoppingCartView.migratedAlert', { migrationDate: formatDate(shoppingCart.migrationDate) })}
              </div>
              <MigratedShoppingCartHelperWidget />
            </>
          }
        />
      ) : null}
      <GeneralShoppingCartDataCard projectName={shoppingCart.name} t={t} />
      <DigitalSignatureCard shoppingCart={shoppingCart} preferences={preferences} />
      <ShoppingCartOverview
        overviewData={overviewData}
        monthlyPaymentTitle={preferences?.monthlyPaymentPreferences?.monthlyPaymentTitle}
      />
      <Categories shoppingCart={shoppingCart} categories={categories} />
      <CustomizedDocumentsCard
        shoppingCartId={shoppingCart._id}
        disabledAdding={false}
        digitalSignatureState={shoppingCart.digitalSignatureState}
      />
      <HistoricRevisions categories={categories} items={items} />
      <ShoppingCartHistoryItems />
    </>
  );
};

/**
 * Hamburger Menu with more actions for shopping cart
 * @param {Object} actionsConfiguration - Configuration for additional actions
 * @param {Object} actionsConfiguration.shoppingCart - Shopping cart object of the shopping cart, shown in the view
 * @param {Function} actionsConfiguration.onShowRevokeModal - Function, which is called, if an user clicks the revoke menu item
 * @param {Function} actionsConfiguration.onShowRemindAllModal - Function, which is called, if an user clicks the remind all menu item
 * @returns {JSX.Element} Component for Hamburger menu for more shopping cart actions
 * @component
 */
const ShoppingCartActionsMemo = memo(function ShoppingCartActions({
  shoppingCart,
  onShowRevokeModal,
  onShowRemindAllModal,
}) {
  const [me] = useCurrentUser();
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);
  const { t } = useTranslation();

  const isDigitalSignatureStarted = shoppingCart?.digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED;

  const menu = (
    <Menu>
      <Menu.Item key="revoke" onClick={onShowRevokeModal}>
        <span className="anticon">
          <FaStop />
        </span>
        {t('viewer.ShoppingCartView.menu.revoke')}
      </Menu.Item>
      <Menu.Item key="remindAll" onClick={onShowRemindAllModal}>
        <span className="anticon">
          <GrMail />
        </span>
        {t('viewer.ShoppingCartView.menu.remindAll')}
      </Menu.Item>
      <SendEmail key="sendEmail" shoppingCart={shoppingCart} menuItem />
    </Menu>
  );

  if (isViewer || !isDigitalSignatureStarted) return null;
  return (
    <Dropdown overlay={menu} className="margin-left-8" placement="bottomRight">
      <MoreOutlined className={cn('options', 'hover-background-light-primary')} />
    </Dropdown>
  );
},
equal);

/**
 * SendButton component represents a remind all signees button if ds state is started else it is a send email button
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.shoppingCart - Current shopping cart object
 * @param {Function} inputParameters.showRemindAllModal - Function, which get called, if remind all signees button is pressed
 * @returns {JSX.Element} button for send email in scr view
 * @component
 */
const SendButton = ({ shoppingCart, showRemindAllModal, ...props }) => {
  const { t } = useTranslation();
  if (!shoppingCart || shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.STARTED)
    return <SendEmail shoppingCart={shoppingCart} />;

  return (
    <PageContainerActionButton
      onClick={showRemindAllModal}
      icon={
        <span className="anticon anticon-edit">
          <GrMail size={19} />
        </span>
      }
      {...props}
    >
      {t('viewer.ShoppingCartView.sendReminderButton')}
    </PageContainerActionButton>
  );
};

/**
 * Component for the shopping cart view page
 * Needs access to react router, apollo client, i18n and a logged in user
 * @returns {JSX.Element} Component, which represents the ShoppingCartView page
 * @component
 */
const ShoppingCartViewWrapper = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [me] = useCurrentUser();
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [showRemindAllModal, setShowRemindAllModal] = useState(false);
  const isViewer = useMemo(() => me?.role === roles.VIEWER, [me]);

  const { data, loading, error } = useCachedQuery(userShoppingCartViewQuery, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    pollInterval: 1000 * 60 * 2,
  });

  const shoppingCart = useMemo(() => grabFirstGQLDataResult(data), [data]);

  const isDigitalSignatureStarted = shoppingCart?.digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED;

  const {
    data: variables,
    loading: variablesLoading,
    error: variablesError,
  } = useComparingVariables({ shoppingCartId: params.id, query: userVariableListQuery });

  const {
    data: categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useCachedQuery(userViewShoppingCartCategoriesQuery, {
    variables: {
      shoppingCartId: params.id,
      isLibrary: false,
      _id: params.id,
    },
    batchingAllowed: false,
    fetchPolicy: 'cache-and-network',
    context: { batchingAllowed: false },
  });

  const closeRevokeModal = () => setShowRevokeModal(false);
  const openRevokeModal = () => setShowRevokeModal(true);

  const closeRemindAllModal = () => setShowRemindAllModal(false);
  const openRemindAllModal = () => setShowRemindAllModal(true);

  const openDeleteConfirmModal = () => {
    setDeleteSelectionModalVisible(true);
  };
  const [deleteSelectionModalVisible, setDeleteSelectionModalVisible] = useState(false);
  const ActionButtons = [
    {
      component: <StartDigitalSignatureProcessButton shoppingCart={shoppingCart} />,
      hide: isViewer,
    },
    {
      component: <SignCartButton shoppingCart={shoppingCart} />,
      hide: isViewer,
    },
    {
      component: <SendButton shoppingCart={shoppingCart} showRemindAllModal={openRemindAllModal} />,
      hide: isViewer,
    },
    {
      component: <EditShoppingCartButton disabled={shoppingCart?.isStarting} _id={params.id} />,
      hide: isViewer,
    },
    {
      component: isDigitalSignatureStarted ? (
        <Tooltip key="deleteTooltip" title={t('viewer.ShoppingCartView.menu.delete.disabledOnDsTooltip')}>
          <Button
            icon={<DeleteOutlined />}
            type="default"
            disabled={isDigitalSignatureStarted}
            className={cn(classes.deleteButton)}
          />
        </Tooltip>
      ) : (
        <Button
          icon={<DeleteOutlined />}
          type="default"
          className={cn(classes.deleteButton)}
          onClick={openDeleteConfirmModal}
        />
      ),
      hide: isViewer,
    },
    {
      component: <SignCartButton shoppingCart={shoppingCart} />,
      hide: !isViewer,
    },
  ];

  return (
    <CompareToGlobalProvider>
      <DigitalSignatureEmailProvider>
        <DeleteTypeSelectionModal
          visible={deleteSelectionModalVisible}
          handleCancel={() => setDeleteSelectionModalVisible(false)}
          isDigitalSignatureStarted={isDigitalSignatureStarted}
          shoppingCartId={params.id}
        />
        <PageContainer
          title={t('viewer.ShoppingCartView.title', { companyName: shoppingCart?.company?.name || '' })}
          actionButtons={ActionButtons}
          addionalIconButtons={
            <>
              {!isDigitalSignatureStarted}
              <LockButton
                initializationConfigDate={shoppingCart?.initializationConfigDate}
                className={cn(classes.lockButton)}
              />
            </>
          }
          menu={
            <ShoppingCartActionsMemo
              shoppingCart={shoppingCart}
              onShowRevokeModal={openRevokeModal}
              onShowRemindAllModal={openRemindAllModal}
            />
          }
          left={<ShoppingCartViewHelperWidget buttonClassName={classes.helpButton} />}
        >
          <GraphQLLoadingWrapper
            data={variables && categories && shoppingCart}
            error={categoriesError || variablesError || error}
            loading={loading || categoriesLoading || variablesLoading}
          >
            {showRevokeModal && (
              <RevokeDigitalSignatureProcessModal shoppingCartId={params.id} onCloseModal={closeRevokeModal} />
            )}
            {showRemindAllModal ? (
              <RemindAllSigneesModal
                shoppingCartId={params.id}
                onCloseModal={closeRemindAllModal}
                shoppingCart={shoppingCart}
              />
            ) : null}
            <div className="xs-mt-20">
              <VariablesContext.Provider value={variables}>
                <ShoppingCartView shoppingCart={shoppingCart} categories={categories?.categories} />
              </VariablesContext.Provider>
            </div>
          </GraphQLLoadingWrapper>
        </PageContainer>
      </DigitalSignatureEmailProvider>
    </CompareToGlobalProvider>
  );
};

export default memo(ShoppingCartViewWrapper, equal);
