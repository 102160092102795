import inputConfirmModal from 'utils/inputConfirmModal';
import { Form } from 'formik-antd';
import { NameFormikInput } from 'components/admin/itemModal/components';
import i18n from 'i18n';
import * as Yup from 'yup';
import { textsSchema } from 'components/admin/itemModal/schema';
import { duplicateDocumentTemplate } from 'graphql/methods';

/**
 * Form for duplicating a document template with a new name input field
 * @returns {JSX.Element} Form component for duplicating a document template
 */
const DuplicateDocumentTemplateForm = () => {
  return (
    <div>
      <Form layout="vertical">
        <NameFormikInput />
      </Form>
    </div>
  );
};

const FIELDS = [{ component: DuplicateDocumentTemplateForm, label: '' }];

/**
 * Opens the modal for duplicating a document template
 * @param {Object} inputParameters - Input parameters of the function
 * @returns {void}
 */
const openDuplicateDocumentTemplateModal = ({ _id, isLibrary }) =>
  inputConfirmModal({
    fields: FIELDS,
    onSubmit: ({ name }) =>
      duplicateDocumentTemplate({
        documentTemplateId: _id,
        name,
        isLibrary,
      }),
    value: {
      name: '',
    },
    errorResolver: {
      Duplicated: [
        'name',
        i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.duplicatedErrorMessage'),
      ],
    },
    headerText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.title'),
    okText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.ok'),
    cancelText: i18n.t('admin.DocumentTemplateListPage.duplicateDocumentTemplateModal.cancel'),
    validationSchema: () =>
      Yup.object().shape({
        ...textsSchema({}),
      }),
    forceMultiField: true,
    width: '600px',
  });

export default openDuplicateDocumentTemplateModal;
