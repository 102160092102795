import apollo from 'graphql/apollo';
import { moveInputFieldMutation } from 'graphql/mutations';

const moveInputField = async ({ _id, offset, order }, categoryListQuery, categoryListQueryVariables) =>
  apollo.mutate({
    mutation: moveInputFieldMutation,
    variables: { _id, offset, order },
    refetchQueries: [{ query: categoryListQuery, variables: categoryListQueryVariables }],
  });

export default moveInputField;
