import { useLibraryContext } from 'contexts/LibraryContext';
import ItemModalNavigation from 'pages/admin/ItemCatalogueConfiguration/ItemModalNavigation';
import { useMemo, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const useItemModalNavigation = (loading, item) => {
  const itemModalNavigation = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLibrary } = useLibraryContext();
  const { categoryId: currentCategoryId } = useParams();

  const modalNavigation = useMemo(() => {
    if (!loading && !itemModalNavigation.current) {
      itemModalNavigation.current = new ItemModalNavigation(navigate, location, isLibrary, currentCategoryId, item);
    }
    return itemModalNavigation.current;
  }, [loading, currentCategoryId, isLibrary, item, location, navigate]);

  return modalNavigation;
};

export default useItemModalNavigation;
