import { DownOutlined, RightOutlined } from '@ant-design/icons';
import classes from './CollapsableButton.module.less';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

/**
 * Collapsable button to open and close collapsable content of the list item
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.open - Is the collapsable content open
 * @param {() => void} inputProperties.onClick - Function to call when the button gets clicked
 * @returns {JSX.Element} Button to open and close collapsable content
 * @component
 */
const CollapsableButton = ({ open, onClick }) => {
  if (open)
    return (
      <div className={classes.CollapsableButtonWrapper} onClick={onClick}>
        <DownOutlined className={classes.CollapsableButtonIcon} />
      </div>
    );
  return (
    <div className={classes.CollapsableButtonWrapper} onClick={onClick}>
      <RightOutlined className={classes.CollapsableButtonIcon} />
    </div>
  );
};

/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

export default CollapsableButton;
