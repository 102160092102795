import { gql } from '@apollo/client';

const orderBookmarkedServices = gql`
  mutation orderBookmarkedServices($bookmarkedServiceIds: [String!]!) {
    orderBookmarkedServices(bookmarkedServiceIds: $bookmarkedServiceIds) {
      success
    }
  }
`;

export default orderBookmarkedServices;
