import apollo from 'graphql/apollo';
import { createStaticItemMutation } from 'graphql/mutations';
import { adminCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createStaticItem = async ({ categoryId, name, value, isLibrary }) =>
  apollo.mutate({
    mutation: createStaticItemMutation,
    variables: { categoryId, modifier: { name, value: `${value}` } },
    update: (cache, { data }) => {
      const result = grabFirstGQLDataResult(data);
      const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
      cache.writeQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary },
        data: {
          categories: cachedData.categories.map((c) =>
            c._id === categoryId ? { ...c, staticItems: [...(c.staticItems || []), result] } : c,
          ),
        },
      });
    },
  });
export default createStaticItem;
