import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import classes from './ModalNavigation.module.less';

export const NAVIGATION_DIRECTION = { NEXT: 'next', PREVIOUS: 'previous' };

/**
 * Displays a confirmation modal when there are unsaved changes on navigation.
 * @returns {Promise<boolean>} A promise that resolves to true if the user chooses to save and navigate, false otherwise.
 */
export const confirmNavigationOnUnsavedChangesInModal = ({ disableOkButton }) => {
  return new Promise((resolve) => {
    confirmModal({
      cancelText: i18n.t('common.navigateOnUnsavedChangesModal.cancel'),
      okText: i18n.t('common.navigateOnUnsavedChangesModal.proceed'),
      okType: 'primary',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      title: i18n.t('common.navigateOnUnsavedChangesModal.title'),
      content: i18n.t(
        disableOkButton
          ? 'common.navigateOnUnsavedChangesModal.contentWithFormErrors'
          : 'common.navigateOnUnsavedChangesModal.content',
      ),
      disableOkButton,
    });
  });
};

/**
 * Checks for changes in a formik form before navigating.
 * If there are unsaved changes, a confirmation modal will be displayed before navigating.
 * @param {Object} formikRef - The formik reference for the form.
 * @returns {Promise<boolean>} A promise that resolves to True if there are unsaved changes, false otherwise.
 */
export const checkForChangesInModalOnNavigation = async (formikRef) => {
  if (!formikRef) return false;

  const formHasUnsavedChanges = formikRef && formikRef.dirty;
  const formHasErrors = !formikRef.isValid;

  if (formHasUnsavedChanges) {
    const proceed = await confirmNavigationOnUnsavedChangesInModal({ disableOkButton: formHasErrors });
    return !proceed;
  }
  return false;
};

/**
 * ArrowNavigation component for navigating between previous and next items displaying arrows.
 * @param {Object} props - The component props.
 * @param {Function} props.handleNavigation - The function to handle navigation.
 * @returns {JSX.Element} The ArrowNavigation component.
 */
export const ArrowNavigation = ({ handleNavigation }) => {
  return (
    <div className={classes.arrowNavigation}>
      <Tooltip title={i18n.t('common.itemCatalogueConfigurationModal.navigatePreviousTooltip')} mouseEnterDelay={1}>
        <Button
          onClick={() => handleNavigation(NAVIGATION_DIRECTION.PREVIOUS)}
          shape="default"
          type="text"
          icon={<ArrowLeftOutlined />}
        />
      </Tooltip>
      <Tooltip title={i18n.t('common.itemCatalogueConfigurationModal.navigateNextTooltip')} mouseEnterDelay={1}>
        <Button
          onClick={() => handleNavigation(NAVIGATION_DIRECTION.NEXT)}
          shape="default"
          type="text"
          icon={<ArrowRightOutlined />}
        />
      </Tooltip>
    </div>
  );
};
