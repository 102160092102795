import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLibraryContext } from 'contexts/LibraryContext';
import { deleteEmailTemplatesWithConfirmation } from 'graphql/methods';
import { useCachedQuery } from 'graphql/utils';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { buildPlaceholderLabelObject } from 'utils/buildPlaceholderLabelObject';
import { RichEditorField } from 'components/common/RichEditor';
import { RichText, PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { RenderDateTime } from 'components/common/List/FieldRenderers';
import { DefaultActionButtons } from 'components/common/List/DefaultActionButtons';
import { ListLayout } from 'components/common/List';
import openEditEmailTemplate from './EditEmailTemplate';

// Definitions of generator functions for list configuration

const generateListItemConfig = (t, isLibrary) => ({
  rows: [
    {
      rowColumns: [
        { fieldName: 'name', fieldLabel: t('admin.EmailTemplates.list.name') },
        {
          fieldName: 'subject',
          fieldLabel: t('admin.EmailTemplates.list.subject'),
          renderValue: (subject) => {
            if (RichText.isRichTextString(subject)) {
              return (
                <RichEditorField
                  key={subject}
                  readOnly
                  className="rich-editor-read-only"
                  initialValue={subject}
                  allowedUses={[PLACEHOLDER_USE.MAIL_SUBJECT]}
                />
              );
            }
            return subject;
          },
        },
        {
          fieldName: 'createdAt',
          fieldLabel: t('admin.EmailTemplates.list.createdAt'),
          renderValue: RenderDateTime,
        },
      ],
      alignLastEntryRight: true,
    },
    {
      rowColumns: [{ fieldName: 'description', fieldLabel: t('admin.EmailTemplates.list.description') }],
      alignLastEntryRight: true,
    },
  ],
  actionButtons: DefaultActionButtons(
    (emailTemplate) => openEditEmailTemplate({ emailTemplate, isLibrary }),
    (item) => deleteEmailTemplatesWithConfirmation({ _id: item._id, isLibrary }),
  ),
  onClick: (emailTemplate) => openEditEmailTemplate({ emailTemplate, isLibrary }),
});

const generateSortBarConfig = (t) => ({
  sortFields: [
    { fieldName: 'name', label: t('admin.EmailTemplates.list.name') },
    {
      fieldName: 'subject',
      label: t('admin.EmailTemplates.list.subject'),
      compareFunc: (a, b) => {
        const placeholderObject = buildPlaceholderLabelObject();
        const aText = RichText.richEditorTextToText(a, placeholderObject);
        const bText = RichText.richEditorTextToText(b, placeholderObject);
        return aText.localeCompare(bText);
      },
    },
    { fieldName: 'createdAt', label: t('admin.EmailTemplates.list.createdAt') },
  ],
  initSort: ['name', 'ASC'],
});

const generateSearchCardConfig = (t) => ({
  searchPlaceholder: t('admin.EmailTemplates.list.searchPlaceholder'),
  queryFields: ['name', 'subject'],
});

const generateListDataHook = (isLibrary) => {
  return () => {
    const { data, error, loading } = useCachedQuery(adminEmailTemplatesQuery, {
      variables: { isLibrary },
      fetchPolicy: 'cache-and-network',
    });
    return { data: useMemo(() => grabFirstGQLDataResult(data), [data]), error, loading };
  };
};

/**
 * List for email templates with search and sort functionality
 * @returns {JSX.Element} The email templates list
 * @component
 */
const EmailTemplatesList = () => {
  const { isLibrary } = useLibraryContext();
  const { t } = useTranslation();

  const listItemConfig = useMemo(() => generateListItemConfig(t, isLibrary), [t, isLibrary]);
  const searchCardConfig = useMemo(() => generateSearchCardConfig(t), [t]);
  const sortBarConfig = useMemo(() => generateSortBarConfig(t), [t]);
  const useDataFetching = useMemo(() => generateListDataHook(isLibrary), [isLibrary]);

  return (
    <ListLayout
      listItemConfig={listItemConfig}
      searchCardConfig={searchCardConfig}
      sortBarConfig={sortBarConfig}
      useDataFetching={useDataFetching}
      shouldUseSearchQueryParams
    />
  );
};

export default EmailTemplatesList;
