import GenericNavigation from 'components/layout/navigation/GenericNavigation';
import apollo from 'graphql/apollo';
import { adminCategoryListQuery } from 'graphql/queries';
import { generatePath } from 'react-router';
import routePaths from 'router/route-paths';
import { grabFirstGQLDataResult } from 'utils/helpers';

/**
 * Class for handling navigation between items in item configuration modal
 * @param {Function} navigate - The navigate function from react-router-dom
 * @param {Object} location - The location object from react-router-dom
 * @param {boolean} isLibrary - Whether the item configuration is in the library or not
 * @param {string} categoryId - The category ID of the item
 * @param {Object} item - The current item to navigate from
 */
export default class ItemModalNavigation {
  constructor(navigate, location, isLibrary, categoryId, item) {
    this.navigate = navigate;
    this.location = location;
    this.isLibrary = isLibrary;
    this.categoryId = categoryId;
    this.item = item;
    this.navigation = new GenericNavigation({
      navigate,
      location,
      fetchEntities: this.fetchCategoryItems,
      constructPath: this.constructItemPath,
      entity: item,
    });
  }

  /**
   * Fetches the items in the category
   * @returns {Promise<Array<Object>>} The items in the category
   */
  fetchCategoryItems = async () => {
    const categories = grabFirstGQLDataResult(
      apollo.readQuery({
        query: adminCategoryListQuery,
        variables: { isLibrary: this.isLibrary },
      }),
    );
    if (!categories) return [];

    const category = categories.find((c) => c._id === this.categoryId);
    return category.items;
  };

  /**
   * Constructs the path for an item
   * @param {Object} item - The item to construct the path for
   * @returns {string} The path for the item
   */
  constructItemPath = (item) => {
    const base = this.isLibrary ? routePaths.commonLibraryItemConfiguration : routePaths.itemCatalogueConfiguration;
    return generatePath(base, {
      id: item._id,
      categoryId: item.category._id,
    });
  };

  /**
   * Navigates to the next item
   * @param {Object} param - The parameters for navigation
   * @param {NAVIGATION_DIRECTION.NEXT|NAVIGATION_DIRECTION.PREVIOUS} param.direction - The direction to navigate in
   * @param {Object} param.formikRef - The formik reference for the form
   * @param {string} param.activeTab - The active tab to navigate to
   * @returns {Promise<void>} A promise that resolves when the navigation is complete
   */
  handleNavigation = ({ direction, formikRef, activeTab }) => {
    this.navigation.handleNavigation(direction, formikRef, activeTab);
  };
}
