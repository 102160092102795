import apollo from 'graphql/apollo';
import { deleteProjectCardAndClientMutation } from 'graphql/mutations';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { toastGraphQLError } from 'utils/helpers';
import toast from 'utils/toast';

const deleteProjectCardAndClient = async ({ _id }) =>
  apollo.mutate({
    mutation: deleteProjectCardAndClientMutation,
    variables: { _id },
  });

const deleteProjectCardAndClientWithConfirmation = ({ _id, onOk }) =>
  confirmModal({
    cancelText: i18n.t('admin.projectCard.deletion.deleteConfirmationModal.cancel'),
    maskClosable: true,
    okText: i18n.t('admin.projectCard.deletion.deleteConfirmationModal.ok'),
    okType: 'danger',
    onOk: () =>
      deleteProjectCardAndClient({ _id })
        .then((props) => {
          onOk(props);
          toast.successDefault();
        })
        .catch(toastGraphQLError),
    title: i18n.t('admin.projectCard.deletion.deleteConfirmationModal.titleDeleteProjectCardWithClient'),
  });

export default deleteProjectCardAndClientWithConfirmation;
