import { useQuery } from '@apollo/client';
import PageContainerActionButton from 'components/common/PageContainerActionButton';
import { userSigningProcessesQuery } from 'graphql/queries';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import { doUserNeedToSign } from 'model/ShoppingCart';
import { useTranslation } from 'react-i18next';
import { FaSignature } from 'react-icons/fa';
import { generatePath } from 'react-router';
import routePaths from 'router/route-paths';

const SignCartButton = ({ shoppingCart }) => {
  const { t: translation } = useTranslation();
  const [me] = useCurrentUser();

  const { digitalSignatureState, signatureData, _id: shoppingCartId } = shoppingCart || {};

  const doUserNeedToSignCart = doUserNeedToSign(digitalSignatureState, signatureData, me);

  const { data } = useQuery(userSigningProcessesQuery, {
    skip: !doUserNeedToSignCart,
    fetchPolicy: 'cache-and-network',
  });

  if (!shoppingCart) return null;
  if (!doUserNeedToSignCart) return null;

  const linkToken = data?.openSigningProcessesOfUser?.signingProcesses?.find(
    (signingProcess) => signingProcess.shoppingCartId === shoppingCartId,
  )?.link;

  if (!linkToken) return null;

  const link = generatePath(routePaths.signingStart, { token: linkToken });

  const signCart = () => {
    window.open(link.toString());
  };

  return (
    <PageContainerActionButton
      onClick={signCart}
      type="primary"
      icon={
        <span className="anticon">
          <FaSignature size={19} />
        </span>
      }
    >
      {translation('viewer.ShoppingCartView.signCartButton')}
    </PageContainerActionButton>
  );
};

export default SignCartButton;
