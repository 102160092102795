import DragAndDropBox from 'components/common/DragAndDrop/DragAndDropBox';
import ListItem from '../ListItem/ListItem';
import classes from './ListItems.module.less';

/**
 * List items component to render multiple list items in the list layouts
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.data - Data entries to be displayed
 * @param {object[]} inputProperties.rows - Rows to be displayed
 * @param {object[]} inputProperties.rows.rowColumns - Columns to show in the row
 * @param {string} inputProperties.rows.rowColumns.fieldName - Field name of the item
 * @param {string} inputProperties.rows.rowColumns.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} inputProperties.rows.rowColumns.renderValue - Optional: Function to render the value
 * @param {boolean} inputProperties.rows.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object[]} inputProperties.actionButtons - Action buttons to be displayed
 * @param {React.ReactNode} inputProperties.actionButtons.icon - Icon for the action button
 * @param {string} inputProperties.actionButtons.key - Key for the action button
 * @param {(item: object) => void} inputProperties.actionButtons.onClick - Function to call when the action button is clicked
 * @param {boolean} inputProperties.actionButtons.danger - Should the action button in danger style
 * @param {boolean} inputProperties.bordered - Whether list items should be bordered
 * @param {boolean} inputProperties.showLabel - Whether list items should show labels
 * @param {(item: object) => void} inputProperties.onClick - Function to be called when a list item is clicked
 * @param {React.ReactNode} inputProperties.collapseContent - Content for the collapsable content area
 * @param {boolean} inputProperties.collapsable - Whether list items should be collapsable
 * @param {boolean} inputProperties.selectable - Whether list items should be selectable
 * @param {(item: object, select: boolean) => void} inputProperties.onSelect - Function to be called when a list item selection changes
 * @param {boolean} inputProperties.draggable - Whether list items should be draggable
 * @param {object[]} inputProperties.selected - Selected list items
 * @param {(dragIndex: number, hoverIndex: number) => void} inputProperties.moveData - Function to move data in data entries for drag and drop
 * @param {(item: object) => void} inputProperties.onOrderChange - Function to be called when order changes
 * @param {string} inputProperties.orderField - Field to order by for drag and drop
 * @param {string} inputProperties.identifier - Identifier field for list items
 * @returns {JSX.Element} List items for list layout
 * @component
 */
const ListItems = ({
  data,
  rows,
  actionButtons,
  bordered,
  showLabel,
  onClick,
  collapseContent,
  collapsable,
  selectable,
  onSelect,
  draggable,
  selected,
  moveData,
  onOrderChange,
  orderField,
  identifier,
}) => {
  let items;
  if (!draggable)
    items = data.map((item) => (
      <ListItem
        rows={rows}
        actionButtons={actionButtons.map((actionButton) => ({
          ...actionButton,
          onClick: () => actionButton.onClick(item),
        }))}
        item={item}
        bordered={bordered}
        showLabel={showLabel}
        onClick={() => onClick(item)}
        collapseContent={collapseContent}
        collapsable={collapsable}
        onSelect={(select) => onSelect(item, select)}
        draggable={draggable}
        selectable={selectable}
        key={identifier ? item[identifier] : JSON.stringify(item)}
        selected={selected.some((selectedItem) => selectedItem === item)}
      />
    ));
  else
    items = data.map((item, index) => (
      <DragAndDropBox
        key={identifier ? item[identifier] : JSON.stringify(item)}
        type="ListItem"
        id={identifier ? item[identifier] : JSON.stringify(item)}
        moveData={moveData}
        afterDrop={onOrderChange}
        orderField={orderField}
        orderValue={index}
      >
        <ListItem
          rows={rows}
          actionButtons={actionButtons.map((actionButton) => ({
            ...actionButton,
            onClick: () => actionButton.onClick(item),
          }))}
          item={item}
          bordered={bordered}
          showLabel={showLabel}
          onClick={() => onClick(item)}
          collapseContent={collapseContent}
          collapsable={collapsable}
          onSelect={(select) => onSelect(item, select)}
          draggable={draggable}
          selectable={selectable}
          selected={selected.some((selectedItem) => selectedItem === item)}
        />
      </DragAndDropBox>
    ));

  return <div className={classes.ListItems}>{items}</div>;
};

export default ListItems;
