/* eslint-disable no-nested-ternary */
import { Badge, Collapse, Divider } from 'antd';
import { Form, Select } from 'formik-antd';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { filter, find, pickBy, intersectionBy, isEmpty } from 'lodash';
import FormItem from 'components/common/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { hasCategoryStartOfService, isItemRecursive } from 'pages/shoppingCartManagement/ShoppingCart/utils';
import cn from 'classnames';
import { getCategoriesCache, useGetCategoryByIdCache } from 'graphql/cache';
import { Cart, numberToLocaleString } from '@JavaScriptSuperstars/kanzleipilot-shared';
import Switch from 'components/common/Switch';
import { CalculationMode } from 'constants/item';
import classes from './CategoryInCart.module.less';
import { CategoryContext, useCategoryContext, useShowCategoryTotalContext, useVariablesContext } from './context';
import { calcItem, getDiscountForCategory, shouldPricePreviewBeVisibleForCurrentFeeType } from './utils';
import { CategoryTotalTable, useCalcCategoryTotalById } from './TotalTable';
import CategoryPanelHeader from './CategoryPanelHeader';
import ItemCard from './ItemCard';
import { CollapseArrow, InputFieldsMemo, StartOfServiceMemo } from './components';
import StaticItems from '../compareToGlobal/StaticItems';

const { Option } = Select;
const { Panel } = Collapse;

const getItemValidation = ({ item, values, errors, category }) => {
  const { _id, calculationMode, recursiveFormulaInfo } = item;
  if (calculationMode === CalculationMode.ON_ACTUAL_COST)
    return {
      fieldError: undefined,
      isValid: true,
      relItems: [],
      relatedInputs: [],
      usedIdsInFormula: recursiveFormulaInfo.usedIdsInFormula,
    };
  const rootFields = (() => {
    const ids = recursiveFormulaInfo.moreInfo[item._id]?.ids;
    if (!ids) return null;
    const relatedInputs = intersectionBy(category.allAffectedInputFields, ids, (e) =>
      typeof e === 'object' ? e._id : e,
    );
    return relatedInputs;
  })();
  const relatedInputs = filter(rootFields, (input) => errors[input._id]);
  const relatedItems = recursiveFormulaInfo.formulaRequiredIds
    .map((itemId) => find(category.items, { _id: itemId }))
    .filter(Boolean);

  const fieldError = errors[_id];

  const allRelItems = isItemRecursive(item)
    ? []
    : // eslint-disable-next-line no-shadow
      relatedItems.map((item) => ({
        isSelected: values[item._id],
        item,
        isValid: getItemValidation({ item, values, errors, category })?.isValid,
      }));
  const relItems = allRelItems.filter(({ isSelected, isValid }) => !isSelected || !isValid);

  const isValid = !(relatedInputs?.length || fieldError || relItems?.length);
  return {
    relatedInputs,
    fieldError,
    relItems,
    isValid,
  };
};
const ItemContainer = (item) => {
  const {
    _id,
    benefits,
    calculationMode,
    descriptionForContract,
    displayInputFields,
    graduatedScaleMode,
    highlightBindingness,
    infoText,
    inputFields,
    internalInputFields,
    minPrice,
    name,
    notesForFeeAgreement,
    notesToEmployee,
    paymentInterval,
    pleaseNote,
    pricingFormulaExtended,
    roundPrice,
    scalePricingFormulaTitle,
    scaleTitle,
    scales,
    subTitle,
  } = item;

  const { t } = useTranslation();
  const { values, errors, setFieldError } = useFormikContext();
  const customPrice = values[`${_id}_customPrice`];
  const internalNoteToTeam = values[`${_id}_internalNoteToTeam`];
  const officialReasonText = values[`${_id}_officialReasonText`];
  const showCalculatedPrice = values[`${_id}_showCalculatedPrice`];
  const currentFeeTypeMonthly = Cart.isCurrentFeeTypeMonthly(values.feeType);
  const category = useCategoryContext();
  const cachedCategories = getCategoriesCache(); // get all categories
  const variables_a = useVariablesContext();
  const discount = getDiscountForCategory({ category, values });
  const isChecked = !!values[_id] || !!values[`${_id}_bookmark`];
  const apolloCacheCategory = useGetCategoryByIdCache(category._id); // category with disabled and enabled items
  const { relatedInputs, fieldError, relItems, isValid } = getItemValidation({
    item,
    values,
    errors,
    category: apolloCacheCategory,
  });
  const calcCategoryTotalById = useCalcCategoryTotalById();

  const currentFeeTypeMessage = isChecked
    ? shouldPricePreviewBeVisibleForCurrentFeeType({
        item,
        feeType: values.feeType,
      })
    : '';

  const price = useMemo(
    () =>
      isChecked && isValid && values.showPrices
        ? calcItem({
            values,
            category,
            item,
            inputFields,
            calcCategoryTotalById,
            allCategoryIds: cachedCategories.map((c) => c._id),
            variables_a,
          })
        : {},
    [cachedCategories, calcCategoryTotalById, category, inputFields, isChecked, isValid, item, values, variables_a],
  );
  useEffect(() => {
    if (!(isChecked && isValid)) return;
    if (!errors[_id] && !isEmpty(price) && !price.isPrice)
      setFieldError(_id, t('user.ShoppingCart.Category.Item.errors.infinityPrice'));
  }, [_id, errors, isChecked, isValid, price, setFieldError, t]);
  return (
    <ItemCard
      item={item}
      _id={_id}
      benefits={benefits}
      calculationMode={calculationMode}
      currentFeeTypeMessage={currentFeeTypeMessage}
      currentFeeTypeMonthly={currentFeeTypeMonthly}
      customPrice={customPrice}
      debugMode={values.debugMode}
      descriptionForContract={descriptionForContract}
      discount={discount}
      displayInputFields={displayInputFields}
      fieldError={fieldError}
      graduatedScaleMode={graduatedScaleMode}
      highlightBindingness={highlightBindingness}
      infoText={infoText}
      internalInputFields={internalInputFields}
      internalNoteToTeam={internalNoteToTeam}
      isChecked={isChecked}
      isValid={isValid}
      minPrice={minPrice}
      name={name}
      notesForFeeAgreement={notesForFeeAgreement}
      notesToEmployee={notesToEmployee}
      officialReasonText={officialReasonText}
      paymentInterval={paymentInterval}
      pleaseNote={pleaseNote}
      price={price}
      pricingFormulaExtended={pricingFormulaExtended}
      relItems={relItems}
      relatedInputs={relatedInputs}
      roundPrice={roundPrice}
      scalePricingFormulaTitle={scalePricingFormulaTitle}
      scaleTitle={scaleTitle}
      scales={scales}
      showCalculatedPrice={showCalculatedPrice}
      showDigits={values.showDigits}
      showPrices={values.showPrices}
      subTitle={subTitle}
    />
  );
};
const ItemContainerMemo = memo(ItemContainer);

const Items = () => {
  const category = useCategoryContext();
  const { items } = category;
  const { values } = useFormikContext();
  const discount = getDiscountForCategory({ category, values });
  const itemsArray = items.map((item) => {
    return (
      <div key={item._id} className={classes.itemContainer}>
        <ItemContainerMemo {...item} discount={discount} />
      </div>
    );
  });
  return (
    <div className={classes.items}>
      {itemsArray}
      <div className={classes.itemDivider} />
    </div>
  );
};
const MemoItems = memo(Items);
const Discount = ({ showDigits }) => {
  const { _id, discounts } = useCategoryContext();
  const { t } = useTranslation();
  const name = `${_id}_discount`;
  return (
    <FormItem label={t('user.ShoppingCart.Category.Discount.label')} className={classes.discount} name={name}>
      <Select name={name} className={classes.discountSelect} allowClear>
        {discounts.map((discount) => (
          <Option key={discount._id} value={discount._id}>
            {discount.name} (
            {numberToLocaleString(discount.value, t('localeCode'), {
              showDigits,
              isRestrictMaximumDigits: false,
            })}
            {'\xa0'}
            {discount.type === 'percent' ? '%' : '€'})
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};
const join = (elements, divider = <Divider className={classes.divider} />) => {
  return (
    <div>
      {elements?.length
        ? elements
            .map((element) => <span>{element}</span>)
            .reduce(
              (prev, curr, index) => (
                <>
                  {prev}
                  {index === 0 ? null : divider}
                  {curr}
                </>
              ),
              [],
            )
        : null}
    </div>
  );
};

function BadgeSelectedItems({ count }) {
  return (
    <div className={classes.badgeContainer}>
      <div className={cn(classes.badgeCategory, 'active-badge-category')}>
        <Badge count={count} />
      </div>
    </div>
  );
}
const BadgeSelectedItemsMemo = memo(BadgeSelectedItems, equal);

const CategoryTotal = ({ showPrices, t }) => {
  const showCategoryTotal = useShowCategoryTotalContext();
  const [isShow, setIsShow] = useState(showCategoryTotal);

  if (!showPrices) return null;
  return (
    <>
      <div className="flex-align-right margin-top-16">
        <Switch checked={isShow} onChange={setIsShow} label={t('user.ShoppingCart.Category.Total.label')} />
      </div>
      {isShow ? (
        <div className="margin-top-16">
          <CategoryTotalTable />
        </div>
      ) : null}
    </>
  );
};

const CategoryTotalMemo = memo(CategoryTotal, equal);

const CategoryInCart = ({ category, showPrices, t, className }) => {
  const {
    _id,
    discounts,
    displayInputFields,
    introForStaticItem,
    isNew,
    items,
    name,
    outroForStaticItem,
    staticItems,
    subTitle,
  } = category;
  const displayStartOfService = hasCategoryStartOfService(category);
  const { values } = useFormikContext();
  const count = useMemo(() => {
    const activeFields = pickBy(values, (value) => typeof value === 'boolean' && value);
    return intersectionBy(
      Object.keys(activeFields),
      category.items.map((e) => e._id),
      (e) => e.replace(/_bookmark$/, ''),
    ).length;
  }, [category.items, values]);
  // eslint-disable-next-line no-param-reassign
  category.items = category.items.map((item) => ({
    ...item,
    customPrice: values[`${item._id}_customPrice`],
  }));
  const isOpen = count || isNew;
  return (
    <CategoryContext.Provider value={category}>
      <Collapse
        bordered={false}
        defaultActiveKey={isOpen ? [_id] : null}
        expandIconPosition="left"
        className={className}
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={({ isActive }) => <CollapseArrow isActive={isActive} />}
      >
        <Panel
          className={cn(count && classes.collapseActive, classes.collapse)}
          forceRender
          header={<CategoryPanelHeader subTitle={subTitle} title={name} />}
          key={_id}
          extra={<BadgeSelectedItemsMemo count={count} />}
        >
          <Form layout="vertical">
            {displayStartOfService && <StartOfServiceMemo />}
            {join(
              [
                displayInputFields.length && <InputFieldsMemo inputFields={displayInputFields} />,
                items?.length && <MemoItems />,
                values?.showDiscounts && discounts?.length && <Discount showDigits={values.showDigits} />,
                staticItems?.length && (
                  <StaticItems
                    introForStaticItem={introForStaticItem}
                    outroForStaticItem={outroForStaticItem}
                    staticItems={staticItems}
                    showPrices={values.showPrices}
                    showVat={values.showVat}
                    showDigits={values.showDigits}
                  />
                ),
              ].filter(Boolean),
              <br />,
            )}
            <CategoryTotalMemo showPrices={showPrices} t={t} />
          </Form>
        </Panel>
      </Collapse>
    </CategoryContext.Provider>
  );
};

const CategoryInCartMemo = memo(CategoryInCart);
const CategoryInCartContainer = ({ category, className }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  return <CategoryInCartMemo category={category} className={className} showPrices={values.showPrices} t={t} />;
};
const CategoryInCartContainerMemo = memo(CategoryInCartContainer);
export default CategoryInCartContainerMemo;
