import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import inputConfirmModal from 'utils/inputConfirmModal';
import { Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  createDiscount,
  deleteDiscountWithConfirmation,
  isDiscountUsedInCart,
  updateDiscount,
  moveCategoryDiscount,
} from 'graphql/methods';
import classNames from 'classnames';
import { updateDiscountsCategoryCache } from 'graphql/cache';
import confirmModal from 'utils/confirmModal';
import { TableMemo } from 'memo';
import { memo, useCallback } from 'react';
import EmptyBox from 'components/common/EmptyBox';
import { useLibraryContext } from 'contexts/LibraryContext';
import { adminCategoryListQuery } from 'graphql/queries';
import equal from 'fast-deep-equal/es6/react';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import InputNumber from 'components/common/InputNumber';
import { numberToLocaleString } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { DragHandle, useDragTableComponents, useSortEnd } from './drag/DragTable';

const getDiscountFields = () => [
  {
    label: i18n.t('admin.discountFieldModal.nameFieldLabel'),
    name: 'name',
    description: i18n.t('admin.discountFieldModal.nameFieldDescription'),
  },
  {
    label: i18n.t('admin.discountFieldModal.typeFieldLabel'),
    name: 'type',
    component: (props) => {
      return (
        <Radio.Group {...props}>
          <Radio.Button value="percent">{i18n.t('admin.discountFieldModal.typeFieldPercent')}</Radio.Button>
          {/* <Radio.Button value="absolute">{i18n.t('admin.discountFieldModal.typeFieldAbsolute')}</Radio.Button> */}
        </Radio.Group>
      );
    },
  },
  {
    label: i18n.t('admin.discountFieldModal.valueFieldLabel'),
    name: 'value',
    description: i18n.t('admin.discountFieldModal.nameFieldDescription'),
    component: InputNumber,
    props: ({ formikContext: { values } }) => {
      const isPercent = values.type === 'percent';
      return {
        min: 0,
        max: isPercent ? 100 : Infinity,
        formatter: (value) => `${value}${isPercent ? '%' : ' €'}`,
        parser: (value) => value.replace(/\s*[%€]/, ''),
      };
    },
  },
];

const addDiscount = (categoryId, isLibrary) =>
  inputConfirmModal({
    headerText: i18n.t('admin.discountFieldModal.addModalTitle'),
    fields: getDiscountFields(),
    value: { name: '', value: 10, type: 'percent' },
    onSubmit: ({ name, value, type }) => createDiscount({ categoryId, name, value, type, isLibrary }),
    errorResolver: { Duplicated: ['name', i18n.t('admin.discountFieldModal.duplicatedErrorMessage')] },
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
  });
const editDiscount = ({ _id, currentName, currentValue, currentType }) =>
  inputConfirmModal({
    headerText: i18n.t('admin.discountFieldModal.editModalTitle'),
    onSubmit: async ({ name, value, type }) => {
      const used = await isDiscountUsedInCart({ _id });
      const onConfirm = () => updateDiscount({ _id, name, value, type });
      if (!used) return onConfirm();
      return confirmModal({
        cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
        okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
        okType: 'danger',
        onOk: onConfirm,
        title: i18n.t('admin.discountUsedInShoppingCartConfirmation.titleUpdate'),
      });
    },
    fields: getDiscountFields(),
    cancelText: i18n.t('admin.discountUsedInShoppingCartConfirmation.cancel'),
    okText: i18n.t('admin.discountUsedInShoppingCartConfirmation.ok'),
    value: { name: currentName, value: currentValue, type: currentType },
    errorResolver: { Duplicated: ['name', i18n.t('admin.discountFieldModal.duplicatedErrorMessage')] },
  });
const discountTableColumns = ({ t, isLibrary }) => [
  {
    className: 'drag-visible',
    width: 10,
    render: () => <DragHandle />,
    key: 'sort',
  },
  {
    className: classNames('col-8', 'table-text'),
    title: t('admin.discountTableColumns.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    className: classNames('col-2', 'table-text'),
    title: t('admin.discountTableColumns.type'),
    dataIndex: 'type',
    key: 'type',
    render: (v) => t(`common.Discount.${v}`),
  },
  {
    className: classNames('col-2', 'table-text'),
    title: t('admin.discountTableColumns.value'),
    key: 'value',
    render: (discount) => (
      <span className="white-space-nowrap">{`${numberToLocaleString(discount.value, t('localeCode'), {
        isRestrictMaximumDigits: false,
      })}${discount.type === 'percent' ? '%' : ' €'}`}</span>
    ),
  },
  {
    title: t('admin.discountTableColumns.actions'),
    dataIndex: '',
    key: 'actions',
    className: 'action-column-2',
    render: ({ _id, name, value, type }) => {
      return (
        <>
          <Button
            ghost
            className="ant-btn-default"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editDiscount({ _id, currentName: name, currentValue: value, currentType: type })}
          />{' '}
          <Button
            type="danger"
            className="ant-btn-default"
            ghost
            icon={<DeleteOutlined />}
            onClick={() => deleteDiscountWithConfirmation({ _id, isLibrary })}
          />
        </>
      );
    },
  },
];

const DiscountsHelperWidget = memo(() => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('admin.CatalogueConfiguration.DiscountsHelperWidget.howUseButton')}
      title={t('admin.CatalogueConfiguration.DiscountsHelperWidget.modalInfo.title')}
      helpText={t('admin.CatalogueConfiguration.DiscountsHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.CatalogueConfiguration.DiscountsHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.CatalogueConfiguration.DiscountsHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.CatalogueConfiguration.DiscountsHelperWidget.modalInfo.imageUrl')}
    />
  );
}, equal);

const DiscountsTab = ({ _id, discounts }) => {
  const { t } = useTranslation();

  const { isLibrary } = useLibraryContext();

  const moveInCache = useCallback((newData) => updateDiscountsCategoryCache({ _id, discounts: newData }), [_id]);

  const { onSortEnd } = useSortEnd({
    dataSource: discounts,
    moveInCache,
    moveMutation: moveCategoryDiscount,
    refetchQuery: adminCategoryListQuery,
    refetchQueryVariables: { isLibrary },
  });

  const { DraggableContainer, DraggableBodyRow } = useDragTableComponents({ dataSource: discounts, onSortEnd });
  return (
    <>
      <Button
        icon={<PlusOutlined />}
        className="margin-right-16"
        onClick={() => addDiscount(_id, isLibrary)}
        type="primary"
      >
        {t('admin.addDiscountButton')}
      </Button>
      <DiscountsHelperWidget />
      <br />
      <br />
      <div className="table-wrapper">
        <TableMemo
          locale={{ emptyText: <EmptyBox label={t('admin.CatalogueConfiguration.emptyDiscountListMessage')} /> }}
          rowKey="_id"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          scroll={{
            x: 388,
          }}
          dataSource={discounts}
          pagination={false}
          columns={discountTableColumns({ t, isLibrary })}
        />
      </div>
    </>
  );
};

export default memo(DiscountsTab, equal);
