import { useCallback, useEffect, useState } from 'react';
import useParametersFromSearchParams from './useParametersFromSearchParams';
import {
  buildFilterParameter,
  buildSortParameter,
  filterData,
  modifySearchParameters,
  parseFilterParameter,
  parseSortParameter,
  searchQuery,
  sortData,
} from './utils';

/**
 * Hook to handle sorting, filtering and searching state for a list and to modify the data accordingly when enabled for not paginated lists
 * (Paginated lists should set shouldModifyData to false and pass state to the data fetching hook to handle sorting, filtering and searching server side)
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.data - Data to sort and filter
 * @param {(data: object[]) => void} inputProperties.setSortedAndFilteredData - Function to set sorted and filtered data
 * @param {string[]} inputProperties.initSort - Initial sort settings [fieldName, direction]
 * @param {object[]} inputProperties.sortSettings - Configuration for sorting
 * @param {string} inputProperties.sortSettings.fieldName - Field name to sort by
 * @param {string} inputProperties.sortSettings.label - Label for the field
 * @param {string} inputProperties.sortSettings.compareFunc - Optional: Function to compare the field
 * @param {object[]} inputProperties.filterSettings - Configuration for filtering
 * @param {string} inputProperties.filterSettings.fieldName - Field name to filter by
 * @param {string} inputProperties.filterSettings.label - Label for the field
 * @param {object[]} inputProperties.filterSettings.options - Options for the filter
 * @param {string} inputProperties.filterSettings.options.value - Value for the filter
 * @param {string} inputProperties.filterSettings.options.label - Label for the filter
 * @param {(item: object, value: string) => boolean} inputProperties.filterSettings.applyFilter - Optional: Function to apply the filter
 * @param {string[]} inputProperties.queryFields - Fields to search in
 * @param {boolean} inputProperties.shouldUseSearchQueryParams - Whether to use search query params
 * @param {string} inputProperties.orderField - Field to order by for drag and drop
 * @param {string} inputProperties.orderDirection - Direction to order by for drag and drop
 * @param {boolean} inputProperties.shouldModifyData - Whether to modify data or not (default: true) (When enabled the hook will sort and filter when state changes and set the sorted and filtered data with setSortedAndFilteredData)
 * @returns {[sortState: string[], setSort: (fieldName: string, direction: string) => void, filterState: string[][], setFilter: (fieldName: string, value: string) => void, queryState: string, setQuery: (newQuery: string) => void, resetSortAndFilter: () => void]} - Array with sort state, setSort function, filter state, setFilter function, query state, setQuery function and resetSortAndFilter function
 */
const useSortFilterQuery = ({
  data,
  setSortedAndFilteredData,
  initSort,
  sortSettings,
  filterSettings,
  queryFields,
  shouldUseSearchQueryParams,
  orderField,
  orderDirection,
  shouldModifyData = true,
}) => {
  const [sort, filter, query] = useParametersFromSearchParams();

  const [sortFilterQueryState, setSortFilterQueryState] = useState({
    sort: sort || sort === '' ? parseSortParameter(sort) : initSort,
    filter: filter || filter === '' ? parseFilterParameter(filter) : [],
    query: query || query === '' ? query : '',
  });

  useEffect(() => {
    if (!shouldModifyData) return;
    if (!data) setSortedAndFilteredData([]);
    const filteredData = searchQuery(
      filterData(data, sortFilterQueryState.filter, filterSettings),
      sortFilterQueryState.query,
      queryFields,
    );
    const sortedData =
      sortFilterQueryState.sort?.length !== 0
        ? sortData(
            filteredData,
            sortFilterQueryState.sort,
            sortSettings?.find(
              (sortField) =>
                sortFilterQueryState.sort.length === 2 &&
                sortFilterQueryState.sort[0] &&
                sortField.fieldName === sortFilterQueryState.sort[0],
            )?.compareFunc,
          )
        : filteredData;
    setSortedAndFilteredData([...sortedData]);
  }, [
    data,
    sortFilterQueryState,
    sort,
    sortSettings,
    queryFields,
    filterSettings,
    setSortedAndFilteredData,
    shouldModifyData,
  ]);

  useEffect(() => {
    const parsedSort = sort || sort === '' ? parseSortParameter(sort) : initSort;
    const parsedFilter = filter || filter === '' ? parseFilterParameter(filter) : [];
    const parsedQuery = query || query === '' ? query : '';
    if (
      parsedSort !== sortFilterQueryState.sort ||
      parsedFilter !== sortFilterQueryState.filter ||
      parsedQuery !== sortFilterQueryState.query
    )
      setSortFilterQueryState({ sort: parsedSort, filter: parsedFilter, query: parsedQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, query]);

  const setSort = useCallback(
    (fieldName, direction) => {
      if (shouldUseSearchQueryParams) modifySearchParameters('sort', buildSortParameter([fieldName, direction]));
      setSortFilterQueryState((prevSortFilterQueryState) => ({
        ...prevSortFilterQueryState,
        sort: [fieldName, direction],
      }));
    },
    [shouldUseSearchQueryParams, setSortFilterQueryState],
  );

  const setFilter = useCallback(
    (fieldName, value) => {
      setSortFilterQueryState((prevSortFilterQueryState) => {
        const cleanedPrevFilter = prevSortFilterQueryState.filter.filter(
          (filterElement) => filterElement[0] !== fieldName,
        );
        const newFilter = value ? [...cleanedPrevFilter, [fieldName, value]] : cleanedPrevFilter;
        if (shouldUseSearchQueryParams) modifySearchParameters('filter', buildFilterParameter(newFilter));
        return { ...prevSortFilterQueryState, filter: newFilter };
      });
    },
    [shouldUseSearchQueryParams, setSortFilterQueryState],
  );

  const setQuery = useCallback(
    (newQuery) => {
      if (shouldUseSearchQueryParams) modifySearchParameters('query', newQuery);
      setSortFilterQueryState((currentState) => ({ ...currentState, query: newQuery }));
    },
    [shouldUseSearchQueryParams, setSortFilterQueryState],
  );

  const resetSortAndFilter = useCallback(() => {
    setSortFilterQueryState({ sort: orderField ? [orderField, orderDirection] : initSort, filter: [], query: '' });
  }, [orderField, orderDirection, initSort]);

  return [
    sortFilterQueryState.sort,
    setSort,
    sortFilterQueryState.filter,
    setFilter,
    sortFilterQueryState.query,
    setQuery,
    resetSortAndFilter,
  ];
};

export default useSortFilterQuery;
