import { useTranslation } from 'react-i18next';
import { useLibraryContext } from 'contexts/LibraryContext';
import { Input, Checkbox } from 'formik-antd';
import FormItem from 'components/common/FormItem';
import Switch from 'components/common/Switch';
import SubjectFormikInput from './SubjectFormikInput';
import BodyFormikInput from './BodyFormikInput';
import classes from './EmailTemplateForm.module.less';

/**
 * Form component for email template creation and editing
 * @returns {JSX.Element} EmailTemplateForm
 * @component
 */
const EmailTemplateForm = () => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  return (
    <>
      <FormItem name="name" label={t('admin.EmailTemplates.fields.name.label')}>
        <Input name="name" placeholder={t('admin.EmailTemplates.fields.name.placeholder')} />
      </FormItem>
      <SubjectFormikInput />
      <BodyFormikInput />
      <FormItem name="description" label={t('admin.EmailTemplates.fields.description.label')}>
        <Input name="description" placeholder={t('admin.EmailTemplates.fields.description.placeholder')} />
      </FormItem>
      <div className={classes.attachmentsSwitchContainer}>
        <Switch
          className={classes.attachmentsSwitch}
          name="shouldSendAttachments"
          label={t('admin.EmailTemplates.fields.shouldSendAttachments.label')}
          checkedChildren={t('admin.EmailTemplates.fields.shouldSendAttachments.on')}
          unCheckedChildren={t('admin.EmailTemplates.fields.shouldSendAttachments.off')}
        />
      </div>
      {isLibrary ? (
        <FormItem name="isDefault" label={t('admin.EmailTemplates.fields.isDefault.label')}>
          <Checkbox name="isDefault">{t('admin.EmailTemplates.fields.isDefault.label')}</Checkbox>
        </FormItem>
      ) : null}
    </>
  );
};

export default EmailTemplateForm;
