import PageContainer from 'components/layout/PageContainer';
import { useTranslation } from 'react-i18next';
import { Collapse, Skeleton } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { resetTheme } from 'graphql/methods';
import { useTheme } from 'graphql/hooks';
import toast from 'utils/toast';
import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import TableTheme from './TableTheme';
import TableOfContent from './TableOfContent';
import CorporatePrimaryColour from './CorporatePrimaryColour';
import TextStylesForDocuments from './TextStylesForDocuments';
import BodyPrimary from './BodyPrimary';
import classes from './CorporateIdentitySetting.module.less';
import CorporateIdentityHelperWidget from './CorporateIdentityHelperWidget';
import CorporateLogo from './CorporateLogo';

const { Panel } = Collapse;

const skeletons = [...new Array(3)].map((_, i) => i + 1);
const onResetTheme = async () => {
  const onConfirm = () =>
    resetTheme()
      .then(() => {
        toast.successDefault();
        window.location.reload();
      })
      .catch((e) => console.error(e) || toast.error(i18n.t('admin.Setting.CorporateIdentity.errors.reset')));

  confirmModal({
    cancelText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.cancel'),
    okText: i18n.t('admin.inputFieldModal.comboOptions.table.deleteOptionConfirmation.ok'),
    okType: 'danger',
    onOk: onConfirm,
    title: i18n.t('admin.Setting.CorporateIdentity.resetConfirmModalMessage'),
  });
};

const actionButtons = [
  {
    label: i18n.t('admin.Setting.CorporateIdentity.reset'),
    icon: <UndoOutlined />,
    onClick: onResetTheme,
  },
];

function CorporateIdentitySetting() {
  const { t } = useTranslation();
  const { loading, theme } = useTheme();

  return (
    <PageContainer
      title={t('admin.Setting.CorporateIdentity.title')}
      actionButtons={actionButtons}
      left={<CorporateIdentityHelperWidget />}
    >
      {loading ? (
        skeletons.map((k) => <Skeleton title loading={!theme && loading} active key={k} />)
      ) : (
        <>
          <CorporateLogo />
          <CorporatePrimaryColour
            primaryColor={theme.main.primaryColor}
            color={theme.main.color}
            fontFamily={theme.main.fontFamily}
          />
          <Collapse>
            <Panel
              forceRender
              header={t('admin.Setting.CorporateIdentity.customize')}
              className={classes.collapsePanel}
            >
              <TextStylesForDocuments
                title={theme.title}
                h1={theme.h1}
                h2={theme.h2}
                h3={theme.h3}
                textBlock={theme.textBlock}
                testimonial={theme.testimonial}
                testimonialSmallText={theme.testimonialSmallText}
                textLessImportant={theme.textLessImportant}
              />
              <TableTheme
                table={theme.table}
                tableContent={theme.tableContent}
                tableFooter={theme.tableFooter}
                tableHeaderPrimary={theme.tableHeaderPrimary}
                tableHeaderSecondary={theme.tableHeaderSecondary}
                tableLeftColumn={theme.tableLeftColumn}
              />
              <TableOfContent tableOfContent={theme.tableOfContent} />
              <BodyPrimary bodyPrimary={theme.bodyPrimary} bodyPrimaryFooter={theme.bodyPrimaryFooter} />
            </Panel>
          </Collapse>
        </>
      )}
    </PageContainer>
  );
}
export default CorporateIdentitySetting;
