import { PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useTranslation } from 'react-i18next';
import EmailFormikRichEditor from './EmailFormikRichEditor';
import classes from './BodyFormikInput.module.less';

/**
 * Input component for the email body field with richtext and formik support
 * @returns {JSX.Element} BodyFormikInput
 */
const BodyFormikInput = () => {
  const { t } = useTranslation();
  return (
    <EmailFormikRichEditor
      label={t('admin.EmailTemplates.fields.body.label')}
      name="body"
      allowedUses={[PLACEHOLDER_USE.MAIL_BODY]}
      className={classes.bodyEditor}
      rootElement="paragraph"
    />
  );
};

export default BodyFormikInput;
