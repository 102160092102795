import { useSearchParams } from 'react-router-dom';

/**
 * Hook to get parameters from search params for list state
 * @returns {Array} Array of parameters from search params: [sort, filter, query, limit, skip]
 */
const useParametersFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const sort = searchParams.get('sort');
  const filter = searchParams.get('filter');
  const query = searchParams.get('query');
  const limit = searchParams.get('limit');
  const skip = searchParams.get('skip');
  return [sort, filter, query, limit, skip];
};

export default useParametersFromSearchParams;
