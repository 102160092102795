import { useTranslation } from 'react-i18next';
import { FaCalculator } from 'react-icons/fa';
import { useSigningProcessShoppingCartContext } from 'contexts/SigningProcessShoppingCartContext';
import Card from '../Card';
import DiscountsAlert from './DiscountsAlert';
import PriceTable from './PriceTable';

// import classes from './PriceCard.module.less';

/**
 * PriceCard component for showing prices of a shopping cart in service overview step in signing process (Needs signing process shopping cart context)
 * @returns {JSX.Element} card component for prices in service overview step in signing process
 */
const PriceCard = ({ shoppingCartFromProps }) => {
  const { t: translation } = useTranslation();
  const { shoppingCart } = useSigningProcessShoppingCartContext();

  if (!shoppingCart && !shoppingCartFromProps) return null;

  const categories = shoppingCart?.categories || [];

  const discountsByCategories = categories
    .map((category) => {
      if (!category.discount) return null;
      return {
        name: category.texts.name,
        discount: category.discount.name,
      };
    })
    .filter(Boolean);

  return (
    <Card title={translation('signing.serviceOverviewStep.priceCard.heading')} icon={<FaCalculator size="15" />}>
      <DiscountsAlert discountsByCategories={discountsByCategories} />
      <PriceTable shoppingCart={shoppingCartFromProps || shoppingCart} />
    </Card>
  );
};

export default PriceCard;
