import SigningProcessLayout from 'components/layouts/SigningProcessLayout';
import IdentityStep from 'components/signing/IdentityStep';
import MonthlyPaymentDecisionStep from 'components/signing/MonthlyPaymentDecisionStep';
import OrderBookmarkedServicesStep from 'components/signing/OrderBookmarkedServicesStep';
import SepaStep from 'components/signing/SepaStep';
import ServiceOverviewStep from 'components/signing/ServiceOverviewStep';
import ViewDocumentsAndSign from 'components/signing/ViewDocumentsAndSign';
import useSigningContext from 'hooks/signing/useSigningContext';
import useUpdateCurrentSigningState from 'hooks/signing/useUpdateCurrentSigningState';
import { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routePaths from 'router/route-paths';

const STEPS = {
  IDENTITY: (submitReference, stepState) => <IdentityStep submitReference={submitReference} stepState={stepState} />,
  VIEW_SHOPPING_CART: (submitReference, stepState) => (
    <ServiceOverviewStep submitReference={submitReference} stepState={stepState} />
  ),
  BOOKMARKED_ITEMS: (submitReference, stepState) => (
    <OrderBookmarkedServicesStep submitReference={submitReference} stepState={stepState} />
  ),
  MONTHLY_PAYMENT: (submitReference, stepState) => (
    <MonthlyPaymentDecisionStep submitReference={submitReference} stepState={stepState} />
  ),
  SEPA: (submitReference, stepState) => <SepaStep submitReference={submitReference} stepState={stepState} />,
  SIGNING: (submitReference, stepState, hasDocumentGenerationTriggered, updateCurrentStepState) => (
    <ViewDocumentsAndSign
      submitReference={submitReference}
      stepState={stepState}
      hasDocumentGenerationTriggered={hasDocumentGenerationTriggered}
      updateCurrentStepState={updateCurrentStepState}
    />
  ),
  ready: () => null,
};

const SigningPage = () => {
  const { signingContext, loading } = useSigningContext();
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const submitReference = useRef();
  const [onContinueLoading, setOnContinueLoading] = useState(false);
  const currentState = signingContext?.currentState && JSON.parse(signingContext?.currentState);
  const updateCurrentSigningState = useUpdateCurrentSigningState();
  const currentStep = currentState?.stepIndex || 0;

  useEffect(() => {
    if (!signingContext && !loading) navigate(routePaths.signingUnvalid);
  }, [signingContext, loading, navigate]);

  const stepsOfContext = signingContext?.steps || [];

  const steps = [...stepsOfContext, 'ready'];
  const stepHeading = translation(`signing.common.headings.${steps[currentStep]}`);

  const currentStepState = currentState?.[currentStep];
  const currentHasDocumentGenerationTriggered = currentState?.hasDocumentGenerationTriggered || false;

  const getUpdateCurrentStepState = () => {
    return (newStepState) => {
      const newState = { ...currentState, [currentStep]: newStepState };
      updateCurrentSigningState(newState);
    };
  };

  const stepContent = STEPS[steps[currentStep]](
    submitReference,
    currentStepState,
    currentHasDocumentGenerationTriggered,
    getUpdateCurrentStepState(),
  );

  const navigateToFinish = useCallback(() => {
    navigate(routePaths.signingSuccessfullySigned);
  }, [navigate]);

  const updateSigningState = (stepInfos, newStepIndex, stepStateToUpdate) => {
    let newState;
    const { stepState, hasDocumentGenerationTriggered: stepsHasDocumentGenerationTriggered } = stepInfos || {};
    let hasDocumentGenerationTriggered = currentState?.hasDocumentGenerationTriggered || false;
    if (stepsHasDocumentGenerationTriggered) hasDocumentGenerationTriggered = true;
    if (stepState)
      newState = {
        ...currentState,
        stepIndex: newStepIndex,
        [stepStateToUpdate]: stepState,
        signingProcessId: signingContext?.signingProcessId,
        hasDocumentGenerationTriggered,
      };
    else
      newState = {
        ...currentState,
        stepIndex: newStepIndex,
        signingProcessId: signingContext?.signingProcessId,
        hasDocumentGenerationTriggered,
      };
    updateCurrentSigningState(newState);
  };

  // onContinue of a step should return a state object or true if the step is finished and the process can go one
  // else return false or null
  // if return value is true, then don't save a step state
  const onClickContinue = async () => {
    let stepState = null;
    if (submitReference?.current?.onContinue) {
      if (submitReference?.current?.showContinueLoading) setOnContinueLoading(true);
      stepState = await submitReference?.current?.onContinue();
      if (submitReference?.current?.showContinueLoading) setOnContinueLoading(false);
      if (!stepState) {
        return;
      }
    }
    let newStepIndex = 0;
    if (currentStep + 2 === steps.length) {
      navigateToFinish();
      newStepIndex = steps.length - 1;
    } else {
      newStepIndex = currentStep + 1;
    }
    if (stepState === true) {
      stepState = null;
    }
    updateSigningState(stepState, newStepIndex, newStepIndex - 1);
  };

  const onClickBack = async () => {
    if (submitReference?.current?.onBack) {
      const shouldContinue = await submitReference?.current?.onBack();
      if (!shouldContinue) {
        return;
      }
    }
    if (currentStep === 0) {
      return;
    }
    const newValue = currentStep - 1;
    updateSigningState(null, newValue);
  };

  return (
    <SigningProcessLayout
      theme={signingContext?.theme}
      steps={steps}
      currentStep={currentStep}
      onContinueButton={onClickContinue}
      onContinueLoading={onContinueLoading}
      onBackButton={currentStep === 0 ? undefined : onClickBack}
    >
      <div>
        <h1>{stepHeading}</h1>
        {stepContent}
      </div>
    </SigningProcessLayout>
  );
};

export default SigningPage;
