import { useState } from 'react';
import useSortFilterQuery from './useSortFilterQuery';
import useMoveData from './useMoveData';
import useIsDragEnabled from './useIsDragEnabled';
import useSelectable from './useSelectable';

/**
 * Handler for list layout with sorting, filtering, searching, selecting and drag and drop for not paginated lists
 * Handles fetching data, state for sorting, filtering, searching, selecting and drag and drop
 * The children functions gets following properties passed:
 *  sortedAndFilteredData: object[] - Data with applied sorting and filtering
 *  error: Error - Error object
 *  loading: boolean - Loading state
 *  sortState: string[] - Current sort settings [fieldName, direction]
 *  setSort: (fieldName: string, direction: string) => void - Function to set sort settings
 *  filterState: string[][] - Current filter settings [[fieldName, value], ...]
 *  setFilter: (fieldName: string, value: string) => void - Function to set filter settings
 *  queryState: string - Current search query
 *  setQuery: (newQuery: string) => void - Function to set search query
 *  selectAll: () => void - Function to select all items
 *  selectOne: (item: object, select: boolean = true) => void - Function to select one item
 *  selected: object[] - Selected items
 *  selectAllActive: boolean - Whether all items are selected
 *  resetSortAndFilter: () => void - Function to reset sort and filter settings for drag and drop
 *  dragEnabled: boolean - Whether drag and drop conditions are given
 *  moveData: (dragIndex: number, hoverIndex: number) => void - Function to move data in data entries for drag and drop
 * @param {object} inputProperties - Input properties of the hook
 * @param {() => object} inputProperties.useDataFetching - Hook to fetch data, which retunrs an object with data, error and loading
 * @param {string[]} inputProperties.initSort - Initial sort settings [fieldName, direction]
 * @param {boolean} inputProperties.shouldUseSearchQueryParams - Whether to use search query params
 * @param {React.ReactNode} inputProperties.children - Children to render, which gets following props: sortedAndFilteredData, error, loading, sortState, setSort, filterState, setFilter, queryState, setQuery, selectAll, selectOne, selected, selectAllActive, resetSortAndFilter, dragEnabled, moveData
 * @param {object} inputProperties.sortSettings - Configuration for sorting
 * @param {object} inputProperties.filterSettings - Configuration for filtering
 * @param {string[]} inputProperties.queryFields - Fields to search in
 * @param {(selectedItems: object[]) => void} inputProperties.onSelectChange - Function to call when selection changes
 * @param {object} inputProperties.dragAndDropSettings - Configuration for drag and drop
 * @returns {JSX.Element} Children with above injected props to handle list layout functionality
 */
const DefaultListLayoutHandler = ({
  useDataFetching,
  initSort = [],
  shouldUseSearchQueryParams,
  children,
  sortSettings,
  filterSettings,
  queryFields,
  onSelectChange = () => {},
  dragAndDropSettings: { orderField = 'order', orderDirection = 'ASC' } = {},
}) => {
  const { data, error, loading } = useDataFetching();
  const [sortedAndFilteredData, setSortedAndFilteredData] = useState(data);
  const [sortState, setSort, filterState, setFilter, queryState, setQuery, resetSortAndFilter] = useSortFilterQuery({
    data,
    setSortedAndFilteredData,
    initSort,
    sortSettings,
    filterSettings,
    queryFields,
    shouldUseSearchQueryParams,
    orderField,
    orderDirection,
  });
  const [selectAll, selectOne, selected, selectAllActive] = useSelectable({ onSelectChange, sortedAndFilteredData });

  const moveData = useMoveData(orderField, setSortedAndFilteredData);

  const dragEnabled = useIsDragEnabled(orderField, orderDirection, filterState, queryState, sortState);

  return children(
    sortedAndFilteredData,
    error,
    loading,
    sortState,
    setSort,
    filterState,
    setFilter,
    queryState,
    setQuery,
    selectAll,
    selectOne,
    selected,
    selectAllActive,
    resetSortAndFilter,
    dragEnabled,
    moveData,
  );
};

export default DefaultListLayoutHandler;
