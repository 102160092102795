import Spin from 'components/common/Spin';
import classes from './Loading.module.less';

/**
 * Loading component for list layouts
 * @returns {JSX.Element} Loading spinner
 */
const Loading = () => {
  return (
    <div className={classes.SpinWrapper}>
      <Spin />
    </div>
  );
};

export default Loading;
