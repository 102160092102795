import apollo from 'graphql/apollo';
import { createInputFieldMutation } from 'graphql/mutations';
import { adminCategoryItemQuery, adminCategoryListQuery, adminPlaceholderDataQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

const createInputField = async ({ categoryId, parentId, parentType, isLibrary, ...props }) =>
  apollo.mutate({
    mutation: createInputFieldMutation,
    variables: { categoryId, parentId, parentType, inputField: props },
    ...(parentType === 'categoryItem' && {
      refetchQueries: [
        {
          query: adminCategoryItemQuery,
          variables: { _id: parentId },
        },
        {
          query: adminPlaceholderDataQuery,
          variables: { categoryId, parentId, isLibrary },
        },
      ],
    }),
    update: (cache, { data }) => {
      try {
        const result = grabFirstGQLDataResult(data);
        const cachedData = cache.readQuery({ query: adminCategoryListQuery, variables: { isLibrary } });
        cache.writeQuery({
          query: adminCategoryListQuery,
          variables: { isLibrary },
          data: {
            categories: cachedData.categories.map((c) =>
              c._id === categoryId ? { ...c, inputFields: [...(c.inputFields || []), result] } : c,
            ),
          },
        });
        const placeholderCachedData = cache.readQuery({
          query: adminPlaceholderDataQuery,
          variables: { categoryId, parentId, isLibrary },
        });
        cache.writeQuery({
          query: adminPlaceholderDataQuery,
          variables: { categoryId, parentId, isLibrary },
          data: {
            inputs: [...placeholderCachedData.inputs, result],
            categories: placeholderCachedData.categories.map((category) => {
              if (category._id !== categoryId) return category;
              if (parentType === 'categoryItem') {
                return {
                  ...category,
                  items: category.items.map((item) =>
                    item._id === parentId ? { ...item, inputFields: [...(item.inputFields || []), result] } : item,
                  ),
                };
              }
              return { ...category, inputFields: [...(category.inputFields || []), result] };
            }),
            categoryItems: placeholderCachedData.categoryItems.map((item) =>
              item._id === parentId && parentType === 'categoryItem'
                ? { ...item, inputFields: [...(item.inputFields || []), result] }
                : item,
            ),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
export default createInputField;
