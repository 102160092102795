import { gql } from '@apollo/client';

const adminDocumentTemplateListForPageFragment = gql`
  fragment adminDocumentTemplateListForPageFragment on DocumentTemplate {
    _id
    name
    order
    description
    nrOfDocumentsCreated
    createdAt
    isSignable
  }
`;

export default adminDocumentTemplateListForPageFragment;
