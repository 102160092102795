import { filter } from 'lodash';
import { RichEditorField } from 'components/common/RichEditor';

/**
 * Richtext editor with formik for email inputs
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} [inputProperties.disableModifiers=false] - Disable text modifiers
 * @returns {JSX.Element} Rich editor field for email inputs with formik support
 * @component
 */
const EmailFormikRichEditor = ({ disableModifiers = false, ...props }) => {
  return (
    <RichEditorField
      allowedModifiers={
        disableModifiers
          ? []
          : ['bold', 'italic', 'underline', 'bulleted-list', 'numbered-list', 'indent', 'outdent', 'reset-formatting']
      }
      modifyPlaceholderSchema={(option) => filter(option, (e) => e.name !== 'document')}
      rootElement={props.rootElement || 'div'}
      {...props}
    />
  );
};

export default EmailFormikRichEditor;
