import { useTranslation } from 'react-i18next';
import { PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { withSingleLine } from 'components/common/RichEditor/plugins/withSingleLine';
import { withNoMarks } from 'components/common/RichEditor/plugins/withNoMarks';
import EmailFormikRichEditor from './EmailFormikRichEditor';

// Modifiers for the formik rich editor
// Single line and no text decoration (bold, italic, underline)
const withPlugins = (e) => withSingleLine(withNoMarks(e));

/**
 * Richtext editor input for email subject with formik support
 * @returns {JSX.Element} Input for email subject
 */
const SubjectFormikInput = () => {
  const { t } = useTranslation();
  return (
    <EmailFormikRichEditor
      withPlugins={withPlugins}
      disableModifiers
      label={t('admin.EmailTemplates.fields.subject.label')}
      allowedUses={[PLACEHOLDER_USE.MAIL_SUBJECT]}
      name="subject"
    />
  );
};

export default SubjectFormikInput;
