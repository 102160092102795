import classes from './ListItemRowEntry.module.less';

/**
 * Entry component for a list item row in a list item with label and value
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label of the entry
 * @param {React.ReactNode} inputProperties.value - Value of the entry
 * @param {boolean} inputProperties.showLabel - Optional: Should the label be shown (Default: true)
 * @returns {JSX.Element} Entry of a list item row
 * @component
 */
const ListItemRowEntry = ({ label, value, showLabel = true }) => {
  return (
    <div>
      {showLabel ? <div className={classes.listItemRowEntryLabel}>{label}</div> : null}
      <div className={classes.listItemRowEntryValue}>{value || <>&nbsp;</>}</div>
    </div>
  );
};

export default ListItemRowEntry;
