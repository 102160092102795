import classes from './DeleteTypeSelectionModal.module.less';

/**
 * DeleteOptionCard component renders a card with a title, description, icon, and a delete button.
 *Offering the user a delete operation on the current project card.
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title of the delete option card.
 * @param {string} props.description - The description of the delete option card.
 * @param {React.ReactNode} props.deleteButton - The delete button element.
 * @param {React.ReactNode} props.icon - The icon element to be displayed in the card.
 * @returns {JSX.Element} The rendered DeleteOptionCard component.
 */
export const DeleteOptionCard = ({ title, description, deleteButton, icon }) => {
  return (
    <div className={classes.deleteOptionCard}>
      <div className={classes.deleteOptionCardTitle}>
        <strong>{title}</strong>
      </div>
      <div className={classes.deleteOptionCardContent}>
        <div className={classes.deleteOptionCardLeft}>
          <div className={classes.deleteOptionCardIcon}>{icon}</div>
          <div className={classes.deleteOptionCardDescription}>{description}</div>
        </div>
        <div className={classes.deleteOptionCardButton}>{deleteButton}</div>
      </div>
    </div>
  );
};
