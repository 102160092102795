import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import concatClassNames from 'utils/classNames';
import classes from './FieldRenderers.module.less';

// Some common field renderers for list item fields
// Functions should get the value from the item field and return a JSX element

/**
 * Renders date times in our style
 * @param {Date} value - Date to render
 * @returns {string} - Rendered date
 */
export const RenderDateTime = (value) => {
  if (!value) return '';
  let date = value;
  if (typeof value === 'string') date = new Date(value);
  return date.toLocaleString('de-DE', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

/**
 * Renders a boolean value as a green check or red cross icon
 * @param {boolean} value - Value to render
 * @returns {JSX.Element} Rendered value
 * @component
 */
export const RenderTrueFalse = (value) => {
  return value ? (
    <CheckOutlined className={concatClassNames(classes.FixIcon, classes.True)} />
  ) : (
    <CloseOutlined className={concatClassNames(classes.FixIcon, classes.False)} />
  );
};
