import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';

/**
 * Renders a read-only view of rich text content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.richText - The rich text content to be rendered.
 * @param {Object} props.placeholderObject - The placeholder object for the rich text content.
 * @returns {JSX.Element} The rendered component.
 */
const RichTextViewer = ({ richText, placeholderObject }) => {
  return (
    <RichText.PlaceholderContextProvider value={placeholderObject}>
      {RichText.renderRichText(richText)}
    </RichText.PlaceholderContextProvider>
  );
};

export default RichTextViewer;
