import { useCallback, useEffect, useState } from 'react';
import { checkIfSelectAllActive } from './utils';

/**
 * Hook to handle select logic for a paginated list
 * @param {object} inputProperties - Input properties of the component
 * @param {(selectedItems: object[]) => void} inputProperties.onSelectChange - Function to call when selection changes
 * @param {object[]} inputProperties.data - Data entries to select from
 * @returns {[selectAll: () => void, selectOne: (item: object, select: boolean = true) => void, selected: object[], selectAllActive: boolean]} - Array with selectAll function, selectOne function, selected items and selectAllActive state
 * @component
 */
const usePaginatedSelectable = ({ data, onSelectChange }) => {
  const [selected, setSelected] = useState([]);
  const [selectAllActive, setSelectAllActive] = useState(false);

  const selectAll = useCallback(() => {
    setSelectAllActive((currentlyActive) => {
      if (currentlyActive) {
        setSelected((prevSelected) => {
          const newState = prevSelected.filter(
            (element) => !data.find((item) => JSON.stringify(item) === JSON.stringify(element)),
          );
          onSelectChange(newState);
          return newState;
        });
        return false;
      }
      setSelected((prevSelected) => {
        const newState = [...prevSelected, ...data];
        onSelectChange(newState);
        return newState;
      });
      return true;
    });
  }, [data, onSelectChange]);

  const selectOne = useCallback(
    (item, select = true) => {
      setSelected((prevSelected) => {
        if (select) {
          const newState = [...prevSelected, item];
          onSelectChange(newState);
          return newState;
        }
        setSelectAllActive(false);
        const newState = prevSelected.filter((element) => element !== item);
        onSelectChange(newState);
        return newState;
      });
    },
    [onSelectChange],
  );

  useEffect(() => {
    const newSelectAllActive = checkIfSelectAllActive(data, selected);
    if (newSelectAllActive !== selectAllActive) setSelectAllActive(newSelectAllActive);
  }, [data, selected, selectAllActive]);

  return [selectAll, selectOne, selected, selectAllActive];
};

export default usePaginatedSelectable;
